// React
import { useContext } from 'react';

// Custom components
import CTabs from 'components/CTabs';
import GalleryView from 'components/GalleryView';

// Tabs
import Preview from 'pages/TemplateDashboard/Tabs/Preview';
import SubmissionsProvider from 'pages/TemplateDashboard/Tabs/Submissions/SubmissionsProvider';
import Spreadsheet from 'pages/TemplateDashboard/Tabs/Spreadsheet';

// Styled
import { HeaderWrapper } from './styled';

// Context
import { DashboardContext } from 'pages/TemplateDashboard/context';

const Tabs = () => {
  const ctx = useContext(DashboardContext);

  const panelProps = {
    sx: {
      p: '0px !important',
    },
  };

  const tabProps = {
    sx: { fontWeight: 700, textTransform: 'capitalize' },
  };

  return (
    <HeaderWrapper container>
      <CTabs
        onTabChangeCb={ctx?.setActiveTab}
        activeTab={ctx?.activeTab}
        onDemand={true}
        data={[
          {
            label: 'Preview',
            content: <Preview />,
            panelProps,
            tabProps,
          },
          {
            label: `Submissions${
              ctx?.submissionCount ? ` (${ctx.submissionCount})` : ''
            }`,
            content: ctx?.template && <SubmissionsProvider />,
            panelProps,
            tabProps,
          },
          {
            label: `Spreadsheet${
              ctx?.submissionCount ? ` (${ctx.submissionCount})` : ''
            }`,
            content: ctx?.template && (
              <Spreadsheet
                viewMode="collapsed"
                tableHeight="calc(100vh - 238px)"
                template={ctx?.template}
                setShouldUpdate={ctx?.setShouldUpdate}
                shouldUpdate={ctx?.shouldUpdate}
              />
            ),
            panelProps,
            tabProps,
          },
          {
            label: 'Gallery',
            content: ctx?.template && (
              <GalleryView
                filters="date location person"
                templateId={ctx?.templateId}
              />
            ),
            tabProps,
          },
        ]}
      />
    </HeaderWrapper>
  );
};

export default Tabs;
