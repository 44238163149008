import React from 'react';
import {
  PriorityContent,
  ValueText,
} from 'pages/task/ListViewV2/TaskTile/priority/style';

function PriorityCmp({ task }) {
  return (
    <>
      {' '}
      {task.taskStatus !== 'Completed' && (
        <span className={'dotDivider'}>•</span>
      )}
      <PriorityContent>
        <div
          className={`icon-priority-${task?.priority?.toLowerCase()}`}
          style={{ marginRight: 10 }}
        />
        <ValueText>{task?.priority}</ValueText>
      </PriorityContent>
    </>
  );
}

export default PriorityCmp;
