import { TASK_STATUS } from 'utils/globalVariable';
import TaskActionDropdown from 'pages/task/TaskListView/TaskActionDropdown/TaskActionDropdown';

function ActionDropdown({ task }) {
  return (
    <>
      {task.taskStatus !== TASK_STATUS.Missed && (
        <span>
          <TaskActionDropdown
            task={task}
            buttonProps={{ style: { transform: 'rotate(90deg)', padding: 2 } }}
          />
        </span>
      )}
    </>
  );
}

export default ActionDropdown;
