import { createSvgIcon } from '@mui/material';

const AiBulbIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        opacity="0.12"
        d="M20.0003 20.9352C23.1536 19.4373 25.3337 16.2233 25.3337 12.5001C25.3337 7.34542 21.155 3.16675 16.0003 3.16675C10.8457 3.16675 6.66699 7.34542 6.66699 12.5001C6.66699 16.2233 8.84706 19.4373 12.0003 20.9352V21.8334C12.0003 23.0759 12.0003 23.6972 12.2033 24.1872C12.474 24.8406 12.9931 25.3598 13.6465 25.6304C14.1366 25.8334 14.7578 25.8334 16.0003 25.8334C17.2428 25.8334 17.8641 25.8334 18.3541 25.6304C19.0076 25.3598 19.5267 24.8406 19.7973 24.1872C20.0003 23.6972 20.0003 23.0759 20.0003 21.8334V20.9352Z"
        fill="#212121"
      />
      <path
        d="M12.667 29.8334H19.3337M13.3337 13.8334H18.667M16.0003 13.8334L16.0003 21.8334M20.0003 20.9352C23.1536 19.4373 25.3337 16.2233 25.3337 12.5001C25.3337 7.34542 21.155 3.16675 16.0003 3.16675C10.8457 3.16675 6.66699 7.34542 6.66699 12.5001C6.66699 16.2233 8.84706 19.4373 12.0003 20.9352V21.8334C12.0003 23.0759 12.0003 23.6972 12.2033 24.1872C12.474 24.8406 12.9931 25.3598 13.6465 25.6304C14.1366 25.8334 14.7578 25.8334 16.0003 25.8334C17.2428 25.8334 17.8641 25.8334 18.3541 25.6304C19.0076 25.3598 19.5267 24.8406 19.7973 24.1872C20.0003 23.6972 20.0003 23.0759 20.0003 21.8334V20.9352Z"
        stroke="#212121"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'AiBulbIcon',
  );
  return (
    <IconCmp
      width="26"
      height="26"
      viewBox="0 0 32 33"
      fill="none"
      {...props}
    />
  );
};
export default AiBulbIcon;
