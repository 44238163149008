import { useDispatch } from 'react-redux';
import { Stack, Typography } from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIconPencilBottom from 'components/Icons/editIconPencilBottomLine';
import ActionDropdown from 'components/Dropdown/ActionDropdown';
import CustomButton from 'components/Button/CustomButton';
import DeleteIcon from 'components/Icons/deleteIcon';
import { colors } from 'components/theme/constants';
import KeyIcon from 'components/Icons/keyIcon';
import DIALOGS from 'utils/dialogIds';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useAssigneesUtils } from 'utils/CustomHooks/useAssigneesUtil';
import { PROJECT_TYPES } from 'utils/globalVariable';
import { setDialog } from 'store/actions/dialogActions';
import { cloneDeep } from 'lodash';
import { deleteProject } from 'api/projectsApi';
import { HeaderCnt, ProjectAccessControl } from './style';
import { useMemo, useState } from 'react';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import xeniaApi from 'api/index';

const ProjectDetailHeader = (props) => {
  const { project, getProjectDetails } = props;
  const dispatch = useDispatch();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { addNewAssignees } = useAssigneesUtils();
  const { PERMISSIONS, hasPermission } = useCustomPermission();

  const [fetchingEditDetails, setFetchingEditDetails] = useState(false);

  const canEditProject = useMemo(() => {
    return hasPermission(PERMISSIONS.CAN_EDIT_PROJECT);
  }, [PERMISSIONS]);

  const canDeleteProject = useMemo(() => {
    return hasPermission(PERMISSIONS.CAN_DELETE_PROJECT);
  }, [PERMISSIONS]);

  const goBackHandler = () => {
    navigateWithWorkspaceUrl('/projects');
  };

  const transformProject = (data) => {
    const clonedData = cloneDeep(data) || {};
    const { entityIds } = project;

    if (project?.type === PROJECT_TYPES.userBasedProject) {
      const transformedAssignees = addNewAssignees(entityIds);
      if (transformedAssignees) {
        clonedData.TaskAssignees = transformedAssignees;
      }
    }

    if (project?.type === PROJECT_TYPES.assetBasedProject) {
      clonedData.assets = entityIds;
    }

    if (clonedData && clonedData?.startDate) {
      clonedData.parentTask = {
        startDate: clonedData?.startDate,
      };
    }

    return clonedData;
  };

  const editHandlerCallback = () => {
    getProjectDetails(false);
  };

  const editProjectHandler = async () => {
    if (project?.state === 'ready') {
      let nextTaskData = {};

      if (project?.NextTask?.[0]?.id) {
        setFetchingEditDetails(true);
        const response = await xeniaApi.taskDetailSingleListing({
          id: project?.NextTask?.[0]?.id,
        });
        nextTaskData = response?.data || {};
        setFetchingEditDetails(false);
      }

      const dataToEdit = transformProject(nextTaskData);
      delete dataToEdit?.transformedAssignees;
      delete dataToEdit?.transformedCategory;

      dispatch(
        setDialog({
          open: true,
          dialogId: 'editTask',
          data: {
            onSuccessCallBack: () => editHandlerCallback(),
            dialogState: {
              viewLess: false,
              project,
            },
            taskData: dataToEdit,
          },
        }),
      );
    }
  };

  const DeleteProjectTemplate = () => {
    return (
      <Typography
        style={{
          fontWeight: 400,
          fontSize: 16,
          lineHeight: '24px',
          letterSpacing: '0.15px',
        }}
      >
        Are you sure you want to delete this project ?
      </Typography>
    );
  };

  const actionsDropdownHandler = async (options) => {
    switch (options?.id) {
      case 'delete':
        dispatch(
          setDialog({
            dialogId: 'confirmationDialog',
            open: true,
            data: {
              title: 'Delete Project',
              ContentTemplate: DeleteProjectTemplate,
              onConfirmCallback: async () => {
                await deleteProject(project?.id);
                goBackHandler();
              },
              confirmationText: 'Delete Project',
            },
          }),
        );

        break;

      default:
        return null;
    }
  };

  const projectAccessHandler = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.PROJECT_ACCESS_CONTROL,
        data: {
          projectAccess: project?.ProjectAccess ?? [],
          projectId: project?.id,
          onSuccessCallback: () => {
            getProjectDetails(false);
          },
        },
      }),
    );
  };

  return (
    <HeaderCnt>
      <Stack alignItems={'center'} direction={'row'}>
        <ArrowBackRoundedIcon
          sx={{ marginRight: '12px', cursor: 'pointer' }}
          onClick={goBackHandler}
        />
        <Typography
          fontSize={'20px'}
          fontWeight={'400'}
          style={{ cursor: 'pointer' }}
          onClick={goBackHandler}
        >
          Projects
        </Typography>
        <KeyboardArrowRightRoundedIcon />
        <Typography fontSize={'20px'} fontWeight={'700'}>
          {project?.title}
        </Typography>
      </Stack>

      <Stack gap="12px" alignItems={'center'} direction={'row'}>
        {canEditProject && !project?.isPaused && (
          <CustomButton
            onClick={editProjectHandler}
            sx={{
              ...(project?.state !== 'ready' && {
                pointerEvents: 'none',
              }),
            }}
            style={{
              padding: '7px 16px',
              borderRadius: '8px',
              fontWeight: 600,
            }}
            startIcon={
              project?.state === 'ready' && (
                <EditIconPencilBottom
                  sx={{
                    '& path': {
                      stroke: 'white',
                      strokeOpacity: 1,
                    },
                  }}
                />
              )
            }
            variant="contained"
            buttonLoader={fetchingEditDetails}
          >
            {project?.state === 'ready'
              ? 'Edit Project'
              : 'Updating Project...'}
          </CustomButton>
        )}

        {canEditProject && (
          <ProjectAccessControl onClick={projectAccessHandler}>
            <KeyIcon sx={{ height: 20 }} />
          </ProjectAccessControl>
        )}

        {canDeleteProject && (
          <ActionDropdown
            paperProps={{
              style: {
                minWidth: 145,
              },
            }}
            options={[
              {
                label: 'Delete',
                id: 'delete',
                icon: (
                  <DeleteIcon style={{ color: colors.red, fill: 'none' }} />
                ),
                iconAlign: 'start',
                sx: {
                  color: `${colors.red}`,
                },
              },
            ]}
            handleOptionSelect={actionsDropdownHandler}
          >
            <MoreHorizIcon />
          </ActionDropdown>
        )}
      </Stack>
    </HeaderCnt>
  );
};

export default ProjectDetailHeader;
