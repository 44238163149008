import config from 'config/index';
import { callApi, getHotelId, getItem } from 'utils/globalFunction';
import { LOCALSTORAGE_KEYS } from 'utils/globalVariable';

export const getScheduleCompletionOverview = async (data) => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        'task/workspaces' +
        `/${hotelId}/tasks-schedule-completion-percentage`,
      data,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return response?.data?.data;
  } catch (res) {
    return res;
  }
};

export const getScheduledTasksByStatus = async (data) => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        'task/workspaces' +
        `/${hotelId}/tasks-schedule-by-status`,
      data,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return response?.data?.data;
  } catch (res) {
    return res;
  }
};

export const getScheduleCompletionByLocation = async (data) => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        'task/workspaces' +
        `/${hotelId}/tasks-schedule-completion-by-location`,
      data,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return response?.data?.data;
  } catch (res) {
    return res;
  }
};

export const getOnTimeVsLateCompletion = async (data) => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        'task/workspaces' +
        `/${hotelId}/tasks-on-time-late-submission-by-location`,
      data,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return response?.data?.data;
  } catch (res) {
    return res;
  }
};

export const getScheduledTasks = async (data) => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        'task/workspaces' +
        `/${hotelId}/tasks-schedule-completion-by-task`,
      data,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return response?.data?.data;
  } catch (res) {
    return res;
  }
};

export const getScheduledTasksGroupBy = async (payload) => {
  const { data, groupBy, groupByLevel } = payload;
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        'task/workspaces' +
        `/${hotelId}/tasks-schedule-summary?groupBy=${groupBy}${
          groupByLevel && `&groupByLevel=${groupByLevel}`
        }`,
      data,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return response?.data?.data;
  } catch (res) {
    return res;
  }
};
