import styled from '@mui/system/styled';
import { colors } from 'components/theme/constants';

export const KanbanBoardCnt = styled('div')({
  display: 'flex',
  width: '100%',
  overflow: 'auto',
});

export const ColumnCnt = styled('div', {
  shouldForwardProp: (prop) => prop !== 'collapsed',
})(({ collapsed }) => ({
  width: collapsed ? 54 : 300,
  background: '#F2F5F7',
  margin: '0 6px',
  borderRadius: 12,
  padding: collapsed ? '22px 14px' : 12,
  transition: '0.4s ease all',
  height: '100%',
  display: 'flex',
  flexDirection: collapsed ? 'row' : 'column',
  '.tasksContainer': {
    flex: 1,
    display: collapsed ? 'none' : 'block',
  },
}));
export const ColumnHeaderCnt = styled('div', {
  shouldForwardProp: (prop) => prop !== 'collapsed',
})(({ collapsed }) => ({
  paddingBottom: collapsed ? 0 : 12,
  fontSize: 16,
  fontWeight: 800,
  display: 'flex',
  alignItems: 'center',
  transition: '0.4s ease all',
  writingMode: collapsed ? 'vertical-rl' : 'horizontal-tb',
  '.childCount': {
    transition: '0.4s ease all',
    background: '#E0E6E8',
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '157%',
    marginLeft: collapsed ? 0 : 8,
    marginTop: collapsed ? 8 : 0,
    padding: collapsed ? '6px 2px' : '2px 6px',
    borderRadius: 4,
  },
  '.collapsIcon': {
    cursor: 'pointer',
    transition: '0.4s ease all',
    color: '#424242',
    marginLeft: collapsed ? 0 : 'auto',
    marginTop: collapsed ? 'auto' : 0,
    opacity: 0,
    transform: collapsed ? 'rotate(180deg)' : 'rotate(0)',
  },
  '&:hover .collapsIcon': {
    opacity: 1,
  },
}));

export const TaskCardCnt = styled('div')({
  padding: 12,
  background: '#fff',
  // marginTop: 12,
  borderRadius: 12,
  boxShadow: '0px 1px 0px 0px #0000000D',
  border: '1px solid #EEEEEE',
  transition: '0.4s ease all',
  '&:hover': {
    boxShadow: '0px 6px 8px 0px #00000014',
  },
  '.taskTitle': {
    display: '-webkit-box !important',
    color: '#000000',
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 6,
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '.taskDate': {
    color: '#212121',
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: 0.4,
    '.dueOrcompleted': {
      color: '#212121',
      '& svg': {
        fontSize: 16,
        '& path': {
          stroke: '#616161',
        },
      },
      display: 'flex',
      gap: 6,
      alignItems: 'center',
    },
    '.overdue': {
      color: 'rgba(211, 47, 47, 1)',
      display: 'flex',
      gap: 6,
      alignItems: 'center',
    },
  },
  '.taskFooter': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 6,
    margin: '12px 0 0 0',
    padding: '12px 0 0 0',
    borderTop: '1px solid #E0E0E0',
    flexWrap: 'wrap',
    '.taskPriority': {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '24px',
      color: '#000',
    },
  },
});

export const AttachmentCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '& .photoCnt': {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginLeft: '10px',
    gap: 0,
    '& .imgbox': {
      display: 'flex',
      width: 38,
      height: 38,
      marginLeft: '-10px',
      position: 'relative',
      overflow: 'hidden',
      borderLeft: '2px solid #fff',
      borderRadius: 8,
      '& .img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },
    '& .overlay': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      background: '#00000080',
      color: '#fff',
      fontSize: 13,
      fontWeight: 700,
    },
  },
});

export const FrequencyCnt = styled('div')({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  '& .innerCnt': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '4px 8px',
    gap: 6,
    background: '#6868FE',
    borderRadius: 20,
    '& svg': {
      color: '#6868FE',
      height: 16,
      width: 16,
    },
    '& p': {
      fontWeight: 400,
      fontSize: 13,
      lineHeight: '18.59px',
      letterSpacing: '0.17px',
      color: colors.white,
    },
  },
});
