// Components
import ColumnHeader from './columnHeader';
import { TaskCardMomoized } from '../taskCard/taskCard';
// Styles
import { ColumnCnt } from '../board.styles';
import { FixedSizeList as List, areEqual } from 'react-window';
import VirtualList from 'components/VirtualList';
// Hook
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
// import VirtualList from './virtualList';

interface ColumnCardProps {
  column: any;
  data: any;
  index: number;
  collapsedColumns: string[];
  setCollapsedColumns: any;
}

const Row = React.memo(({ data, index, style }: any) => {
  const task = data[index];
  // We are rendering an extra item for the placeholder
  // Do do this we increased our data set size to include one 'fake' item
  if (!task) {
    return null;
  }

  return (
    <Draggable draggableId={task.id} index={index} key={task.id}>
      {(provided, snapshot) => (
        <TaskCardMomoized
          provided={provided}
          isDragging={snapshot.isDragging}
          task={task}
          style={{ ...style }}
          index={index}
        />
      )}
    </Draggable>
  );
}, areEqual);

function ColumnCard(props: ColumnCardProps) {
  const { column, index, data, collapsedColumns, setCollapsedColumns } = props;
  const tasksContainerRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (tasksContainerRef.current) {
      setHeight(tasksContainerRef.current.clientHeight);
    }
  }, []);

  const tasks = useMemo(() => {
    return column.taskIds.map((taskId) => data?.tasksById[taskId]);
  }, [column.taskIds]);
  const collapsed = useMemo(() => {
    return collapsedColumns.includes(column.id);
  }, [column, collapsedColumns]);

  const updateCollapsed = (collapsed: boolean) => {
    if (collapsed) {
      setCollapsedColumns([...collapsedColumns, column.id]);
    } else {
      setCollapsedColumns(collapsedColumns.filter((c) => c !== column.id));
    }
  };

  return (
    <ColumnCnt
      style={{ background: column.backgroundColor }}
      collapsed={collapsed}
    >
      <ColumnHeader
        column={column}
        count={tasks.length}
        collapsed={collapsed}
        updateCollapsed={updateCollapsed}
      />
      <div className={'tasksContainer'} ref={tasksContainerRef}>
        <Droppable
          droppableId={column.id}
          type="TASK"
          mode="virtual"
          isDropDisabled={column.id == 'Missed'}
          renderClone={(provided, snapshot, rubric) => (
            <TaskCardMomoized
              provided={provided}
              isDragging={snapshot.isDragging}
              task={data?.tasksById[rubric.draggableId]}
              index={rubric.source.index}
              style={{ margin: 0 }}
              isClonned={true}
            />
          )}
        >
          {(provided, snapshot) => {
            return (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ height: '100%' }}
              >
                {/* <VirtualList
                  height={height}
                  tasks={tasks}
                  outerRef={provided.innerRef}
                /> */}

                <VirtualList
                  height={height}
                  rows={tasks.map((task, i) => (
                    <Draggable draggableId={task.id} index={i} key={task.id}>
                      {(provided, snapshot) => (
                        <TaskCardMomoized
                          provided={provided}
                          isDragging={snapshot.isDragging}
                          task={task}
                          style={{}}
                          index={i}
                        />
                      )}
                    </Draggable>
                  ))}
                  rowGap={12}
                />
                {/* <List
                  height={500}
                  itemCount={tasks.length}
                  itemSize={128}
                  width={'100%'}
                  outerRef={provided.innerRef}
                  style={{
                    transition: 'background-color 0.2s ease',
                  }}
                  itemData={tasks}
                >
                  {Row}
                </List> */}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </div>
    </ColumnCnt>
  );
}

export default ColumnCard;
