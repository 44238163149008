import ArrowExpanded from '@mui/icons-material/KeyboardArrowDown';
import ArrowCollapsed from '@mui/icons-material/KeyboardArrowRight';
import CustomButton from 'components/Button/CustomButton';
import ColorsDropdown from 'components/Dropdown/ColorsDropdown';
import { HasPermission } from 'components/HasPermission';
import AddTaskWorkOrderIcon from 'components/Icons/addTaskWorkorderIcon';
import ChildItemIcon from 'components/Icons/childItemIcon';
import OpenBookIcon from 'components/Icons/openBookIcon';
import { isEmpty } from 'lodash';
import {
  ActionColumnRendererCnt,
  ArrowBtn,
  AttachmentIcon,
  EmptyLoaderCnt,
  LocationLevelCnt,
  LocationNameCnt,
  MUILoader,
  MainCnt,
} from 'pages/Locations/LocationsList/locationsList.styles';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setDialog } from 'store/actions/dialogActions';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import useUpdateLocation from 'utils/CustomHooks/useUpdateLocation';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { appRoutes } from 'utils/globalVariable';
import mime from 'mime';
import xeniaApi from 'api/index';
import actions from 'store/actions';
import { Badge, Stack, Typography } from '@mui/material';
import ImageComponent from 'components/common/htmlTags/image';
import CustomAvatarGroup from 'components/Avatar/AvatarGroup/AvatarGroup.cmp';
import ImageFileUpload from 'components/ImageFileUpload';
import CTypography from 'components/CTypography';

export function PhotosAttachment(props) {
  const { location } = props;
  const attachments = location?.attachments || [];
  const dispatch = useDispatch();
  const { updateLocation } = useUpdateLocation();

  const [loading, setLoading] = useState(false);
  const attachmentsData: any = useMemo(() => {
    return attachments
      ?.filter((url) => /\.(jpe?g|png|gif)$/i.test(url))
      ?.map((attachment: any) => {
        return {
          url: attachment,
          type: mime.getType(attachment),
        };
      })
      ?.reverse();
  }, [attachments]);

  const uploadAttachments = async (acceptedFiles: any) => {
    try {
      if (acceptedFiles.length) {
        setLoading(true);
        const response = await xeniaApi.CreateTaskAttchmentFilesApi(
          acceptedFiles,
        );
        if (response) {
          let attachedList = response.filePath.map(
            (item) => response.baseUrl + item,
          );

          if (location?.attachments?.length > 0) {
            attachedList = [
              ...location.attachments,
              ...response.filePath.map((item) => response.baseUrl + item),
            ];
          }

          const postData = { ...location, attachments: attachedList };
          updateLocation({ ...postData });
        }
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <MainCnt loading={loading} onHoverOnly={attachmentsData?.length > 0}>
      {attachmentsData?.length > 0 ? (
        <div
          className="photoCnt"
          onClick={() => {
            dispatch(
              actions.setAttachmentsPreviewData({
                index: 0,
                attachments: attachmentsData,
              } as any),
            );
          }}
        >
          {loading && <MUILoader disableShrink size={20} />}
          <Badge
            badgeContent={
              attachmentsData.length - 1 === 0
                ? 0
                : `+${attachmentsData.length - 1}`
            }
            color="success"
          >
            <div className="imgbox" role="button">
              <ImageComponent
                src={attachmentsData?.[0]?.url}
                className={'img'}
              />
            </div>
          </Badge>
        </div>
      ) : loading ? (
        <EmptyLoaderCnt>
          <MUILoader disableShrink size={20} />
        </EmptyLoaderCnt>
      ) : (
        <></>
      )}

      <div className="attachCnt">
        <ImageFileUpload
          onUploadCallback={uploadAttachments}
          options={{
            accept: {
              'image/*': [],
            },
          }}
        >
          <AttachmentIcon />
        </ImageFileUpload>
      </div>
    </MainCnt>
  );
}

export function ActionColumnRenderer({ params }) {
  const { id } = params.data;
  const dispatch = useDispatch();
  const { PAYWALL_LIMITS, PERMISSIONS } = useCustomPermission();

  const handleCreateTask = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: 'createEditTask',
        data: {
          dialogState: {
            viewLess: false,
          },
          taskData: {
            locationIds: [id],
          },
        },
      }),
    );
  };
  return (
    <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_TASKS]}>
      <ActionColumnRendererCnt
        className={'actions'}
        data-attribute={'cell-click'}
      >
        <CustomButton
          startIcon={<AddTaskWorkOrderIcon className={'addTaskIcon'} />}
          onClick={handleCreateTask}
          variant={'outlined'}
          buttonType={'grayWhite'}
        >
          Create Work Order
        </CustomButton>
      </ActionColumnRendererCnt>
    </HasPermission>
  );
}
export function OpenLocationBtnRenderer({ params }) {
  const { id, ParentId } = params.data;
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const navigateToLocationDetail = () => {
    const urlToNavigate = ParentId ? `/${ParentId}/sl/${id}` : `/${id}`;
    navigateWithWorkspaceUrl(appRoutes.locations + urlToNavigate);
  };
  return (
    <div className={'actions'} style={{ display: 'none' }}>
      <CustomButton
        startIcon={<OpenBookIcon sx={{ color: '#757575' }} />}
        onClick={navigateToLocationDetail}
        variant={'outlined'}
        buttonType={'grayWhite'}
        sx={{ borderRadius: '30px', padding: '3px 13px' }}
      >
        Open
      </CustomButton>
    </div>
  );
}

export function LocationLevelRenderer({ params }) {
  const { Level } = params.data;
  return (
    <LocationLevelCnt>
      <CTypography className="title">{Level?.title}</CTypography>
    </LocationLevelCnt>
  );
}

export function LocationNameRenderer({ params }) {
  const {
    name,
    ParentId,
    avatar,
    Sublocations,
    taggedName,
    taggedCount,
    untaggedName,
    untaggedCount,
  } = params.data;

  const { updateLocation } = useUpdateLocation();

  const handleUpdateLocationColor = (color) => {
    updateLocation({ ...params.data, avatar: { color } });
  };
  const haveSubLocations = !isEmpty(params?.data?.Sublocations);
  return (
    <LocationNameCnt>
      <span
        style={{ display: 'flex', alignItems: 'center' }}
        data-attribute={'cell-click'}
      >
        {params.node.expanded && haveSubLocations && !params?.isSearching && (
          <ArrowBtn
            onClick={(e) => {
              e.stopPropagation();
              params.node.setExpanded(false);
              globalThis?.LocationsGrid.api.redrawRows();
            }}
          >
            <ArrowExpanded />{' '}
          </ArrowBtn>
        )}
        {!params.node.expanded && haveSubLocations && !params?.isSearching && (
          <ArrowBtn
            onClick={(e) => {
              e.stopPropagation();
              params.node.setExpanded(true);
              globalThis?.LocationsGrid.api.redrawRows();
            }}
          >
            <ArrowCollapsed />
          </ArrowBtn>
        )}
        {ParentId ? <ChildItemIcon className="subLocationIcon" /> : null}
      </span>
      {!ParentId ? (
        <span
          data-attribute={'cell-click'}
          style={{
            marginLeft: !haveSubLocations || params?.isSearching ? 30 : '',
          }}
        >
          <ColorsDropdown
            selected={avatar?.color}
            changeHandler={handleUpdateLocationColor}
            iconStyles={{
              width: 10,
              height: 10,
              borderRadius: '2px',
              border: 'none',
            }}
          />
        </span>
      ) : null}
      <span className={'locationName'}>{name}</span>

      {(taggedCount > 0 || untaggedCount > 0) && (
        <span className={'countsTag'}>
          {taggedCount} {taggedName}
          {taggedCount > 0 && untaggedCount > 0 && ','} {untaggedCount}{' '}
          {untaggedName}
        </span>
      )}
    </LocationNameCnt>
  );
}

export function LocationMembersRenderer({ members }) {
  const treshold = 3;
  const hasMore = members?.length > treshold;
  const avatarMembers = hasMore ? members?.slice(0, treshold) : members;
  return (
    <Stack justifyContent={'center'} height={'100%'}>
      <Stack
        style={{ display: 'flex', alignItems: 'center' }}
        data-attribute={'cell-click'}
        direction={'row'}
        gap={'4px'}
        alignItems={'center'}
      >
        <CustomAvatarGroup members={avatarMembers} max={3} />
        {hasMore && (
          <Typography
            color={'rgba(78, 72, 250, 1)'}
            fontWeight={700}
            fontSize={13}
          >
            +{members?.length - treshold}
          </Typography>
        )}
        {members?.length === 1 && (
          <Typography color={'#212121'} fontWeight={500} fontSize={13}>
            {members[0]?.type === 'team'
              ? members[0]?.name
              : members[0]?.fullName}
          </Typography>
        )}
        {members?.length === 0 && (
          <Typography color={'#212121'} fontWeight={500} fontSize={13}>
            -
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}
