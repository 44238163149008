import { Box, Stack, Typography, styled } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';

export const AddOnsContainer = styled(Typography)({
  margin: '20px 50px',
  '& .mainBox': {
    cursor: 'pointer',
    border: '1px solid #E0E0E0',
    borderRadius: 12,
    padding: 20,
    height: 209,
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    position: 'relative',

    '&:hover': {
      transform: 'scale(1.03)',
      cursor: 'pointer',
    },
  },

  '& .title': {
    color: '#000000',
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '24.59px',
    marginBottom: 5,
  },

  '& .subTitle': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '22px',
    color: '#212121',
  },
});

export const IntroBannerCnt = styled(Stack)(() => ({
  borderRadius: 12,
  background: 'url(/assets/img/onboarding-logo.png) #EBEAFF no-repeat 100% 1px',
  backgroundSize: 'contain',
  gap: 5,
  padding: '24px',
  height: 137,
  marginBottom: 20,
  position: 'relative',

  '& .statement': {
    fontWeight: 800,
    fontSize: 24,
    lineHeight: '32.78px',
    color: '#000000',
  },

  '& .boldText': {
    fontWeight: 800,
    fontSize: 24,
    lineHeight: '32.78px',
    color: '#6868FE',
  },

  '& .description': {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    color: '#212121',
    maxWidth: 850,
  },
}));

export const GetDemoBtn = styled(CustomButton)(() => ({
  backgroundColor: '#FFC400',
  color: 'black',
  width: '140px',
  marginTop: '10px',
  borderRadius: '8px',
  height: '40px',
  fontWeight: '700',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',

  '&:hover': {
    transform: 'scale(1.03)',
    cursor: 'pointer',
    backgroundColor: '#FFC400',
  },
}));

export const ImgBox = styled(Box)(() => ({
  borderRadius: 200,
  height: 60,
  width: 60,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: 10,
}));

export const EnabledBox = styled(Box)({
  position: 'absolute',
  top: 10,
  right: 10,

  display: 'flex',
  padding: '6px 12px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 10,
  backgroundColor: 'rgba(235, 234, 255, 1)',
  borderRadius: '100px',

  '& .text': {
    fontSize: 13,
    fontWeight: 600,
    color: 'rgba(104, 104, 254, 1)',
  },
});

export const BuilderIoSectionCnt = styled(Box)({
  position: 'relative',
  '& .resources': {
    margin: '-5px 50px 5px 50px',
  },
  '& .actionCnt': {
    position: 'absolute',
    top: 30,
    right: 50,

    '& .talkToSales': {
      borderRadius: 8,
      height: 40,
    },

    '& .enabledBox': {
      display: 'flex',
      padding: '6px 12px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 10,
      backgroundColor: 'rgba(235, 234, 255, 1)',
      borderRadius: '100px',

      '& .text': {
        fontSize: 15,
        fontWeight: 700,
        color: 'rgba(104, 104, 254, 1)',
      },
    },
  },
});
