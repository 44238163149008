import { useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CancelIcon from '@mui/icons-material/Cancel';
import { getAuthorizedLocations } from 'store/selectors/locations';
import {
  LocationDropdownButton,
  StyledSelectedText,
  StyledEndIconCnt,
  RemoveIconBtn,
} from 'pages/Widgets/TaskFilters/LocationFilter/locationFilters.style';
import LocationItemsContainer from 'components/Dropdown/LocationsSelectItemsList/LocationItemsContainer';

function LocationList(props) {
  const { selectedOption, setSelectedOption, onChangeCallback } = props;
  const locationsList = useSelector(getAuthorizedLocations);

  return (
    <LocationItemsContainer
      data={locationsList}
      selected={selectedOption || []}
      containerProps={{
        style: {
          padding: '0px 10px',
        },
      }}
      contentProps={{
        isSearch: true,
        isSelectAll: true,
        isMulti: true,
        style: {
          maxHeight: 300,
          overflow: 'auto',
        },
      }}
      changeHandler={(_, values) => {
        setSelectedOption(values);
        const idsList = values?.map((item) => {
          return {
            id: item,
          };
        });
        onChangeCallback?.(idsList);
      }}
    />
  );
}
function LocationFilterDropdown(props) {
  const {
    popperProps = { style: {} },
    buttonProps = {},
    selected = null,
    children,
    dropdownProps,
    onChangeCallback,
    onClearCallback,
    hideRemoveIcon = false,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState<any[]>(selected ?? []);

  useEffect(() => {
    setSelectedOption(selected ?? []);
  }, [selected]);
  const handleDropdownOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleDropdownClose = () => {
    setAnchorEl(null);
  };
  const handleClearOptions = (event) => {
    event.stopPropagation();
    setSelectedOption([]);
    onClearCallback?.();
  };
  const selectedText = useMemo(() => {
    if (selectedOption && selectedOption.length > 0) {
      return `${selectedOption?.length} Locations`;
    } else {
      return 'Location: All';
    }
  }, [selectedOption]);

  return (
    <ClickAwayListener
      onClickAway={handleDropdownClose}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <div>
        <LocationDropdownButton
          variant="contained"
          buttonType="grayDropdown"
          onClick={handleDropdownOpen}
          isSelected={selectedOption?.length > 0}
          endIcon={
            <StyledEndIconCnt>
              {/*commented for later use*/}
              {/*{selected && (*/}
              {/*  <IconButton onClick={handleClearOptions}>*/}
              {/*    <CloseIcon />*/}
              {/*  </IconButton>*/}
              {/*)}*/}
              <KeyboardArrowDownIcon style={{ fontSize: 20 }} />
            </StyledEndIconCnt>
          }
        >
          {selectedOption?.length && !hideRemoveIcon ? (
            <RemoveIconBtn
              className="remove-assignee"
              onClick={handleClearOptions}
            >
              <CancelIcon />
            </RemoveIconBtn>
          ) : null}
          <StyledSelectedText>{selectedText}</StyledSelectedText>
        </LocationDropdownButton>

        <CustomDropdown
          popperProps={{
            placement: 'bottom-start',
            content: (
              <LocationList
                closeAction={handleDropdownClose}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                onChangeCallback={onChangeCallback}
                {...dropdownProps}
              />
            ),
            style: { ...popperProps.style, zIndex: 9999, width: 270 },
            open: !!anchorEl,
            anchorEl,
            ...popperProps,
          }}
          buttonProps={buttonProps}
          clickaway={false}
        >
          {children}
        </CustomDropdown>
      </div>
    </ClickAwayListener>
  );
}

export default LocationFilterDropdown;
