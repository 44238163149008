import React from 'react';
import { Box, Skeleton } from '@mui/material';

function TaskListSkeleton() {
  return (
    <>
      {[...Array(4)].map((_, index) => {
        return (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            padding="8px 16px"
            border="1px solid #E0E0E0"
            borderRadius="8px"
            height="100px" // Set the height of each skeleton loader
            mb={2} // Vertical spacing between skeletons
          >
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Skeleton variant="text" width={150} height={24} />
              <Box display="flex" alignItems="center" mt={1}>
                <Skeleton variant="circular" width={20} height={20} />
                <Skeleton
                  variant="text"
                  width={100}
                  height={20}
                  sx={{ marginLeft: 1 }}
                />
                <Skeleton
                  variant="text"
                  width={40}
                  height={20}
                  sx={{ marginLeft: 2 }}
                />
              </Box>
            </Box>

            <Box display="flex" alignItems="center">
              <Skeleton
                variant="circular"
                width={24}
                height={24}
                sx={{ marginRight: 2 }}
              />
              <Skeleton variant="circular" width={24} height={24} />
            </Box>
          </Box>
        );
      })}
    </>
  );
}

export default TaskListSkeleton;
