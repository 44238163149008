import { FC, useState } from 'react';
import BluetoothSensorDetails from 'components/BuilderUI/AddOns/BluetoothSensor';
import { BuilderIoSectionCnt } from '../style';
import { Box } from '@mui/material';
import Resources from 'pages/dashboard/resources';
import { useDispatch } from 'react-redux';
import CustomButton from 'components/Button/CustomButton';
import { setCalendlyInfo } from 'store/actions/calendlyActions';
import { TALK_TO_SALES_URL } from 'utils/constants';

const AddOnsBluetoothSensorPage: FC = () => {
  const [builderActive, setBuilderActive] = useState(false);
  const dispatch = useDispatch();

  const handleTalkToSales = () => {
    dispatch(
      setCalendlyInfo({
        url: TALK_TO_SALES_URL,
        open: true,
      }),
    );
  };

  return (
    <BuilderIoSectionCnt>
      <Box className="actionCnt">
        <CustomButton
          variant="contained"
          className="talkToSales"
          onClick={handleTalkToSales}
        >
          Talk to Sales
        </CustomButton>
      </Box>
      <BluetoothSensorDetails setBuilderActive={setBuilderActive} />
      {builderActive && (
        <Box className="resources">
          <Resources title="Resources about Bluetooth Thermometer Integration" />
        </Box>
      )}
    </BuilderIoSectionCnt>
  );
};

export default AddOnsBluetoothSensorPage;
