// Components
import ImageComponent from 'components/common/htmlTags/image';
// Actions / Selectors / Utils
import actions from 'store/actions';
// Hooks
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

// Styles
import { AttachmentCnt } from '../board.styles';
import mime from 'mime';
import { Badge } from '@mui/material';

function TaskAttachment(props) {
  const { task } = props;
  const { attachment } = task;
  const dispatch = useDispatch();
  const attachmentsData: any = useMemo(() => {
    return attachment
      ?.filter((url) => /\.(jpe?g|png|gif)$/i.test(url))
      ?.map((attachment: any) => {
        return {
          url: attachment,
          type: mime.getType(attachment),
        };
      })
      ?.reverse();
  }, [attachment]);

  return (
    <>
      {attachmentsData?.length > 0 && (
        <AttachmentCnt>
          <div
            className="photoCnt"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(
                actions.setAttachmentsPreviewData({
                  index: 0,
                  attachments: attachmentsData,
                } as any),
              );
            }}
          >
            <div className="imgbox" role="button">
              <ImageComponent
                src={attachmentsData?.[0]?.url}
                className={'img'}
              />
            </div>
            {attachmentsData.length > 1 && (
              <div className="imgbox" role="button">
                <ImageComponent
                  src={attachmentsData?.[1]?.url}
                  className={'img'}
                />
                {attachmentsData.length > 2 && (
                  <span className="overlay">+{attachmentsData.length - 1}</span>
                )}
              </div>
            )}
          </div>
        </AttachmentCnt>
      )}
    </>
  );
}

export default TaskAttachment;
