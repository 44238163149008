import { createSvgIcon } from '@mui/material';

const EnvironmentIcon = (props) => {
  const { showBackground = true } = props;
  const IconCmp = createSvgIcon(
    <>
      {showBackground && (
        <rect y="0.548828" width="48" height="48" rx="8" fill="#B3E5FC" />
      )}
      <path
        opacity="0.12"
        d="M23.1855 35.7666C23.4438 35.9173 23.573 35.9926 23.7552 36.0317C23.8967 36.062 24.103 36.062 24.2445 36.0317C24.4267 35.9926 24.5559 35.9173 24.8142 35.7666C27.0869 34.4407 33.3332 30.2758 33.3332 24.5492V18.9698C33.3332 18.037 33.3332 17.5706 33.1806 17.1697C33.0459 16.8156 32.8269 16.4996 32.5426 16.249C32.2208 15.9654 31.7841 15.8017 30.9107 15.4742L24.6553 13.1284C24.4127 13.0374 24.2915 12.9919 24.1667 12.9739C24.056 12.9579 23.9436 12.9579 23.833 12.9739C23.7082 12.9919 23.587 13.0374 23.3444 13.1284L17.089 15.4742C16.2156 15.8017 15.7789 15.9654 15.4571 16.249C15.1728 16.4996 14.9538 16.8156 14.8191 17.1697C14.6665 17.5706 14.6665 18.037 14.6665 18.9698V24.5492C14.6665 30.2758 20.9128 34.4407 23.1855 35.7666Z"
        fill="black"
      />
      <path
        d="M23.1855 35.7666C23.4438 35.9173 23.573 35.9926 23.7552 36.0317C23.8967 36.062 24.103 36.062 24.2445 36.0317C24.4267 35.9926 24.5559 35.9173 24.8142 35.7666C27.0869 34.4407 33.3332 30.2758 33.3332 24.5492V18.9698C33.3332 18.037 33.3332 17.5706 33.1806 17.1697C33.0459 16.8156 32.8269 16.4996 32.5426 16.249C32.2208 15.9654 31.7841 15.8017 30.9107 15.4742L24.6553 13.1284C24.4127 13.0374 24.2915 12.9919 24.1667 12.9739C24.056 12.9579 23.9436 12.9579 23.833 12.9739C23.7082 12.9919 23.587 13.0374 23.3444 13.1284L17.089 15.4742C16.2156 15.8017 15.7789 15.9654 15.4571 16.249C15.1728 16.4996 14.9538 16.8156 14.8191 17.1697C14.6665 17.5706 14.6665 18.037 14.6665 18.9698V24.5492C14.6665 30.2758 20.9128 34.4407 23.1855 35.7666Z"
        fill="#E3F2FD"
      />
      <path
        d="M20.4998 23.9659L22.8332 26.2992L28.0832 21.0492M33.3332 24.5492C33.3332 30.2758 27.0869 34.4407 24.8142 35.7666C24.5559 35.9173 24.4267 35.9926 24.2445 36.0317C24.103 36.0621 23.8967 36.0621 23.7552 36.0317C23.573 35.9926 23.4438 35.9173 23.1855 35.7666C20.9128 34.4407 14.6665 30.2758 14.6665 24.5492V18.9698C14.6665 18.037 14.6665 17.5706 14.8191 17.1697C14.9538 16.8156 15.1728 16.4996 15.4571 16.249C15.7789 15.9654 16.2156 15.8017 17.089 15.4742L23.3444 13.1284C23.587 13.0374 23.7082 12.9919 23.833 12.9739C23.9436 12.9579 24.056 12.9579 24.1667 12.9739C24.2915 12.9919 24.4127 13.0374 24.6553 13.1284L30.9107 15.4742C31.7841 15.8017 32.2208 15.9654 32.5426 16.249C32.8269 16.4996 33.0459 16.8156 33.1806 17.1697C33.3332 17.5706 33.3332 18.037 33.3332 18.9698V24.5492Z"
        stroke="#0277BD"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'EnvironmentIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 48 49'} {...props} />;
};
export default EnvironmentIcon;
