// React
import { FC, useMemo } from 'react';

// Redux

// Custom components
import CustomListView from 'components/ListView/Listview.cmp';
// import LocationsBulkAction from 'pages/Locations/LocationsList/BulkActions/LocationsBulkAction';
import { TitleColumn } from './constants';

// Utils
import { Columns, defaultColDef } from './constants';

// Hooks
import useResponsive from 'utils/CustomHooks/useResponsive';

// Styles
import './scheduleSummaryList.css';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { appRoutes } from 'utils/globalVariable';

export const ScheduleSummaryTable: FC<any> = ({
  rowData,
  searchTerm,
  groupBy,
}) => {
  const { isMobileDeviceSm } = useResponsive();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  //Fit columns to span to full width of the grid automatically
  const onGridReady = (event) => {
    setTimeout(() => {
      event.api.sizeColumnsToFit();
    }, 1000);
  };
  // navigate to location detail on click
  const handleRowClick = (id, viewType, data) => {
    if (data.data.LocationId) {
      navigateWithWorkspaceUrl(appRoutes.locations + data.data.LocationId);
    }
    if (data.data.taskUniqueId) {
      navigateWithWorkspaceUrl(`/schedule-details/${data.data.taskUniqueId}`);
    }
  };
  const onGridResize = (event) => {
    event.api.sizeColumnsToFit();
  };

  const onModelUpdated = () => {
    const { api } = globalThis.scheduleSummaryList;
    const rowsCount = api.getDisplayedRowCount();

    if (rowsCount === 0 && rowData?.length > 0 && api.isAnyFilterPresent()) {
      api.showNoRowsOverlay();
    } else {
      api.hideOverlay();
    }
  };

  const flattenList = (item, parentPath = []) => {
    const getPathName = item.LocationId || item.taskUniqueId;

    const path: any = [...parentPath, getPathName];
    const flattenedSchedules = { ...item, path };

    if (item.TaskSummaries && item.TaskSummaries.length > 0) {
      const subLocationsWithPath = item.TaskSummaries.flatMap((tasks) =>
        flattenList(tasks, path),
      );
      return [flattenedSchedules, ...subLocationsWithPath];
    }

    if (item.LocationSummaries && item.LocationSummaries.length > 0) {
      const subLocationsWithPath = item.LocationSummaries.flatMap((tasks) =>
        flattenList(tasks, path),
      );
      return [flattenedSchedules, ...subLocationsWithPath];
    }
    return [flattenedSchedules];
  };

  const formattedScheduleList = useMemo(() => {
    const flatList = rowData?.flatMap((location) => flattenList(location, []));
    return flatList;
  }, [rowData, searchTerm]);

  const getDataPath = useMemo(() => {
    return (data) => {
      return data.path;
    };
  }, []);

  const autoGroupColumnDef = useMemo(() => {
    const dynamicDef = {};
    const titleCol = TitleColumn({ groupBy: groupBy });
    return {
      ...titleCol,
      ...dynamicDef,
    };
  }, [rowData, searchTerm]);

  const gridColumns = useMemo(() => {
    const staticColumns = Columns({ groupBy: groupBy });
    return staticColumns;
  }, [rowData]);

  return (
    <>
      <CustomListView
        rootProps={{
          className: 'schedule-summary-list',
          style: {
            height: isMobileDeviceSm
              ? 'calc(100vh - 134px)'
              : 'calc(100vh - 240px)',
            boxShadow: '0px 2px 10px 0px #0000001F',
            flex: 1,
            borderRadius: 8,
            overflow: 'hidden',
            // background: '#F5F5F5',
          },
        }}
        onRowClick={handleRowClick}
        gridProps={{
          ref: (ref) => {
            globalThis.scheduleSummaryList = ref;
          },
          rowData: formattedScheduleList,
          treeData: true,
          defaultColDef: defaultColDef,
          columnDefs: gridColumns,
          onGridReady: onGridReady,
          onGridSizeChanged: onGridResize,
          suppressClickEdit: true,
          suppressCellFocus: true,
          getRowId: (params) =>
            params.data.LocationId || params.data.taskUniqueId,
          suppressRowClickSelection: true,
          groupSelectsChildren: false,
          headerHeight: 36,
          rowHeight: 56,
          autoGroupColumnDef: autoGroupColumnDef,
          getDataPath,
          onModelUpdated: onModelUpdated,
        }}
      />
    </>
  );
};
