/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useMemo, useState } from 'react';
import CustomListView from 'components/ListView/Listview.cmp';
import {
  AssetsNameColumn,
  Columns,
  defaultColDef,
  getRowStyle,
} from './constants';
import { colors } from 'components/theme/constants';
import { useSelector } from 'react-redux';
import { getAuthAssetsState } from 'store/selectors/assets';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { appRoutes } from 'utils/globalVariable';
import NoAddedRecords from '../EmptyRecords/NoAddedRecords.cmp';
import useResponsive from 'utils/CustomHooks/useResponsive';

import './assetsList.css';
import AssetsBulkAction from '../BulkActions/AssetsBulkAction';
import { useUserConfig } from 'controller/useUserConfig';
import { CircularProgress, Stack } from '@mui/material';

export const AssetsList: FC<any> = ({
  assetsList,
  setSelectedView,
  locationsFilter,
  isEmptyData,
}) => {
  const { getUserConfig, editUserConfig, userId } = useUserConfig();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { isMobileDeviceSm } = useResponsive();
  const [selectedAssets, setSelectedAssets] = useState([]);
  const originalAssetsList = useSelector(getAuthAssetsState);
  const [tableConfig, setTableConfig] = useState<any>(null);
  const [configKey, setConfigKey] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (userId) {
      setConfigKey(`assetsHomeTableConfig:${userId}`);
    }
  }, [userId]);

  useEffect(() => {
    if (configKey) {
      fetchTableConfig();
    }
  }, [configKey]);

  const fetchTableConfig = async () => {
    setLoading(true);
    const config = await getUserConfig(configKey);
    if (config) {
      setTableConfig(config.assetsHomeTableConfig);
    }
    setLoading(false);
  };

  const onColumnResized = (event) => {
    const prevConfig = tableConfig;
    const state = event.columnApi.getColumnState();
    if (event?.finished && event.source !== 'api') {
      setTableConfig(state);
      const newConfig = editUserConfig(configKey, {
        assetsHomeTableConfig: state,
      });
      if (!newConfig) {
        setTableConfig(prevConfig);
      }
    }
  };

  useEffect(() => {
    if (isMobileDeviceSm) {
      setSelectedView('grid');
    }
  }, [isMobileDeviceSm]);

  // navigate to asset detail on click
  const handleRowClick = (id, viewType, data) => {
    const urlToNavigate = data.data.ParentId
      ? `/${data?.data?.ParentId}/sa/${data?.data?.id}`
      : `/${data?.data?.id}`;
    navigateWithWorkspaceUrl(appRoutes.assets + urlToNavigate);
  };

  const gridColumns = useMemo(() => {
    const staticColumns = Columns();
    const dynamicColumns = staticColumns.map((col) => {
      const configCol = tableConfig?.find((c) => c.colId === col.colId);
      const index = tableConfig?.findIndex((c) => c.colId === col.colId);
      if (configCol) {
        return {
          ...col,
          ...configCol,
          order: index,
        };
      }
      return col;
    });
    const sortedColumns = dynamicColumns.sort((a, b) => a.order - b.order);
    return sortedColumns;
  }, [tableConfig, assetsList]);

  const handleUpdateColumnState = (event) => {
    const prevConfig = tableConfig;
    const state = event.columnApi.getColumnState();
    if (event.type === 'dragStopped') {
      setTableConfig(state);
      const newConfig = editUserConfig(configKey, {
        assetsHomeTableConfig: state,
      });
      if (!newConfig) {
        setTableConfig(prevConfig);
      }
    }
  };

  const onGridResize = (event) => {
    event.api.sizeColumnsToFit();
  };

  const onModelUpdated = () => {
    const { api } = globalThis.AssetsGrid;
    const rowsCount = api.getDisplayedRowCount();

    if (
      rowsCount === 0 &&
      originalAssetsList?.length > 0 &&
      (api.isAnyFilterPresent() || locationsFilter)
    ) {
      api.showNoRowsOverlay();
    } else {
      api.hideOverlay();
    }
  };

  const onSelectionChanged = (event) => {
    const selected = event.api.getSelectedNodes();
    setSelectedAssets(selected);
  };

  const resetSelection = () => {
    globalThis.AssetsGrid.api.deselectAll();
  };

  const getDataPath = useMemo(() => {
    return (data) => {
      return data.path;
    };
  }, []);

  //Fit columns to span to full width of the grid automatically
  const onGridReady = (event) => {
    setTimeout(() => {
      event.api.sizeColumnsToFit();
    }, 1000);
  };

  return (
    <>
      {selectedAssets?.length > 0 && (
        <AssetsBulkAction
          selected={selectedAssets}
          onClearSelection={resetSelection}
        />
      )}
      {!loading && (originalAssetsList?.length === 0 || isEmptyData) && (
        <NoAddedRecords />
      )}

      {loading && (
        <Stack width="100%" pt="70px" alignItems={'center'}>
          <CircularProgress />
        </Stack>
      )}

      {!loading && (
        <CustomListView
          rootProps={{
            className: 'assets-list',
            style: {
              height: 'calc(100vh - 188px)',
              backgroundColor: `${colors.white}`,
              boxShadow: 'none',
              border: '1px solid #d3d3d382',
              flex: 1,
              borderRadius: 8,
              overflow: 'hidden',
              padding: '0px 10px 12px 10px',
              background: '#F5F5F5',
            },
          }}
          onRowClick={handleRowClick}
          gridProps={{
            ref: (ref) => {
              globalThis.AssetsGrid = ref;
            },
            defaultColDef: defaultColDef,
            columnDefs: gridColumns,
            rowData: assetsList,
            treeData: true,
            onGridReady: onGridReady,
            onGridSizeChanged: onGridResize,
            onSortChanged: handleUpdateColumnState,
            onDragStopped: handleUpdateColumnState,
            onColumnResized: onColumnResized,
            onColumnVisible: handleUpdateColumnState,
            onColumnPinned: handleUpdateColumnState,
            onColumnMoved: handleUpdateColumnState,
            suppressClickEdit: true,
            suppressCellFocus: true,
            suppressRowClickSelection: true,
            groupSelectsChildren: false,
            rowSelection: 'multiple',
            headerHeight: 36,
            rowHeight: 56,
            autoGroupColumnDef: AssetsNameColumn,
            onSelectionChanged: onSelectionChanged,
            getRowStyle: getRowStyle,
            getRowId: (params) => params.data.id,
            getDataPath,
            onModelUpdated: onModelUpdated,
          }}
        />
      )}
    </>
  );
};
