import { useReducer, useEffect } from 'react';
import {
  EmulatorWrapper,
  TemplateNameEmulator,
  OpenOnMobileButton,
  CheckListIconBox,
} from '../style';

import TemplateBody from 'components/TemplatePreview/TemplateBody';

import { Box, Stack, Typography } from '@mui/material';
import MobileIcon from 'components/Icons/mobileIcon';
import { iconToComponentMapping } from 'pages/checklistV2/mappings';
import NewMobileEmulator from 'public/assets/img/newMobileEmulator.svg';

const Emulator = ({
  checklist,
  checklistItems,
  handleClickMobile,
  checklistItemsHashRef,
  isAIBasedTemplate,
}) => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    const intervalId = setInterval(forceUpdate, 1000);
    return () => clearInterval(intervalId);
  }, [checklist, checklistItems, checklistItemsHashRef]);

  const MuiIcon = ({ name, ...rest }) => {
    const IconComponent = iconToComponentMapping[name];
    return IconComponent ? <IconComponent {...rest} /> : null;
  };

  const { icon } = checklist || {};

  return (
    <EmulatorWrapper>
      <Typography className="title">Mobile Preview</Typography>
      <Stack
        width="320px"
        alignItems={'center'}
        position={'relative'}
        mr="-9px"
      >
        <img
          width={'310px'}
          height={'auto'}
          src={NewMobileEmulator}
          style={{
            maxWidth: 'none',
          }}
        />
        <Stack
          width="268px"
          position={'absolute'}
          zIndex={2}
          top="44px"
          height={'470px'}
          padding="5px"
          gap="8px"
          bgcolor="#F2F5F9"
          sx={{
            overflow: 'auto',
            scrollbarWidth: 'none', // For Firefox
            '&::-webkit-scrollbar': {
              display: 'none', // For Chrome, Safari, and Edge
            },
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            bgcolor={'white'}
            margin="-7px"
            padding="10px 7px 7px 7px"
            marginBottom="1px"
          >
            <CheckListIconBox
              style={{
                backgroundColor: icon?.color,
              }}
            >
              <MuiIcon name={icon?.icon} style={{ height: 16, width: 16 }} />
            </CheckListIconBox>
            <TemplateNameEmulator>{checklist?.name}</TemplateNameEmulator>
          </Stack>
          {!!checklist && checklistItems?.length > 0 && (
            <>
              <TemplateBody
                isEmulatorView={true}
                checklistItems={checklistItems.map((item) => {
                  return checklistItemsHashRef?.[item.id] ?? item;
                })}
              />
            </>
          )}
        </Stack>
      </Stack>
      {!isAIBasedTemplate && (
        <OpenOnMobileButton
          startIcon={<MobileIcon />}
          variant="outlined"
          buttonType="grayWhite"
          onClick={handleClickMobile}
        >
          Open On Mobile
        </OpenOnMobileButton>
      )}
    </EmulatorWrapper>
  );
};

export default Emulator;
