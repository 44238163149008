import config from 'config/index';
import { callApi, getItem } from 'utils/globalFunction';
import { API_ROUTES, LOCALSTORAGE_KEYS } from 'utils/globalVariable';

export const connectAIModel = async (): Promise<any> => {
  const response = await callApi(
    config.REACT_APP_BASE_URL + API_ROUTES.connectAIModel,
    null,
    'post',
    true,
    false,
    getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
    '',
    false,
    '',
    false,
  );
  if (response !== null) {
    return { ...(response?.data || {}) };
  } else {
    return { response: null };
  }
};

export const sendPromptToAI = async (data, signal = null): Promise<any> => {
  const response = await callApi(
    config.REACT_APP_BASE_URL + API_ROUTES.generateAIContent,
    data,
    'post',
    true,
    false,
    getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
    '',
    false,
    '',
    false,
    {},
    signal,
  );
  if (response !== null) {
    return { ...(response?.data || {}) };
  } else {
    return { response: null };
  }
};
