import { Box, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { XENIA_PRO_TIPS } from './utils';
import { AIFooterNotesBox } from './style';
import AiBulbIcon from 'components/Icons/aiBulbIcon';

function XeniaProTips() {
  const randomTipIndex = useMemo(() => {
    const randomNumber = Math.floor(Math.random() * 10);
    if (randomNumber >= 0 && randomNumber <= 9) {
      return randomNumber;
    }

    return 0;
  }, []);

  return (
    <AIFooterNotesBox direction="row" alignItems="center">
      <Stack
        direction="row"
        justifyContent="space-between"
        width="100%"
        style={{ padding: 15 }}
      >
        <Stack
          direction="row"
          gap="5px"
          alignItems="center"
          sx={{ '& svg': { height: '40px', width: '40px' } }}
        >
          <Box className="iconBox">
            <AiBulbIcon style={{ fill: 'none', height: 35, width: 35 }} />
          </Box>
          <Stack direction="column" gap="2px">
            <Typography className="title">
              Xenia Pro Tip:{' '}
              <span className="tipTitle">
                {XENIA_PRO_TIPS[randomTipIndex ?? 0]?.title}
              </span>
            </Typography>
            <Typography className="statement">
              {XENIA_PRO_TIPS[randomTipIndex ?? 0]?.text}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </AIFooterNotesBox>
  );
}

export default XeniaProTips;
