import { styled } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';
import { Typography } from '@mui/material';
import { Button } from '@mui/material';

import CustomModal from 'components/CustomModal';

export const StyledCustomModal = styled(CustomModal)({
  '& .MuiPaper-root': {
    borderRadius: 0,
  },
});

export const CheckListIconBox = styled('Box')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: 25,
  minWidth: 25,
  marginRight: 7,
  borderRadius: 4,
});

export const BodyWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'topalert',
})(({ topalert }) => ({
  display: 'flex',
  height: `calc(100vh - ${topalert ? '169' : '120'}px)`,
  overflow: 'auto',
}));

export const BuilderBody = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'topalert',
})(({ theme, topalert }) => ({
  padding: '20px 16px 0px 20px',
  borderRight: '1px solid #E0E0E0',
  height: `calc(100vh - ${topalert ? '169' : '120'}px)`,
  overflow: 'auto',
  width: '77%',
  position: 'relative',
}));

export const EmulatorWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'topalert',
})(({ topalert }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  padding: '24px 16px',
  gap: '9px',
  width: '23%',
  height: `calc(100vh - ${topalert ? '169' : '121'}px)`,
  overflow: 'auto',

  '& .title': {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '21.86px',
    color: '#212121',
  },
}));

export const TemplateNameEmulator = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: 15,
  lineHeight: '20.49px',
  whiteSpace: 'pre',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: '#000000',
}));

export const OpenOnMobileButton = styled(Button)(({ theme }) => ({
  borderRadius: 6,
  padding: '6px 16px',
  height: 36,
}));

export const LoaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
}));
