// React
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
// MUI Components
import { Box } from '@mui/material';
// Components
import KanbanBoard from './kanbanBoard';
import KanbanSkeleton from './skeletonLoader';
// Actions / Selectors / Utils
import selectors from 'store/selectors';

const TaskBoardView = ({
  currentTabInTasksView,
  hideMainBanner,
  taskListHeight,
}) => {
  const loaders = useSelector(selectors.getLoaders);
  const topAlertState = useSelector(selectors.getTopAlertState);

  const listHeight = useMemo(() => {
    if (taskListHeight) {
      return taskListHeight;
    }
    if (topAlertState?.show && hideMainBanner) {
      return 'calc(100vh - 235px)';
    }

    if (topAlertState?.show && !hideMainBanner) {
      return 'calc(100vh - 265px)';
    }

    if (topAlertState?.show && !hideMainBanner) {
      return 'calc(100vh - 290px)';
    }

    if (!topAlertState?.show && hideMainBanner) {
      return 'calc(100vh - 187px)';
    }

    if (!topAlertState?.show && !hideMainBanner) {
      return 'calc(100vh - 245px)';
    }

    return 'calc(100vh - 265px)';
  }, [topAlertState, hideMainBanner]);
  return (
    <Box
      style={{
        height: listHeight,
        width: '100%',
      }}
    >
      {loaders?.tasksList?.isLoading ||
      loaders?.tasksList?.isLoading === null ? (
        <KanbanSkeleton />
      ) : (
        <KanbanBoard currentTabInTasksView={currentTabInTasksView} />
      )}
    </Box>
  );
};
export default TaskBoardView;
