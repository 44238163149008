import { Box, styled } from '@mui/material';

export const MainContainer = styled(Box)({
  '& .actionTitle': {
    fontWeight: 700,
    fontSize: 15,
    lineHeight: '20.49px',
    color: '#000000',
  },
  '& .statement': {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '17.76px',
    color: '#212121',
  },
  '& .boxContainer': {
    padding: '22px 16px 22px 16px',
    border: '1px solid #E0E0E0',
    borderRadius: 16,
    cursor: 'pointer',

    '&:hover': {
      transform: 'scale(1.008)',
      transition: 'all 0.3s ease',
    },
  },

  '& .aiDocument': {
    border: '1px solid',
    borderImageSource:
      'linear-gradient(133.32deg, rgba(216, 71, 252, 0.4) 0%, rgba(65, 169, 245, 0.4) 100%)',
    background:
      'linear-gradient(133.32deg, rgba(65, 169, 245, 0.2) 0%, rgba(216, 71, 252, 0.2) 100%)',
  },

  '& .aiTemplate': {
    border: '1px solid',
    borderImageSource:
      'linear-gradient(133.32deg, rgba(252, 71, 212, 0.4) 0%, rgba(245, 162, 65, 0.4) 100%)',
    background:
      'linear-gradient(133.32deg, rgba(245, 162, 65, 0.2) 0%, rgba(252, 71, 212, 0.2) 100%)',
  },

  '& .starsIcon': {
    height: 40,
    width: 40,
  },
});
