import styled from '@mui/system/styled';

import { statusDropdownData } from 'helper/task/dropdownData';

export const TaskListTileCnt = styled('div', {
  shouldForwardProp: (prop) => prop !== 'clickable',
})(({ theme, clickable }) => ({
  border: '1px solid #E0E0E0',
  borderRadius: 12,
  padding: '12px 16px',
  marginBottom: 12,
  boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.08)',
  background: '#FFFFFF',
  position: 'relative',
  overflow: 'hidden',
  cursor: 'pointer',
  transition: '0.2s',
  height: 95,

  ':hover': {
    boxShadow: clickable ? '0px 16px 10px -20px rgba(0,0,0,0.63)' : '',
    transition: '0.2s',
  },
  '.header': {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: 18,
    },
    '.headerLeftCnt': {
      flex: 1,
      flexDirection: 'column',
      '.title': {
        color: '#212121',
        fontSize: 15,
        fontWeight: 700,
        lineHeight: '19px',
      },
    },
  },
  '.footer': {
    position: 'absolute',
    bottom: 6,
    width: 'calc(100% - 30px)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 8,
    '.footerLeft': {
      display: 'flex',
      alignItems: 'center',
    },
    '.footerLeftSmall': {
      flex: 1,
    },
    '.footerRight': {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
    },
    '.dotDivider': {
      color: '#616161',
      display: 'inline-block',
      margin: '0 10px',
    },
  },
}));

export const LeftBorder = styled('div', {
  shouldForwardProp: (prop) => prop !== 'status',
})(({ status }) => ({
  height: '100%',
  position: 'absolute',
  left: 0,
  top: 0,
  background:
    statusDropdownData.find((s) => s.id === status)?.color || 'transparent',
  width: 5,
}));
export const LocationInfo = styled('span')({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
  '.icon': {
    display: 'flex',
  },
  '.locationName': {
    color: '#424242',
    fontSize: 13,
    fontWeight: 600,
  },
});
export const CreatedByWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  '.createdBy': {
    fontWeight: '400',
    fontSize: 12,
    letterSpacing: 0.4,
    color: 'rgba(97,97,97,1)',
  },
  '.user': {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
});
export const TaskStatusChip = styled('span', {
  shouldForwardProp: (prop) => prop !== 'color' || prop !== 'background',
})(({ theme, color, background }) => ({
  fontSize: 12,
  fontWeight: 700,
  padding: '6px 12px',
  borderRadius: 90,
  color,
  background,
}));
