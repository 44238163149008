// Redux
import store from 'store';

// Custom components
import CTypography from 'components/CTypography';
import AvatarPopover from 'components/AvatarPopover';

// Icons
import RecurringIcon from 'components/Icons/recurringIcon';

// Constants
import { weeks } from 'components/RepeatTask/Weekly/constants';
import { months } from 'components/RepeatTask/Monthly/constants';
import { statusDropdownData } from 'helper/task/dropdownData';
import { DEFAULT_TIMEZONE } from 'utils/globalVariable';

// Styles
import { AssigneeCnt } from 'pages/Widgets/TaskListing/TaskListView/tasksListSummary.style';

// Utilities
import { ScheduleCnt } from 'pages/task/TaskListView/taskListView.style';
import moment from 'moment-timezone';
import AssetAttached from './renderer/assetAttached/assetAttached';
import AssigneesView from 'components/AssigneesView/AssigneesView';
import { Stack, Typography } from '@mui/material';
import LocationAttached from './renderer/locationAttached/locationAttached';
import FolderIcon from 'components/Icons/folderIcon';
import IosSwitch from 'components/IosSwitch';
import ActionDropdown from 'components/Dropdown/ActionDropdown';
import EditIconPencilBottomLine from 'components/Icons/editIconPencilBottomLine';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import TemplateAttached from './renderer/templateAttached/templateAttached';

const getTimeZone = () => {
  return (
    store?.getState()?.workspace?.activeWorkspace?.Hotel?.timezone ||
    DEFAULT_TIMEZONE
  );
};

const scheduleList = [...weeks, ...months];

export const rowHeight = 50;
export const defaultColDef = {
  resizable: true,
  hide: false,
  pdfExportOptions: { styles: { fontSize: 10 } },
  menuTabs: ['generalMenuTab'],
  suppressMenu: true,
};

export const getRowStyles = (params) => {
  const rowGroupId =
    globalThis?.taskSummaryListGrid?.columnApi?.getRowGroupColumns()?.[0]
      ?.colId;
  if (params?.node?.group && rowGroupId == 'status') {
    const selectedStatus = statusDropdownData.find(
      (s) => s.value === params?.node?.key,
    );
    return {
      background: selectedStatus?.backgroundColor,
      color: selectedStatus?.color,
      boxShadow: 'none',
      borderTop: '25px solid #fff',
    };
  }
  if (params?.node?.group) {
    return {
      background: '#ECEEF6',
      borderTop: '25px solid #fff',
    };
  }
};

export const cellRenderer = {
  title: (params) => {
    if (!params?.data) return;
    const { title } = params.data;
    return (
      <span
        style={{
          fontWeight: 400,
          fontSize: 14,
          letterSpacing: '0.17px',
          color: 'rgba(33, 33, 33, 1)',
        }}
        className="boldTextOnHover"
      >
        {title}
      </span>
    );
  },
  schedule: (params) => {
    if (!params?.data) return;
    const { requestThisTask, intervalWeek, recurringByEvery } = params.data;
    let schedule = scheduleList?.find(
      (item) => item.id === recurringByEvery,
    )?.displayTitle;

    if (
      requestThisTask === 'Weekly' &&
      intervalWeek?.length === 7 &&
      recurringByEvery === 'Week1'
    ) {
      schedule = 'Daily';
    }

    return schedule ? (
      <ScheduleCnt>
        <div className="innerCnt">
          <RecurringIcon />
          <CTypography>{schedule}</CTypography>
        </div>
      </ScheduleCnt>
    ) : (
      '-'
    );
  },
  createdBy: (row) => {
    if (!row?.data) return;
    const { Creator } = row.data;

    return (
      <AssigneeCnt>
        {!Creator && <span>{'-'}</span>}
        {Creator && (
          <div>
            <AvatarPopover userId={Creator.id} />
          </div>
        )}
      </AssigneeCnt>
    );
  },
  assignee: (row) => {
    if (!row?.data) return;
    const { TaskAssignees } = row.data;

    return (
      <AssigneeCnt data-attribute="cell-click">
        {TaskAssignees?.length == 1 && (
          <AssigneesView type="single" data={TaskAssignees} />
        )}

        {TaskAssignees?.length > 1 && (
          <AssigneesView
            type="avatars"
            data={TaskAssignees}
            assigneesProps={{ isMenuToShow: true }}
          />
        )}

        {(TaskAssignees?.length == 0 || !TaskAssignees) && '-'}
      </AssigneeCnt>
    );
  },
  template: (row) => {
    if (!row?.data) return;
    const { ChecklistId } = row.data;
    return ChecklistId ? <TemplateAttached data={row.data} /> : '-';
  },
  location: (row) => {
    if (!row?.data) return;
    const { Location } = row.data;
    return Location?.name ? <LocationAttached location={Location} /> : '-';
  },
  asset: (row) => {
    if (!row?.data) return;
    const { Asset } = row.data;
    return Asset?.name ? <AssetAttached asset={Asset} /> : '-';
  },
  nextDue: (row) => {
    if (!row?.data) return;
    const { NextDueFormatted } = row.data;

    return (
      <Stack direction="row" alignItems="center" height="100%">
        <Typography color="#212121" fontSize="14px" fontWeight="500">
          {NextDueFormatted}
        </Typography>
      </Stack>
    );
  },
  action: (row) => {
    if (!row?.data) return;
    const { NextDueFormatted } = row.data;

    return (
      <Stack
        onClick={(e) => e.stopPropagation()}
        gap="16px"
        direction="row"
        alignItems="center"
        height="100%"
      >
        <IosSwitch height={19} width={32} thumbSize={15} />
        <ActionDropdown
          options={[
            {
              label: 'Edit',
              id: 'edit',
              icon: <EditIconPencilBottomLine />,
              iconAlign: 'start',
            },
          ]}
          handleOptionSelect={(option) => console.log(option)}
        >
          <MoreHorizIcon />
        </ActionDropdown>
      </Stack>
    );
  },
};

//List View value getters
//Returned value will be the values to be shown in the cell
export const valueGetters = {
  createdBy: (params) => {
    if (!params?.data) return;
    const { Creator } = params.data;
    return Creator?.fullName;
  },
  template: (params) => {
    if (!params?.data) return;
    const { ChecklistId } = params.data;
    return ChecklistId || '';
  },
  location: (params) => {
    if (!params?.data) return;
    const { Location } = params.data;
    return Location?.name || '';
  },
  asset: (params) => {
    if (!params?.data) return;
    const { Asset } = params.data;
    return Asset?.name || '';
  },
  assignee: (params) => {
    if (!params?.data) return;
    const { Assigned, Team } = params.data;
    if (!params?.node?.group && !Assigned && !Team) {
      return 'Unassigned';
    }
    const AssigneeName =
      Assigned?.firstName && `${Assigned?.firstName} ${Assigned?.lastName}`;
    const TeamName = Team?.name;
    return AssigneeName || TeamName;
  },
  nextDue: (params) => {
    const tz = getTimeZone();
    if (!params.data) return null;
    const { NextDue } = params.data;
    return NextDue
      ? moment.tz(NextDue.dueDate, tz).format('MMM DD YYYY hh:mm a')
      : '';
  },
};

export const columns: any = (props) => {
  return [
    {
      field: 'title',
      headerName: 'Title',
      colId: 'title',
      sortable: true,
      cellRenderer: cellRenderer.title,
      pinned: 'left',
    },
    {
      field: 'recurringByEvery',
      headerName: 'Schedule',
      colId: 'schedule',
      sortable: true,
      cellRenderer: cellRenderer.schedule,
      resizable: true,
    },
    {
      field: 'Creator',
      headerName: 'Created by',
      colId: 'createdBy',
      comparator: comparator.assignee,
      valueGetter: valueGetters.createdBy,
      sortable: true,
      cellRenderer: cellRenderer.createdBy,
    },
    {
      field: 'assignedTo',
      headerName: 'Assigned To',
      colId: 'assigned_to',
      comparator: comparator.assignee,
      valueGetter: valueGetters.assignee,
      sortable: true,
      cellRenderer: cellRenderer.assignee,
    },
    {
      field: 'Associated Template',
      headerName: 'Associated Template',
      colId: 'assosiated_template',
      valueGetter: valueGetters.template,
      sortable: true,
      cellRenderer: cellRenderer.template,
    },
    {
      field: 'Location',
      headerName: 'Location',
      colId: 'Location',
      valueGetter: valueGetters.location,
      sortable: true,
      cellRenderer: cellRenderer.location,
    },
    {
      field: 'Asset',
      headerName: 'Asset',
      colId: 'Asset',
      valueGetter: valueGetters.asset,
      sortable: true,
      cellRenderer: cellRenderer.asset,
    },
    {
      field: 'NextDue',
      headerName: 'Next Due',
      colId: 'nextDue',
      minWidth: 450,
      sortable: true,
      comparator: comparator.date,
      cellRenderer: cellRenderer.nextDue,
      valueGetter: valueGetters.nextDue,
    },
    // {
    //   field: 'action',
    //   headerName: '',
    //   colId: 'action',
    //   sortable: false,
    //   cellRenderer: cellRenderer.action,
    // },
  ];
};

const comparator = {
  date: (date1, date2) => {
    const date1Number = date1 ? new Date(date1).getTime() : null;
    const date2Number = date2 ? new Date(date2).getTime() : null;

    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }

    return date1Number - date2Number;
  },
  assignee: (a1, a2) => {
    const assignee1 = a1 || '-';
    const assignee2 = a2 || '-';
    return assignee1?.toLowerCase() == assignee2?.toLowerCase()
      ? 0
      : assignee1?.toLowerCase() > assignee2?.toLowerCase()
      ? 1
      : -1;
  },
};
