import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import CustomDialog from 'components/CustomDialog/customDialog';
import CustomInput from 'components/Form/TextField/TextField.cmp';

import DIALOGS from 'utils/dialogIds';
import selectors from 'store/selectors';
import { setDialog } from 'store/actions/dialogActions';
import actions from 'store/actions';
import { CustomSelect } from 'components/CustomSelect/CustomSelect';
import CustomButton from 'components/Button/CustomButton';
import AIStarIcon from 'components/Icons/aiStarIcon';
import CrossIcon from 'components/Icons/crossIcon';
import {
  getIndustryIcon,
  sortIndustries,
} from 'pages/onboarding/organization-info/utils';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

import { CrossIconBtn, InfoBanner, MainContainer } from './style';
import {
  INDEXED_DB_NAME,
  INDEXED_STORE_NAME,
  TEXT_BASED_PROMPT,
} from 'utils/constants';
import useIndexedDB from 'utils/CustomHooks/useIndexedDB';
import { WarningOutlined } from '@mui/icons-material';

const CreateTemplateWithAI = () => {
  const [error, setError] = useState(false);
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const dispatch = useDispatch();
  const dialogState = useSelector(selectors.getDialogState);
  const { open, data } = dialogState?.[DIALOGS?.CREATE_TEMPLATE_WITH_AI] || [];

  const { onCloseHandler = null, onSubmitHandler = null } = data || {};

  const { saveIndexedDBData } = useIndexedDB(
    INDEXED_DB_NAME,
    INDEXED_STORE_NAME,
  );
  const types: any = useSelector(selectors.getChecklistTypes);
  const industries: any = useSelector(selectors.getChecklistIndustries);

  const [metadata, setMetadata] = useState<any>({
    description: '',
    checklistCategory: null,
    checklistIndustry: null,
    additionalInfo: '',
  });

  useEffect(() => {
    if (data?.metadata && types?.length > 0 && industries?.length > 0) {
      setMetadata({
        description: data?.metadata?.description ?? '',
        additionalInfo: data?.metadata?.additionalInfo ?? '',
        checklistCategory: data?.metadata?.checklistCategory ?? null,
        checklistIndustry: data?.metadata?.checklistIndustry ?? null,
      });
    }
  }, [types, industries, data?.metadata]);

  useEffect(() => {
    if (data?.showError) {
      setError(true);
    }
  }, [data?.showError]);

  const handleClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS?.CREATE_TEMPLATE_WITH_AI,
      }),
    );
  };

  const handleChangeMetadata = (key, value) => {
    setError(false);
    setMetadata({
      ...metadata,
      [key]: value,
    });
  };

  useEffect(() => {
    dispatch(actions.getChecklistTypes());
    dispatch(actions.getChecklistIndustries());
  }, []);

  const generateTemplateHandler = async () => {
    const payload = {
      id: 1,
      description: metadata?.description,
      additionalInfo: metadata?.additionalInfo ?? '',
      checklistCategory: metadata?.checklistCategory,
      checklistIndustry: metadata?.checklistIndustry,
      type: TEXT_BASED_PROMPT,
    };

    await saveIndexedDBData(payload).then(console.log).catch(console.error);

    if (onSubmitHandler) {
      onSubmitHandler?.();
    } else {
      navigateWithWorkspaceUrl('/checklist/new?ai=true');
    }
    handleClose();
  };

  const TypesOptions = useMemo(() => {
    if (!types || types?.length === 0) return [];

    return types.map((item) => {
      return {
        label: item.name,
        value: item.id,
        id: item.id,
      };
    });
  }, [types]);

  const industriesOptions = useMemo(() => {
    if (!industries || industries?.length === 0) return [];

    const filteredIndustries = industries?.filter(
      (industry) => !['General', 'Security Services'].includes(industry?.name),
    );

    const sortedIndustries = sortIndustries(filteredIndustries);

    return sortedIndustries.map((item) => {
      return {
        label: item.name,
        value: item.id,
        id: item.id,
        startIcon: <img src={getIndustryIcon(item.name)} />,
      };
    });
  }, [industries]);

  const isButtonDisable = useMemo(() => {
    if (
      !metadata?.description ||
      !metadata?.checklistCategory ||
      !metadata?.checklistIndustry
    )
      return true;

    return false;
  }, [metadata]);

  return (
    <CustomDialog
      hideActions={true}
      dialogProps={{
        open: open,
        maxWidth: false,
      }}
    >
      <MainContainer>
        <Grid container spacing={2} style={{ height: 'inherit' }}>
          <Grid item xs={4} style={{ height: 'inherit' }}>
            <Box className="leftSide">
              <div className="content">
                <AIStarIcon />
                <Typography className="title">
                  Instant Templates, Effortless Operations
                </Typography>
                <Typography className="statement">
                  Transform your ideas into actionable templates with our
                  AI-powered wizardry. Say goodbye to manual formatting and
                  hello to the future of streamlined operations.
                </Typography>
              </div>

              <Box className="sideNote">
                <Typography className="title">How does this work?</Typography>
                <Typography className="statement">
                  We’ll use third-party AI provider to generate content based on
                  your provided description. Avoid using personal and sensitive
                  data.
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={8}>
            <Box
              className="rightSide"
              style={{
                ...(error && {
                  padding: '20px 30px',
                }),
              }}
            >
              <CrossIconBtn
                className="closeIcon"
                onClick={() => {
                  onCloseHandler?.();
                  handleClose();
                }}
              >
                <CrossIcon style={{ height: 20 }} />
              </CrossIconBtn>

              <Typography className="title">Create Template with AI</Typography>
              <Typography className="statement">
                Need help building template? Let our AI tool craft personalized
                template for you.
              </Typography>

              {error && (
                <InfoBanner direction="row" alignItems="center" gap="7px">
                  <WarningOutlined className="icon" />
                  <Typography className="content">
                    Looks like there was an issue processing your request.
                    Please try again
                  </Typography>
                </InfoBanner>
              )}

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Stack direction="column" gap="5px" className="selectListing">
                    <Typography className="label">Type</Typography>
                    <CustomSelect
                      selectedOption={
                        TypesOptions?.find(
                          (item) => item?.id === metadata?.checklistCategory,
                        ) || null
                      }
                      style={{ marginBottom: 8 }}
                      options={TypesOptions}
                      onChange={(val) =>
                        handleChangeMetadata('checklistCategory', val?.id)
                      }
                      placeholder="Select"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction="column" gap="5px" className="selectListing">
                    <Typography className="label">Industry</Typography>
                    <CustomSelect
                      selectedOption={
                        industriesOptions?.find(
                          (item) => item?.id === metadata?.checklistIndustry,
                        ) || null
                      }
                      style={{ marginBottom: 8 }}
                      options={industriesOptions}
                      onChange={(val) =>
                        handleChangeMetadata('checklistIndustry', val?.id)
                      }
                      placeholder="Select"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="column" gap="5px">
                    <Typography className="label">
                      Additional Business Details (Optional)
                    </Typography>
                    <CustomInput
                      suppressErrorSpace={true}
                      muiLabel={false}
                      sx={{
                        '& .MuiOutlinedInput-input': {
                          fontSize: 15,
                          fontWeight: 500,
                        },
                      }}
                      fieldProps={{
                        placeholder: 'Additional Business Information',
                        onChange: (e) =>
                          handleChangeMetadata(
                            'additionalInfo',
                            e.target.value,
                          ),
                        value: metadata.additionalInfo ?? '',
                      }}
                    />
                  </Stack>
                </Grid>

                <Divider className="separator" />

                <Grid item xs={12}>
                  <Stack direction="column" gap="5px">
                    <Typography
                      fontWeight="700"
                      fontSize="19px"
                      lineHeight="21.86px"
                      color="rgba(0, 0, 0, 1)"
                    >
                      Description
                    </Typography>
                    <Typography className="label">
                      The more detail you provide, the better the template will
                      match your requirements.
                    </Typography>
                    <CustomInput
                      sx={{
                        mb: 3,
                        '& .MuiOutlinedInput-input': {
                          fontSize: 16,
                          fontWeight: 600,
                        },
                      }}
                      suppressErrorSpace={true}
                      muiLabel={false}
                      fieldProps={{
                        placeholder: 'Add a description',
                        onChange: (e) =>
                          handleChangeMetadata('description', e.target.value),
                        multiline: true,
                        rows: 5,
                        value: metadata.description ?? '',
                      }}
                    />
                  </Stack>
                </Grid>
              </Grid>

              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{
                  '& path': {
                    stroke: 'white',
                  },
                }}
              >
                <CustomButton
                  variant="contained"
                  onClick={generateTemplateHandler}
                  disabled={isButtonDisable}
                  style={{
                    background: isButtonDisable
                      ? 'lightGray'
                      : 'linear-gradient(133.32deg, #F5A241 0%, #FC47D4 100%)',
                  }}
                  className="actionBtn"
                  sx={{
                    '& svg': {
                      height: 30,
                      width: 30,
                    },
                  }}
                  startIcon={
                    isButtonDisable ? null : <AIStarIcon baseColor="#fff" />
                  }
                >
                  Generate Template
                </CustomButton>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </MainContainer>
    </CustomDialog>
  );
};

export default CreateTemplateWithAI;
