import { useEffect, useMemo, useState } from 'react';

import selectors from 'store/selectors';
import { useSelector } from 'react-redux';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import SelectItemsList from 'components/Dropdown/SelectItemsList/SelectItemsList';
import {
  generateAssigneeDropdownData,
  generateTeamsFilterDropdownData,
} from 'helper/task/dropdownData';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  StyledEndIconCnt,
  StyledSelectedText,
  AssigneeDropdownButton,
  RemoveIconBtn,
} from './employeeFilter.style';
import CancelIcon from '@mui/icons-material/Cancel';
import useUsers from 'utils/CustomHooks/useUsers';

function StatusList(props) {
  const {
    selectedOption,
    setSelectedOption,
    onChangeCallback,
    showAuthorizedAssignees = false,
  } = props;

  const { taskAssignees, userList } = useUsers();
  const teams = useSelector(selectors.getTeams);
  const authorizedTeams = useSelector(selectors.getAuthorizedTeams);

  const assigneeDropdownData = useMemo(() => {
    return generateAssigneeDropdownData(taskAssignees(showAuthorizedAssignees));
  }, [userList]);

  // Generating Teams data for dropdown
  const teamsDropdownData = useMemo(() => {
    return generateTeamsFilterDropdownData(
      showAuthorizedAssignees ? authorizedTeams : teams,
    );
  }, [teams, authorizedTeams]);
  const taskAssigneesData = [
    ...(teamsDropdownData?.length
      ? [
          {
            label: 'Teams',
            id: 'teams',
            child: teamsDropdownData,
          },
        ]
      : []),
    { label: 'People', id: 'people', child: assigneeDropdownData },
  ];
  return (
    <SelectItemsList
      searchFieldProps={{ autoFocus: false }}
      selectedOptions={selectedOption}
      controlled={true}
      checkbox={true}
      canSearch={true}
      options={taskAssigneesData}
      checkboxProps={{ size: 'small' }}
      handleChangeCallback={(values) => {
        setSelectedOption(values);
        onChangeCallback?.(values);
      }}
    />
  );
}
function AssigneeFilterDropdown(props) {
  const {
    buttonProps = {},
    selected = null,
    children,
    dropdownProps,
    onChangeCallback,
    onClearCallback,
    showAuthorizedAssignees = false,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState<any[]>(selected ?? []);

  useEffect(() => {
    setSelectedOption(selected ?? []);
  }, [selected]);
  const handleDropdownOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleClearOptions = (event) => {
    event.stopPropagation();
    setSelectedOption([]);
    onClearCallback?.();
  };
  const selectedText = useMemo(() => {
    if (selectedOption && selectedOption.length === 1) {
      return selectedOption[0].label;
    } else if (selectedOption && selectedOption.length > 1) {
      return `${selectedOption.length} Employees`;
    } else {
      return 'Employee: All';
    }
  }, [selectedOption]);
  return (
    <ClickAwayListener
      onClickAway={handleDropdownClose}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <div>
        <AssigneeDropdownButton
          variant="contained"
          buttonType="grayDropdown"
          onClick={handleDropdownOpen}
          isSelected={selectedOption.length > 0}
          endIcon={
            <StyledEndIconCnt>
              <KeyboardArrowDownIcon style={{ fontSize: 20 }} />
            </StyledEndIconCnt>
          }
        >
          <StyledSelectedText>{selectedText}</StyledSelectedText>
          {selectedOption?.length ? (
            <RemoveIconBtn
              className="remove-assignee"
              onClick={handleClearOptions}
            >
              <CancelIcon />
            </RemoveIconBtn>
          ) : null}
        </AssigneeDropdownButton>

        <CustomDropdown
          popperProps={{
            placement: 'bottom-start',
            content: (
              <StatusList
                closeAction={handleDropdownClose}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                onChangeCallback={onChangeCallback}
                showAuthorizedAssignees={showAuthorizedAssignees}
                {...dropdownProps}
              />
            ),
            style: { zIndex: 9999, width: 400 },

            open: !!anchorEl,
            anchorEl,
          }}
          buttonProps={buttonProps}
          clickaway={false}
        >
          {children}
        </CustomDropdown>
      </div>
    </ClickAwayListener>
  );
}

export default AssigneeFilterDropdown;
