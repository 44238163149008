// React
import React, { Fragment, useMemo } from 'react';
import UserAvatar from 'components/Avatar';
import UserOrTeamAvatarGroup from 'components/Avatar/UserOrTeamAvatarGroup';

const TaskAssignee = ({ task }: { task: any }) => {
  const { TaskAssignees } = task;
  if (!TaskAssignees?.length) return null;
  return (
    <>
      <span className={'dotDivider'}>•</span>

      {TaskAssignees && !task.Team && (
        <UserOrTeamAvatarGroup
          members={TaskAssignees.map((assignee) => assignee.AssignId)}
        />
      )}
    </>
  );
};

export default TaskAssignee;
