import { useCallback, useEffect, useMemo, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import SelectItemsDropdown from 'components/Dropdown/SelectItemsDropdown/SelectItemsDropdown';
import { setDialog } from 'store/actions/dialogActions';
import CustomListView from 'components/ListView/Listview.cmp';
import CModalConfirmation from 'components/CModalConfirmation';

import UsersSearchInput from './Search/search';
import {
  deleteUserTypeApi,
  getUserListApi,
  updateUserStatusApi,
  updateUserTypeApi,
  resendInviteApi,
  updateUserLocations,
} from 'api/userApi';
import { defaultColDef, generateColumns, userTypeOptions } from './constants';
import { GridReadyEvent } from 'ag-grid-community';
import { TData } from 'memfs/lib/volume';
import TableEmptyState from './emptyState/emptyState.view';
import { passwordResetInstructionsApi } from 'api/authApi';
import CustomButton from 'components/Button/CustomButton';
import {
  deleteUser,
  setAllUsers,
  updateUser,
  updateUserStatus,
} from 'store/actions/userActions';
import selectors from 'store/selectors';
import actions from 'store/actions';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useUserRoles } from 'utils/CustomHooks/useUserRoles';
import UserCounterProgress from 'components/UserCounterProgress';
import { TitleAndCounter } from './styles';
import { getListParams } from 'utils/CustomHooks/useInitialDataLoad';
import { Stack } from '@mui/material';
import useWorkspaceConfig from 'utils/CustomHooks/useWorkspaceConfig';
import useLocationsUtils from 'utils/CustomHooks/useLocationsUtils';
// Styles
import './style.css';
import useCustomRoles from 'utils/CustomHooks/useCustomRoles';

const UserManagement = ({
  disableAddUsers = false,
}: {
  disableAddUsers?: boolean;
}) => {
  const dispatch = useDispatch();
  const { config } = useWorkspaceConfig();
  const { workspaceId } = useWorkspaceHook();
  const { fetchCustomRoles } = useCustomRoles();
  const { fetchAuthLocations } = useLocationsUtils();
  const { roles } = useUserRoles();
  const [userType, setUserType] = useState<any>(userTypeOptions[0]);
  const [fetching, setFetching] = useState(true);
  const tableData: any = useSelector(selectors.getAllUsersList);
  const topAlertState = useSelector(selectors.getTopAlertState);

  const { kioskMode: kioskConfig } = config || {};

  const getUserListData = () => {
    dispatch(
      actions.userListApiCall(
        getListParams({ sortBy: 'firstName', sortType: 'ASC', type: 'ALL' }),
        'task',
      ),
    );
  };
  const fetchUsersList = async () => {
    const result = await getUserListApi();
    const data = result.data.length > 0 ? result.data : [];
    dispatch(setAllUsers(data));
    setFetching(false);
  };

  const deleteSingleUser = async (userId) => {
    dispatch(deleteUser(userId));
    await deleteUserTypeApi(userId);
    getUserListData();
    dispatch(actions.getBillingInfo(workspaceId as string));
    await fetchUsersList();
  };

  const updateUserRole = async (data, value) => {
    const RoleId = value[0].id;
    dispatch(updateUser({ id: data.id, RoleId }));
    await updateUserTypeApi(data.id, RoleId);
    await fetchUsersList();
    getUserListData();
    fetchCustomRoles({ payload: {}, forceLoad: true });
  };

  const setUserStatus = async (userId, status) => {
    dispatch(updateUserStatus({ status: status, id: userId }));
    await updateUserStatusApi(userId, status);
    dispatch(actions.getBillingInfo(workspaceId as string));
    await fetchUsersList();
    getUserListData();
  };

  useEffect(() => {
    (async () => await fetchUsersList())();
  }, []);

  // UI Methods
  const filterUserByStatus = useMemo(() => {
    const prevData = tableData;
    if (prevData.length === 0) {
      return [];
    } else {
      const data = tableData.filter((row: any) => {
        if (userType.value === 'all') {
          return true;
        } else if (
          row?.UserHotels?.[0].status.toLowerCase() === userType.value
        ) {
          return true;
        } else return false;
      });
      return data;
    }
  }, [tableData, userType]);

  const updateLocation = async (id, locations) => {
    const formattedLocations = locations.map((id) => {
      return {
        LocationId: id,
      };
    });

    dispatch(updateUser({ id, UserLocations: formattedLocations || [] }));

    await updateUserLocations(
      id,
      { locations },
      'User Locations Memebership has been updated successfully!',
    );

    fetchAuthLocations();
  };

  //Fit columns to span to full width of the grid automatically
  const onGridReady = (event: GridReadyEvent<TData>) => {
    setTimeout(() => {
      event.api.sizeColumnsToFit();
    }, 1000);
  };

  const onGridResize = (event: GridReadyEvent<TData>) => {
    event.api.sizeColumnsToFit();
  };

  const handleOptionSelect = (option) => {
    switch (option.id) {
      case 'send_password_instructions':
        {
          passwordResetInstructionsApi({
            emailId: option.data.emailId,
            forgotPassword: true,
          });
        }
        break;
      case 'resend_invitation':
        resendInviteApi({
          userId: option.data.id,
        });

        break;
      case 'mark_active':
        {
          const existingStatus = option.data?.UserHotels?.[0]?.status;
          const status = ['inactive', 'pending'].includes(
            existingStatus.toLowerCase(),
          )
            ? 'Active'
            : 'Inactive';
          setUserStatus(option.data.id, status);
        }
        break;
      case 'delete_user':
        {
          dispatch(
            setDialog({
              dialogId: 'confirmAddUser',
              open: true,
              data: {
                content: `Are you sure you want to delete ${option.data.fullName}?`,
                handleSave: () => {
                  deleteSingleUser(option.data.id);
                },
              },
            }),
          );
        }
        break;
    }
  };

  const gridColumns = useMemo(
    () =>
      generateColumns(
        handleOptionSelect,
        updateUserRole,
        roles,
        updateLocation,
        {
          locations: !config?.advancedLocationBasedAssignment,
          pincode: !kioskConfig?.isEnable,
        },
      ),
    [kioskConfig, config, roles],
  );

  return (
    <Stack width={'100%'}>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        padding="16px 0px"
      >
        <TitleAndCounter>
          <UserCounterProgress />
        </TitleAndCounter>
        <div style={{ display: 'flex' }}>
          <UsersSearchInput />
          {userType !== null && (
            <div style={{ width: 120, marginRight: disableAddUsers ? '' : 8 }}>
              <SelectItemsDropdown
                controlled
                buttonProps={{
                  id: 'statusFilterDropdownBtn',
                  variant: 'dropdown',
                  style: {
                    marginRight: 8,
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: 120,
                    paddingTop: 5,
                    paddingBottom: 5,
                    borderRadius: 8,
                    height: 40,
                  },
                }}
                popperProps={{
                  id: 'statusFilterDropdown333',
                  style: { width: 120 },
                }}
                options={userTypeOptions}
                handleChangeCallback={(value) => {
                  setUserType(value[0]);
                }}
              >
                {userType.label}
              </SelectItemsDropdown>
            </div>
          )}
          {!disableAddUsers && (
            <CustomButton
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              sx={{ borderRadius: '8px' }}
              onClick={() => {
                dispatch(
                  setDialog({
                    dialogId: 'inviteUserDialog',
                    open: true,
                    data: {
                      onSuccessCallback: () => fetchUsersList(),
                    },
                  }),
                );
              }}
            >
              Add User
            </CustomButton>
          )}
        </div>
      </Stack>

      {filterUserByStatus.length > 0 && !fetching && (
        <CustomListView
          rootProps={{
            className: 'user-table',
            style: {
              height: topAlertState?.show
                ? 'calc(100vh - 295px)'
                : 'calc(100vh - 223px)',
              boxShadow: 'none',
              padding: '0',
            },
          }}
          onRowClick={() => {
            // handle row click here
          }}
          gridProps={{
            ref: (ref) => {
              globalThis.userListGrid = ref;
            },
            headerHeight: 30,
            rowData: filterUserByStatus,
            getRowId: (params) => params.data.id,
            columnDefs: gridColumns,
            defaultColDef: defaultColDef,
            deltaRowDataMode: false,
            onGridReady: onGridReady,
            onGridSizeChanged: onGridResize,
          }}
        />
      )}
      {!filterUserByStatus.length && !fetching && (
        <TableEmptyState
          primaryMessage="You have no users yet"
          secondaryMessage={
            <>
              Use the <b>Add User</b> button to invite your users
            </>
          }
          buttonText="Add User"
          buttonHandleClick={() => {
            dispatch(
              setDialog({
                dialogId: 'inviteUserDialog',
                open: true,
              }),
            );
          }}
        />
      )}

      <CModalConfirmation
        dialogId="confirmAddUser"
        handleSaveButtonText={'Yes, Delete'}
      />
    </Stack>
  );
};

export default UserManagement;
