// Components
import { Draggable } from 'react-beautiful-dnd';

export const DraggableCmp = ({ dndPropss, children }) => {
  return (
    <Draggable {...dndPropss}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...provided.draggableProps.style,
          }}
        >
          {children}
        </div>
      )}
    </Draggable>
  );
};
