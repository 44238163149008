import { connectAIModel, sendPromptToAI } from 'api/aiModalApi';
import { STEP_TYPES } from 'utils/templates';
import { v4 as uuid } from 'uuid';
import useIndexedDB from './useIndexedDB';
import { INDEXED_DB_NAME, INDEXED_STORE_NAME } from 'utils/constants';
import { sample } from 'lodash';
import { _colors } from 'components/ColorPicker';

function useAITemplate() {
  const { getIndexedDBData } = useIndexedDB(
    INDEXED_DB_NAME,
    INDEXED_STORE_NAME,
  );

  const getPayloadForAI = async () => {
    const data = await getIndexedDBData(1);
    if (!data) return null;

    return data;
  };

  const getAIBasedTemplate = async (payload, signal) => {
    try {
      const connectionResponse = await connectAIModel();

      const modalResponse = await sendPromptToAI(
        {
          ...payload,
          chatId: connectionResponse?.id,
        },
        signal,
      );

      if (Object.keys(modalResponse)?.length > 0) {
        const transformedData = dataTransformation(modalResponse);
        return transformedData;
      }

      return null;
    } catch (e: any) {
      if (e?.cancelled) {
        return e;
      }
      return null;
    }
  };

  const getAIBasedTemplateByDocument = async (payload) => {
    try {
      const connectionResponse = await connectAIModel();

      const formData = new FormData();
      formData.append('chatId', connectionResponse?.id);
      formData.append('additionalInfo', payload?.additionalInfo);
      formData.append('file', payload?.attachment);

      const modalResponse = await sendPromptToAI(formData);
      if (Object.keys(modalResponse)?.length > 0) {
        const transformedData = dataTransformation(modalResponse);
        return transformedData;
      }

      return null;
    } catch (e) {
      console.log('AI error:', e);
      return null;
    }
  };

  const dataTransformation = (data) => {
    const transformedData = {
      id: uuid(),
      name: data?.title,
      icon: {
        icon: 'Default',
        color: '#FFA681',
      },
      ChecklistItems: [],
    };

    let checklistItems: any = [];
    for (let index = 0; index < data?.sections?.length; index++) {
      const stepsList = data?.sections?.[index]?.items?.map((item) => {
        const updatedItem: any = {
          id: uuid(),
          description: item?.item,
          order: item?.order,
          type: STEP_TYPES[item?.type],
          isActive: true,
        };

        if (item?.type === 'DROPDOWN' || item?.type === 'MULTIPLE_CHOICE') {
          updatedItem.options = item?.answers?.map((answer, index) => {
            return {
              id: uuid(),
              label: answer,
              color: sample(_colors) as string,
              flag: false,
              order: index + 1,
              score: 1,
            };
          });
        }

        return updatedItem;
      });

      if (stepsList?.length > 0) {
        checklistItems = [...checklistItems, ...stepsList];
      }
    }

    transformedData.ChecklistItems = checklistItems ?? [];

    return transformedData;
  };

  return {
    getPayloadForAI,
    getAIBasedTemplate,
    getAIBasedTemplateByDocument,
  };
}

export default useAITemplate;
