import styled from '@mui/system/styled';

export const HeaderCnt = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '0 24px 12px 24px',
  '.lefCnt': {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
  '.rightCnt': {
    display: 'flex',
    alignItems: 'center',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 8,
  },
}));
export const ResponsiveHeaderCnt = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '0 24px 12px 24px',
  gap: 8,
  '.lefCnt': {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    width: '100%',
  },
  '.rightCnt': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));
export const TaskActionDropdownCnt = styled('div')(({ theme }) => ({
  position: 'fixed',
  zIndex: 1,
  bottom: 10,
  right: 10,
  '& .actionDropdownBtn': {
    padding: 0,
    '& svg': {
      color: 'white',
    },
    background: '#4E48FA',
    width: 48,
    height: 48,
    borderRadius: '100%',
  },
}));
