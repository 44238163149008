// React
import React, { Fragment, useMemo } from 'react';

// Icons
import CategoryIcon from 'components/Icons/categoryIcon';

import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { COLORS_LIST } from 'utils/constants';
import { StyledCategoryChip } from 'pages/task/ListViewV2/TaskTile/category/style';

const CategoryChip = ({
  task,
  hideSeprator = false,
}: {
  task: any;
  hideSeprator?: boolean;
}) => {
  const categoryList = useSelector(selectors.getServiceAdmin);
  const taskCategory = useMemo(
    () => categoryList?.find((cat) => cat.id === task?.ServiceId),
    [task, categoryList],
  );
  const taskCategoryColor = useMemo(() => {
    if (taskCategory) {
      const color = COLORS_LIST.find((c) => c.color === taskCategory.color);
      return color;
    }
    return COLORS_LIST[0];
  }, [task, taskCategory]);
  if (!taskCategory) return null;
  return (
    <Fragment>
      {' '}
      {!hideSeprator && <span className={'dotDivider'}>•</span>}
      {taskCategory ? (
        <StyledCategoryChip
          bg={taskCategoryColor?.color}
          color={taskCategoryColor?.textColor}
          border={taskCategoryColor?.borderColor}
        >
          {taskCategory?.type || ''}
        </StyledCategoryChip>
      ) : (
        <></>
      )}
    </Fragment>
  );
};

export default CategoryChip;
