import { createSvgIcon } from '@mui/material';

const KanbanIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M5.5 12.5V3.5C5.5 2.80109 5.5 2.45163 5.38582 2.17597C5.23358 1.80843 4.94157 1.51642 4.57403 1.36418C4.29837 1.25 3.94891 1.25 3.25 1.25C2.55109 1.25 2.20163 1.25 1.92597 1.36418C1.55843 1.51642 1.26642 1.80843 1.11418 2.17597C1 2.45163 1 2.80109 1 3.5V12.5C1 13.1989 1 13.5484 1.11418 13.824C1.26642 14.1916 1.55843 14.4836 1.92597 14.6358C2.20163 14.75 2.55109 14.75 3.25 14.75C3.94891 14.75 4.29837 14.75 4.57403 14.6358C4.94157 14.4836 5.23358 14.1916 5.38582 13.824C5.5 13.5484 5.5 13.1989 5.5 12.5Z"
        stroke="#616161"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13 9.5V3.5C13 2.80109 13 2.45163 12.8858 2.17597C12.7336 1.80843 12.4416 1.51642 12.074 1.36418C11.7984 1.25 11.4489 1.25 10.75 1.25C10.0511 1.25 9.70163 1.25 9.42598 1.36418C9.05843 1.51642 8.76642 1.80843 8.61418 2.17597C8.5 2.45163 8.5 2.80109 8.5 3.5V9.5C8.5 10.1989 8.5 10.5484 8.61418 10.824C8.76642 11.1916 9.05843 11.4836 9.42598 11.6358C9.70163 11.75 10.0511 11.75 10.75 11.75C11.4489 11.75 11.7984 11.75 12.074 11.6358C12.4416 11.4836 12.7336 11.1916 12.8858 10.824C13 10.5484 13 10.1989 13 9.5Z"
        stroke="#616161"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'KanbanIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 14 16'} {...props} />;
};
export default KanbanIcon;
