// React
import React, { useState } from 'react';

// Redux
import { useDispatch } from 'react-redux';

// Custom components
import ActionDropdown from 'components/Dropdown/ActionDropdown';

// MUI
import {
  Divider,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';

// Icons
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import GearIcon from 'components/Icons/gearIcon';
import EditIconPencilBox from 'components/Icons/editIconPencilBox';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import GlobeIcon from 'components/Icons/globeIcon';
import DisabledGlobeIcon from 'components/Icons/disabledGlobeIcon';
import LinkIconRotated from 'components/Icons/LinkIconRotated';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import LightbulbIcon from 'components/Icons/lightBulbIcon';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import HelpInChatIcon from 'components/Icons/helpInChatIcon';

// Styled
import {
  RowWrapper,
  FirstRow,
  GoBackWrapper,
  BackText,
  ToggleBarCnt,
  FirstRowButtonsWrapper,
  ShareButton,
  SecondRow,
  TemplateNameText,
  LastModifiedTextWrapper,
  SecondRowButtons,
  StyledButton,
} from './styled';

// Utilities
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import xeniaApi from 'api/index';
import actions from 'store/actions';
import PublishDialog from 'pages/checklistV2/Builder/PublishDialog';
import TopAlert from 'components/TopAlert';
import classNames from 'classnames';
import DIALOGS from 'utils/dialogIds';
import { isEqual, set } from 'lodash';

// Constants
import { TALK_TO_SALES_URL } from 'utils/constants';
import { RepeatTaskSwitch as CustomSwitch } from 'components/AllDialogs/tasks/createTask/createTask.style';
import { VerifyFeatureAccess } from 'components/UpSellModal/VerifyFeatureAccess';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import CloudIcon from 'components/Icons/cloudIcon';

const Header = ({
  checklist,
  setShowStepType,
  viewToggle,
  handleViewToggleClick,
  redirectTo,
  handleOpenSettings,
  setChecklist,
  lastSavedAt,
  isAIBasedTemplate,
}) => {
  const { navigateBackWithWorkspace } = useWorkspaceHook();
  const { FEATURES } = useCustomPermission();
  const dispatch = useDispatch();

  const [publishLoader, setPublishLoader] = useState(false);

  const togglePublishState = async () => {
    setPublishLoader(true);
    await xeniaApi.toggleChecklistPublishState(
      checklist?.id,
      checklist?.isPublished ? 'Template Unpublished' : 'Template Published',
    );
    setChecklist({ ...checklist, isPublished: !checklist?.isPublished });
    setPublishLoader(false);
  };

  const toggleScoring = async (isScoring: boolean) => {
    const patchData = {
      isScoring,
      ...(!isScoring && {
        isShowScoreInProgress: false,
        isShowScoreInSteps: false,
      }),
    };
    const previousChecklist = { ...checklist, ...patchData };
    setChecklist({ ...checklist, isScoring });
    const response = await xeniaApi.patchChecklist({
      checklistId: checklist.id,
      data: patchData,
    });
    if (!response?.data) {
      setChecklist(previousChecklist);
    }
  };

  const handleGoBack = () => {
    navigateBackWithWorkspace();
  };

  const handleClickDownloadMobileApp = () => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.DOWNLOAD_MOBILE_APP_DIALOG,
        open: true,
      }),
    );
  };

  const handleOpenCalendlyModal = () => {
    dispatch(
      actions.setCalendlyInfo({
        url: TALK_TO_SALES_URL,
        open: true,
      }),
    );
  };

  const helpOptionProps = {
    sx: {
      '& svg': {
        fontSize: 20,
      },
    },
    iconAlign: 'start',
  };

  const helpOptions = [
    {
      id: 'helpCenter',
      label: 'Help Center',
      icon: <HelpOutlineRoundedIcon />,
      ...helpOptionProps,
    },
    {
      id: 'learnFeature',
      label: 'Learn More About This Feature',
      icon: <LightbulbIcon />,
      ...helpOptionProps,
    },
    {
      id: 'downloadApp',
      label: 'Download Mobile App',
      icon: <DownloadForOfflineOutlinedIcon />,
      ...helpOptionProps,
    },
    {
      id: 'bookTraining',
      label: 'Book a Training Call',
      icon: <SupportAgentIcon />,
      ...helpOptionProps,
    },
  ];

  const handleHelpOptionSelect = (option: any) => {
    switch (option.id) {
      case 'helpCenter':
        window.open('http://help.xenia.team/', '_blank');
        return;
      case 'learnFeature':
        window.open(
          'https://help.xenia.team/hc/en-us/categories/12299367588507-Operations-Templates-Checklists',
          '_blank',
        );
        return;
      case 'downloadApp':
        handleClickDownloadMobileApp();
        return;
      case 'bookTraining':
        handleOpenCalendlyModal();
        return;
      default:
        break;
    }
  };

  return (
    <>
      <TopAlert />
      <RowWrapper>
        <FirstRow>
          <GoBackWrapper onClick={handleGoBack}>
            <ArrowBackRoundedIcon />
            <BackText>Back</BackText>
          </GoBackWrapper>
          <ToggleBarCnt
            style={{
              ...(isAIBasedTemplate && {
                pointerEvents: 'none',
              }),
            }}
          >
            <ToggleButtonGroup
              color="primary"
              sx={{ color: '#424242' }}
              value={viewToggle}
              exclusive
              onChange={(e: any) => {
                handleViewToggleClick(e?.target?.value);
              }}
              aria-label="Settings Toggle"
            >
              <ToggleButton
                value="builder"
                className={classNames({ selected: viewToggle === 'builder' })}
              >
                <EditIconPencilBox
                  color={viewToggle === 'builder' ? '#6868FE' : '#757575'}
                  sx={{ fill: 'none', fontSize: 20, mr: 1 }}
                />
                Builder
              </ToggleButton>
              <ToggleButton
                value="settings"
                className={classNames({ selected: viewToggle === 'settings' })}
              >
                <GearIcon
                  color={viewToggle === 'settings' ? '#6868FE' : '#757575'}
                  sx={{ fill: 'none', fontSize: 20, mr: 1 }}
                />
                Settings
              </ToggleButton>
            </ToggleButtonGroup>
          </ToggleBarCnt>
          <FirstRowButtonsWrapper
            style={{
              ...(isAIBasedTemplate && {
                pointerEvents: 'none',
              }),
            }}
          >
            <Stack direction="row" spacing={1}>
              <Typography fontSize="14px" fontWeight={500}>
                Weighted Scoring
              </Typography>
              <VerifyFeatureAccess featureId={FEATURES.WEIGHTED_SCORING}>
                <CustomSwitch
                  checked={checklist?.isScoring}
                  onChange={(e) => toggleScoring(e.target.checked)}
                />
              </VerifyFeatureAccess>
            </Stack>
            <Divider orientation="vertical" variant="middle" flexItem />
            <ShareButton
              onClick={() => handleOpenSettings(4)}
              startIcon={<LinkIconRotated />}
              variant="outlined"
            >
              Share
            </ShareButton>
            <ActionDropdown
              buttonProps={{
                sx: { p: 0, mb: '1px', '& svg': { fontSize: '36px' } },
              }}
              options={helpOptions}
              handleOptionSelect={handleHelpOptionSelect}
            >
              <HelpInChatIcon />
            </ActionDropdown>
          </FirstRowButtonsWrapper>
        </FirstRow>
        {checklist?.name && (
          <SecondRow
            style={{
              ...(isAIBasedTemplate && {
                pointerEvents: 'none',
              }),
            }}
          >
            <LastModifiedTextWrapper>
              <Stack gap="6px" direction={'row'} alignItems={'center'}>
                <CloudIcon />
                <Typography
                  fontSize={'12px'}
                  fontWeight={500}
                  color="rgba(66, 66, 66, 1)"
                >
                  Last saved at {lastSavedAt}
                </Typography>
              </Stack>

              {/* {renderUpdateText()} */}
            </LastModifiedTextWrapper>
            <TemplateNameText>{checklist?.name}</TemplateNameText>
            <SecondRowButtons>
              <StyledButton
                variant="outlined"
                startIcon={<AddOutlinedIcon fontSize="small" />}
                onClick={() => setShowStepType(true)}
                bg="white"
              >
                Add Step
              </StyledButton>
              <StyledButton
                endIcon={
                  checklist?.isPublished ? (
                    <DisabledGlobeIcon fontSize="small" />
                  ) : (
                    <GlobeIcon fontSize="small" />
                  )
                }
                disabled={publishLoader}
                buttonLoader={publishLoader}
                variant="contained"
                onClick={async () => {
                  if (checklist?.isPublished) {
                    await togglePublishState();
                  } else {
                    await togglePublishState();
                    dispatch(
                      actions.setDialog({
                        dialogId: 'checklistPublish',
                        open: true,
                      }),
                    );
                  }
                }}
              >
                {checklist?.isPublished ? 'Unpublish' : 'Publish'}
              </StyledButton>
            </SecondRowButtons>
          </SecondRow>
        )}
      </RowWrapper>
      <PublishDialog dialogId="checklistPublish" checklist={checklist} />
    </>
  );
};

export default React.memo(Header, (prevProps, nextProps) => {
  const prev = {
    checklist: prevProps.checklist,
    viewToggle: prevProps.viewToggle,
    redirectTo: prevProps.redirectTo,
    lastSavedAt: prevProps.lastSavedAt,
    isAIBasedTemplate: prevProps.isAIBasedTemplate,
  };
  const next = {
    checklist: nextProps.checklist,
    viewToggle: nextProps.viewToggle,
    redirectTo: nextProps.redirectTo,
    lastSavedAt: nextProps.lastSavedAt,
    isAIBasedTemplate: nextProps.isAIBasedTemplate,
  };
  return isEqual(prev, next);
});
