import FilterTerm from 'components/SubmissionsFilters/ItemFilterRow/FilterTerm';
import { RowWrapper, DeleteIcon } from './styled';

interface FilterRowPropTypes {
  index: number;
  filter: {
    filterName: string;
    comparator: string;
    value: any[];
    conditional: string;
  };
  handleDeleteRow: (idx: number) => void;
  handleSetFilters: (
    idx: number,
    filterData: {
      filterName?: string;
      comparator?: string;
      value?: any;
      conditional: string;
    },
  ) => void;
  templateItems: any[];
  templateItemsHash: any;
}

const ItemFilterRow = ({
  index,
  filter,
  templateItemsHash,
  templateItems,
  handleDeleteRow,
  handleSetFilters,
}: FilterRowPropTypes) => {
  const handleChangeFilter = (
    filterType: 'item' | 'comparator' | 'value',
    value: string | string[],
    type?: any,
  ) => {
    const tempData = {
      filterName: filterType,
      comparator: 'is',
      value: [value],
      conditional: 'and',
    };
    handleSetFilters(index, tempData);
  };

  return (
    <RowWrapper>
      <FilterTerm
        value={templateItemsHash[filter?.value[0]]}
        onSelect={handleChangeFilter}
        templateItems={templateItems}
      />
      {/* <DeleteIcon
        onClick={() => handleDeleteRow(index)}
        sx={{ color: 'rgba(0, 0, 0, 0.54)' }}
      /> */}
    </RowWrapper>
  );
};

export default ItemFilterRow;
