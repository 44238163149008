import { useMutation } from 'react-query';
import { useEffect, useMemo, useState } from 'react';
import { getScheduledTasksGroupBy } from 'api/scheduledReportsApi';
import { CircularProgress, Stack } from '@mui/material';
import { ScheduleSummaryTable } from 'components/DataTables/Reports/ScheduleSummaryTable/scheduleSummaryList';
import SearchInput from './search/search';
import GroupByDropdown from './groupBy';
import { HeaderCnt } from './search/style';
import { WidgetLoaderCnt } from 'pages/Widgets/widgets.style';
import { getLocationsLevelsState } from 'store/selectors/locationsLevels';
import { useSelector } from 'react-redux';

const groupByOptions = [
  {
    title: 'Location',
    id: 'location',
  },
  {
    title: 'Schedule',
    id: 'schedule',
  },
];
function DetailedBreakdown(props) {
  const { filters } = props;
  const [searchTerm, setSearchTerm] = useState('');

  const [data, setData] = useState<any>({});
  const [groupBy, setGroupBy] = useState('location');
  const [groupByLevel, setGroupByLevel] = useState('');
  const locationsLevels = useSelector(getLocationsLevelsState);
  const getQuery = useMutation({
    mutationKey: 'getScheduledTasksGroupBy',
    mutationFn: getScheduledTasksGroupBy,
  });
  const groupByLevelOptions = useMemo(() => {
    if (locationsLevels.length) {
      return locationsLevels.map((level) => {
        return { title: level.title, id: level.id };
      });
    }
  }, [locationsLevels]);

  const loading = getQuery.isLoading;

  const getData = async () => {
    const requestPayload = {
      filters: {
        location: filters.locations,
        schedules: filters.schedules,
        projects: filters.projects,
        ...((filters?.fromDate || filters?.toDate) && {
          dateRange: [filters?.fromDate, filters?.toDate],
        }),
      },
    };

    getQuery.mutate(
      { data: requestPayload, groupBy: groupBy, groupByLevel: groupByLevel },
      {
        onSuccess: (data) => {
          setData({
            table: data?.charts || [],
            summary: {
              total: data?.summary?.completedOnTime || 0,
            },
          });
        },
      },
    );
  };

  useEffect(() => {
    if (
      (filters?.fromDate && filters?.toDate) ||
      (!filters?.fromDate && !filters?.toDate)
    ) {
      getData();
    }
  }, [filters, groupBy, groupByLevel]);

  return (
    <>
      <HeaderCnt>
        <div className={'lefCnt'}>
          <SearchInput onSearch={setSearchTerm} />
          <GroupByDropdown
            groupBy={groupBy}
            updateGroupBy={setGroupBy}
            groupByOptions={groupByOptions}
          />
          <GroupByDropdown
            groupBy={groupByLevel}
            updateGroupBy={setGroupByLevel}
            groupByOptions={groupByLevelOptions}
            title="Location Hierarchy"
          />
        </div>
        <div className={'rightCnt'}></div>
      </HeaderCnt>

      {loading ? (
        <WidgetLoaderCnt>
          <CircularProgress />
        </WidgetLoaderCnt>
      ) : (
        <ScheduleSummaryTable
          rowData={data?.table}
          groupBy={groupBy}
          searchTerm={searchTerm}
        />
      )}
    </>
  );
}

export default DetailedBreakdown;
