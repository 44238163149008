import { useSelector } from 'react-redux';
import { getAssetsState } from 'store/selectors/assets';
import { CheckListCnt } from './style';
import { useSchedule } from 'pages/Schedules/common/useSchedule';
import ChecklistIcon from 'components/ChecklistIcon';
import CTypography from 'components/CTypography';

const TemplateAttached = ({ data }) => {
  const { taskTemplate } = useSchedule(data);
  return (
    <CheckListCnt>
      <div className="templateAttached">
        <ChecklistIcon icon={taskTemplate.icon} style={{ minWidth: 24 }} />
        <CTypography>{taskTemplate.name}</CTypography>
      </div>
    </CheckListCnt>
  );
};

export default TemplateAttached;
