import CommentAction from 'components/Tasks/TasksList/CellRenderer/CommentsRenderer';
import CustomToolTip from 'components/Tooltip';
import { useTaskEdit } from 'controller/useTaskEdit';

function Comments({ task }) {
  const { handleEditDetailTask } = useTaskEdit();
  const unreadCommentsCount = task?.commentsCount?.unreadCount;
  const unreadCommentsCountValue =
    unreadCommentsCount > 9 ? '9+' : unreadCommentsCount;
  return (
    <CustomToolTip title={'Comments'}>
      <span
        onClick={(e) => {
          handleEditDetailTask(task?.id, 'comments');
        }}
      >
        {unreadCommentsCountValue ? (
          <CommentAction variant="unread" count={unreadCommentsCountValue} />
        ) : !task?.commentsCount?.messages?.length ? (
          <CommentAction variant="add" />
        ) : (
          <CommentAction
            variant="read"
            count={task?.commentsCount?.messages?.length || 0}
          />
        )}
      </span>
    </CustomToolTip>
  );
}

export default Comments;
