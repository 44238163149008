import { useEffect, useState } from 'react';
import { BuilderComponent } from '@builder.io/react';

const CustomRolesDetails = (props) => {
  const { setBuilderActive } = props;
  const [builderContentJson, setBuilderContentJson] = useState({});

  useEffect(() => {
    window?.builder
      .get('addon-custom-roles', {
        url: location.pathname,
      })
      .promise()
      .then((data) => {
        setBuilderContentJson(data);
        setBuilderActive(true);
      });
  }, []);

  return (
    <BuilderComponent model="addon-custom-roles" content={builderContentJson} />
  );
};

export default CustomRolesDetails;
