import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import xeniaApi from 'api/index';
import { Box } from '@mui/material';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import withTitleHoc from 'components/Routes/withTitleHoc';
import TaskListViewV2 from 'pages/task/ListViewV2';
import actions from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getUserHotelTz } from 'store/selectors/auth';
import { dateRangePickerSideMenuCommon } from 'utils/datePicker';
import useTaskFilters from 'utils/CustomHooks/useTaskFilters/useTaskFilters';
import { useTaskEdit } from 'controller/useTaskEdit';
import selectors from 'store/selectors';
import { useReactRouter } from 'controller/useReactRouter';
import { setDialog } from 'store/actions/dialogActions';
import { PAGE_TITLES } from 'utils/globalVariable';
import TaskGridListHeader from 'pages/task/TaskListView/TaskGridListHeader/TaskListHeader';

const TaskListViewCmp = withTitleHoc(TaskListViewV2);

export const filtersKeys = [
  'taskStatus',
  'priority',
  'taskLocations',
  'taskAssets',
  'taskCategories',
  'taskAssignees',
  'taskDueFilter',
  'taskType',
];

const FilteredTasks = () => {
  const dispatch = useDispatch();
  const { hasPermission, PERMISSIONS } = useCustomPermission();
  const [searchParams] = useSearchParams();
  const [hideMainBanner, setHideMainBanner] = useState<any>(true);
  const topAlertState = useSelector(selectors.getTopAlertState);
  const [currentTabInTasksView, setCurrentTabInTasksView] = useState('List');
  const [searchedText, setSearchedText] = useState('');

  const tz = useSelector(getUserHotelTz);

  const defaultLast7DaysFilter = useMemo(() => {
    if (tz) {
      const range = dateRangePickerSideMenuCommon(tz)[4];
      return {
        from: range?.range?.from,
        to: range?.range?.to,
        selectedDateOption: range?.label,
      };
    }
    return {};
  }, [tz]);

  const [forcedAdvanceFilters, setForcedAdvanceFilters] = useState<any>(null);
  const [dateFilter, setDateFilter] = useState<any>(defaultLast7DaysFilter);

  const {
    handleSetAdvanceFiltersCommon,
    fetchAdvanceFilters,
    syncAdvanceFilters,
  } = useTaskFilters();

  const disabledAdvanceFilters = useMemo(() => {
    if (forcedAdvanceFilters) {
      const disabledFilters = forcedAdvanceFilters.map((f) => f.filterName);
      return disabledFilters;
    }
    return null;
  }, [forcedAdvanceFilters, searchParams]);

  const formatDateFilter = (dateFilter) => {
    return {
      comparator: 'is',
      conditional: 'and',
      filterName: 'createdDate',
      value: {
        from: dateFilter.from,
        to: dateFilter.to,
      },
    };
  };

  const getTasksListing = async (currentTab = currentTabInTasksView) => {
    dispatch(actions.setLoader({ type: 'tasksList', value: true }));
    dispatch(actions.setLoader({ type: 'tasksCalendar', value: true }));

    // Getting Saved Configurations through API
    const dbFilters = await fetchAdvanceFilters();
    syncAdvanceFilters(dbFilters); // Dispatch to save in redux store

    // Get Advance Filters
    const advanceFilters = dbFilters?.tabs?.[currentTab]?.filters;

    const dateFilter =
      dbFilters?.tabs?.[currentTab]?.dateRange ?? defaultLast7DaysFilter;

    setDateFilter(dateFilter);
    if (searchParams.get('filters')) {
      const filters = formatQueryFilters(searchParams.get('filters'));
      if (filters) {
        setForcedAdvanceFilters(filters.filters);
        handleSetAdvanceFiltersCommon(
          advanceFilters,
          [...filters.filters, formatDateFilter(dateFilter)],
          {
            skipFilterSave: true,
          },
          currentTabInTasksView,
        );
      }
    } else {
      setForcedAdvanceFilters(null);
      handleSetAdvanceFiltersCommon(
        advanceFilters,
        [formatDateFilter(dateFilter)],
        { skipFilterSave: true },
        currentTabInTasksView,
      );
    }
  };

  useEffect(() => {
    if (!hasPermission(PERMISSIONS.CAN_VIEW_TASKS)) {
      setTimeout(() => {
        navigateWithWorkspaceUrl('/dashboard');
      }, 1000);
    }

    // Gettings List of Tasks
    getTasksListing();
  }, [searchParams]);

  const { handleEditDetailTask } = useTaskEdit();

  const getTaskDetailIdFromNotification: any = useSelector(
    selectors.getTaskDetailIdFromNotification,
  );

  const loaders = useSelector(selectors.getLoaders);

  const getSearchText: any = useSelector(selectors.getSearchTextFromStore);
  const taskListFilters: any = useSelector(selectors.getTaskListFilters);

  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  const { handleGetQueryParam, handleGetPathName } = useReactRouter();

  const formatQueryFilters = (advanceFilters) => {
    let allFilters: any[] = [];
    if (advanceFilters) {
      const filtersSeparated = advanceFilters.split('.') as string[];
      allFilters = filtersSeparated.map((filterString) => {
        const filter = filterString.split('|');
        const filterName = filter[0];
        const filterComparator = filter[1];
        const filterValue = filter[2];
        const filterObj = {
          filterName,
          comparator: filterComparator,
          value: filterValue.split(','),
          conditional: 'and',
        };
        return filterObj;
      });
    }
    if (allFilters.length > 0)
      return {
        condition: 'AND',
        filters: allFilters,
      };
    return undefined;
  };

  useEffect(() => {
    const params = handleGetQueryParam();
    const channelId = params.get('channelId');
    const isCommentChannel =
      channelId && channelId.startsWith('task_comments_');
    if (channelId && !isCommentChannel) {
      navigateWithWorkspaceUrl(`/messages/${channelId}`);
      return;
    }
    const taskId =
      (isCommentChannel && channelId.substr(14)) || params.get('taskId');
    if (taskId) {
      const isCommentParam =
        params.get('comments') && params.get('comments') === '1';
      const section =
        isCommentChannel || isCommentParam ? 'comments' : 'detail';
      handleEditDetailTask(taskId, section);
      navigateWithWorkspaceUrl('/tasks/list');
    }
  }, [handleGetQueryParam()]);

  useEffect(() => {
    if (getTaskDetailIdFromNotification !== '') {
      handleEditDetailTask(getTaskDetailIdFromNotification, 'detail');
    }
  }, [getTaskDetailIdFromNotification]);

  const fetchTask = async (id, isRecur?: any) => {
    if (!isRecur) {
      const taskData = await xeniaApi.taskDetailSingleListing({
        id,
      });
      dispatch(
        actions.editSingleTask({
          taskId: id,
          task: { ...taskData?.data },
        }),
      );
    } else {
      closeTaskDetail();
    }
  };

  const closeTaskDetail = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: 'taskDetail',
      }),
    );
  };

  const childTitle = useMemo(() => {
    const filters = searchParams?.get('filters') || '';

    if (filters.includes('taskTypes|is|corrective')) {
      return PAGE_TITLES.corrective;
    }

    if (filters.includes('taskTypes|is|requested')) {
      return PAGE_TITLES.requested;
    }
  }, [searchParams]);

  return hasPermission(PERMISSIONS.CAN_VIEW_TASKS) ? (
    <>
      <Box mb={2} />

      <TaskGridListHeader
        forcedAdvanceFilters={forcedAdvanceFilters}
        disabledAdvanceFilters={disabledAdvanceFilters}
        isMainView={!searchParams.get('filters')}
        dateFilter={dateFilter}
        setDateFilter={setDateFilter}
        formatDateFilter={formatDateFilter}
        currentTabInTasksView={currentTabInTasksView}
        setSearchedText={setSearchedText}
        showViewTypes={false}
        showVisibilityToggle={false}
      />

      <TaskListViewCmp
        hideMainBanner={hideMainBanner}
        searchedText={searchedText}
        title={PAGE_TITLES.task}
        childTitle={childTitle}
      />
    </>
  ) : null;
};

export default FilteredTasks;
