import styled from '@mui/system/styled';

export const DueDateCnt = styled('div')({
  '.dueOrcompleted': {
    color: '#000000',
    fontSize: 13,
    fontWeight: 600,
    letterSpacing: 0.4,
    display: 'flex',
    gap: 6,
    alignItems: 'center',
    '& svg': {
      fontSize: 16,
      '& path': {
        stroke: '#616161',
      },
    },
  },
  '.overdue': {
    color: 'rgba(211, 47, 47, 1)',
    fontSize: 13,
    fontWeight: 600,
    letterSpacing: 0.17,
    display: 'flex',
    gap: 6,
    alignItems: 'center',
  },
});
