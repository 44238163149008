import { Stack, Typography } from '@mui/material';
import { AIFooterStopGeneratingBox } from './style';
import CustomButton from 'components/Button/CustomButton';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import { useDispatch } from 'react-redux';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import useAITemplate from 'utils/CustomHooks/useAITemplate';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import aiChecklistGif from 'public/assets/gif/aichecklist.gif';

function StopAIGeneration(props) {
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const {
    autoTemplateInterval,
    setChecklist,
    setChecklistItems,
    getOrCreateAndSetChecklist,
    aiController,
  } = props;
  const dispatch = useDispatch();
  const { getPayloadForAI } = useAITemplate();

  const stopAITemplateGeneration = async () => {
    aiController?.abort();

    if (autoTemplateInterval) {
      clearInterval(autoTemplateInterval);
    }

    const metadata = await getPayloadForAI();

    // Show Prefilled Modal Again
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS?.CREATE_TEMPLATE_WITH_AI,
        data: {
          metadata,
          onSubmitHandler: () => {
            setChecklist(null);
            setChecklistItems(null);
            getOrCreateAndSetChecklist();
          },
          onCloseHandler: () => {
            navigateWithWorkspaceUrl('/checklist');
          },
        },
      }),
    );
  };

  return (
    <AIFooterStopGeneratingBox direction="row" alignItems="center">
      <Stack
        direction="row"
        justifyContent="space-between"
        width="100%"
        style={{ padding: 15 }}
      >
        <Stack
          direction="row"
          gap="5px"
          alignItems="center"
          sx={{ '& svg': { height: '40px', width: '40px' } }}
        >
          <img src={aiChecklistGif} height="40px" alt="AI Checklist" />
          <Stack direction="column" gap="2px">
            <Typography className="title">Generating template...</Typography>
            <Typography className="statement">
              Operation Template is being generating, this may take a moment.
            </Typography>
          </Stack>
        </Stack>

        <CustomButton
          variant={'outlined'}
          color={'primary'}
          startIcon={<StopCircleIcon />}
          onClick={stopAITemplateGeneration}
        >
          Stop Generating
        </CustomButton>
      </Stack>
    </AIFooterStopGeneratingBox>
  );
}

export default StopAIGeneration;
