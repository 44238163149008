// React
import { useMemo, useState } from 'react';

// Redux
import { useSelector } from 'react-redux';

// Custom components
import CustomListItem from 'components/List/ListItem/ListItem';
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';

// Icons
import DoneIcon from '@mui/icons-material/Done';

import {
  GroupByBtn,
  IconCnt,
  SelectViewBtnCnt,
  StyledList,
} from 'pages/task/TaskListView/SelectTaskViewDropdown/selectTaskViewDropdown.style';
import { isEmpty } from 'lodash';
import ListIcon from 'components/Icons/listIcon';
import TablesIcon from 'components/Icons/tablesIcon';
import CalendarIconRounded from 'components/Icons/calendarIconRounded';
import { StyledEndIconCnt } from 'components/Dropdown/AssigneeDropdown/assigneeDropdownSelect.style';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import useTasksConfig from 'utils/CustomHooks/useTasksTabs';
import selectors from 'store/selectors';
import { TASKS_VIEW_TYPES } from 'pages/constants';
import KanbanIcon from 'components/Icons/kanbanIcon';

const taskViewOptions = [
  {
    title: 'List',
    id: 'list',
    icon: <ListIcon className={'optionsIcon'} />,
  },
  {
    title: 'Table',
    id: 'table',
    icon: <TablesIcon filled={false} className={'optionsIcon'} />,
  },
  {
    title: 'Calendar',
    id: 'calendar',
    icon: <CalendarIconRounded className={'calendarIcon'} />,
  },
  {
    title: 'Kanban',
    id: 'kanban',
    icon: <KanbanIcon className={'kanbanIcon'} />,
  },
];
function SelectTaskViewDropdown(props) {
  const { listProps = {}, currentTabInTasksView } = props;
  const { updateTabConfigState, updateTasksConfig } = useTasksConfig();
  const allTabsfilterData: any = useSelector(selectors.getTaskListFilters);
  const [anchorEl, setAnchorEl] = useState(null);

  const onViewSelect = (colId) => {
    const updatedData = updateTabConfigState(
      allTabsfilterData,
      currentTabInTasksView,
      {
        viewType: colId,
      },
    );

    updateTasksConfig(updatedData);
  };
  const handleDropdownOpen = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleDropdownClose = (event) => {
    setAnchorEl(null);
  };

  const currentView = useMemo(() => {
    if (
      currentTabInTasksView?.toLocaleLowerCase() ===
      TASKS_VIEW_TYPES.KANBAN?.toLocaleLowerCase()
    )
      return TASKS_VIEW_TYPES.KANBAN;

    return (
      allTabsfilterData?.tabs?.[currentTabInTasksView]?.viewType ||
      TASKS_VIEW_TYPES?.TABLE
    );
  }, [allTabsfilterData, currentTabInTasksView]);

  const ListItems = () => {
    return (
      <>
        <StyledList
          sx={{ width: '100%' }}
          dense={true}
          disablePadding
          {...listProps}
        >
          {taskViewOptions.map((c, i) => {
            const isSelected = c.id == currentView;
            return (
              <CustomListItem
                key={c.title}
                id={c.title}
                selected={isSelected}
                checkbox={false}
                onClick={() => onViewSelect(c.id)}
                endIcon={isSelected && <DoneIcon />}
                startIcon={<IconCnt>{c.icon}</IconCnt>}
              >
                {c.title}
              </CustomListItem>
            );
          })}
        </StyledList>
      </>
    );
  };
  const selectedViewOption = taskViewOptions.find((t) => t.id == currentView);
  return (
    <>
      <SelectViewBtnCnt>
        <GroupByBtn
          variant="outlined"
          buttonType="grayWhite"
          onClick={handleDropdownOpen}
          startIcon={selectedViewOption?.icon}
          selected={!isEmpty(selectedViewOption)}
          endIcon={
            <StyledEndIconCnt>
              {/*commented for later use*/}
              {/*{selected && (*/}
              {/*  <IconButton onClick={handleClearOptions}>*/}
              {/*    <CloseIcon />*/}
              {/*  </IconButton>*/}
              {/*)}*/}
              <KeyboardArrowDownIcon style={{ fontSize: 20 }} />
            </StyledEndIconCnt>
          }
        >
          {selectedViewOption ? selectedViewOption?.title : ''}
        </GroupByBtn>
      </SelectViewBtnCnt>
      <CustomDropdown
        closeAction={handleDropdownClose}
        popperProps={{
          content: <ListItems />,
          id: 'columnSelectionDropdown',
          style: { width: 140 },
          open: !!anchorEl,
          placement: 'bottom-start',
          anchorEl,
        }}
      ></CustomDropdown>
    </>
  );
}

export default SelectTaskViewDropdown;
