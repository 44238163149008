// Redux
import store from 'store';

// Custom components
import {
  Title,
  Location,
  Schedule,
  CreatedBy,
  Assignee,
  NextDue,
  TemplateAttached,
} from './renderers/renderers';

// Constants
import { statusDropdownData } from 'helper/task/dropdownData';
import { DEFAULT_TIMEZONE } from 'utils/globalVariable';

// Utilities
import moment from 'moment-timezone';

const getTimeZone = () => {
  return (
    store?.getState()?.workspace?.activeWorkspace?.Hotel?.timezone ||
    DEFAULT_TIMEZONE
  );
};

export const rowHeight = 50;
export const defaultColDef = {
  resizable: true,
  hide: false,
  pdfExportOptions: { styles: { fontSize: 10 } },
  menuTabs: ['generalMenuTab'],
  suppressMenu: true,
  lockVisible: true,
};

export const getRowStyles = (params) => {
  const rowGroupId =
    globalThis?.taskSummaryListGrid?.columnApi?.getRowGroupColumns()?.[0]
      ?.colId;
  if (params?.node?.group && rowGroupId == 'status') {
    const selectedStatus = statusDropdownData.find(
      (s) => s.value === params?.node?.key,
    );
    return {
      background: selectedStatus?.backgroundColor,
      color: selectedStatus?.color,
      boxShadow: 'none',
      borderTop: '25px solid #fff',
    };
  }
  if (params?.node?.group) {
    return {
      background: '#ECEEF6',
      borderTop: '25px solid #fff',
    };
  }
};

export const cellRenderer = {
  title: (params) => {
    if (!params?.data) return;
    const { title } = params.data;
    return <Title title={title} />;
  },
  location: (params) => {
    if (!params?.data) return;
    const { Location: _location } = params.data;
    return _location ? <Location location={_location} /> : '-';
  },
  template: (row) => {
    if (!row?.data) return;
    const { ChecklistId } = row.data;
    return ChecklistId ? <TemplateAttached data={row.data} /> : '-';
  },
  schedule: (params) => {
    if (!params?.data) return;
    const { recurringByEvery } = params.data;
    return <Schedule recurringByEvery={recurringByEvery} />;
  },
  createdBy: (row) => {
    if (!row?.data) return;
    const { Creator } = row.data;
    return <CreatedBy creator={Creator} />;
  },
  assignee: (row) => {
    if (!row?.data) return;
    const { TaskAssignees } = row.data;
    return <Assignee assignees={TaskAssignees} />;
  },
  nextDue: (row) => {
    if (!row?.data) return;
    const { NextDue: _NextDue } = row.data;
    if (!_NextDue) return '-';
    const due = moment
      .tz(_NextDue.dueDate, getTimeZone())
      .format('MMM DD YYYY hh:mm a');
    return <NextDue nextDue={due} />;
  },
};

//List View value getters
//Returned value will be the values to be shown in the cell
export const valueGetters = {
  createdBy: (params) => {
    if (!params?.data) return;
    const { Creator } = params.data;
    return Creator?.fullName;
  },
  assignee: (params) => {
    if (!params?.data) return;
    const { Assigned, Team } = params.data;
    if (!params?.node?.group && !Assigned && !Team) {
      return 'Unassigned';
    }
    const AssigneeName =
      Assigned?.firstName && `${Assigned?.firstName} ${Assigned?.lastName}`;
    const TeamName = Team?.name;
    return AssigneeName || TeamName;
  },
  nextDue: (params) => {
    const tz = getTimeZone();
    if (!params.data) return null;
    const { NextDue } = params.data;
    return NextDue
      ? moment.tz(NextDue.dueDate, tz).format('MMM DD YYYY hh:mm a')
      : '';
  },
};

export const columns: any = (props) => {
  const { columnConfig } = props;
  const tableCells = [
    {
      field: 'title',
      headerName: 'Title',
      colId: 'title',
      sortable: true,
      cellRenderer: cellRenderer.title,
    },
    {
      field: 'Location',
      headerName: 'Location',
      colId: 'location',
      sortable: true,
      cellRenderer: cellRenderer.location,
      resizable: true,
    },
    {
      field: 'assosiated_template',
      headerName: 'Associated Template',
      colId: 'assosiated_template',
      sortable: true,
      cellRenderer: cellRenderer.template,
      resizable: true,
    },
    {
      field: 'recurringByEvery',
      headerName: 'Schedule',
      colId: 'schedule',
      sortable: true,
      cellRenderer: cellRenderer.schedule,
      resizable: true,
    },
    {
      field: 'Creator',
      headerName: 'Created by',
      colId: 'createdBy',
      comparator: comparator.assignee,
      valueGetter: valueGetters.createdBy,
      sortable: true,
      cellRenderer: cellRenderer.createdBy,
    },
    {
      field: 'assignedTo',
      headerName: 'Assigned To',
      colId: 'assigned_to',
      comparator: comparator.assignee,
      valueGetter: valueGetters.assignee,
      sortable: true,
      cellRenderer: cellRenderer.assignee,
    },
    {
      field: 'NextDue',
      headerName: 'Next Due',
      colId: 'nextDue',
      sortable: true,
      comparator: comparator.date,
      cellRenderer: cellRenderer.nextDue,
      valueGetter: valueGetters.nextDue,
    },
  ];

  return tableCells.filter((cell) => columnConfig[cell.field]);
};

const comparator = {
  date: (date1, date2) => {
    const date1Number = date1 ? new Date(date1).getTime() : null;
    const date2Number = date2 ? new Date(date2).getTime() : null;

    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }

    return date1Number - date2Number;
  },
  assignee: (a1, a2) => {
    const assignee1 = a1 || '-';
    const assignee2 = a2 || '-';
    return assignee1?.toLowerCase() == assignee2?.toLowerCase()
      ? 0
      : assignee1?.toLowerCase() > assignee2?.toLowerCase()
      ? 1
      : -1;
  },
};
