import { ItemCnt, MainCnt } from './style';
import { cloneDeep, isEmpty, isObject } from 'lodash';
import { Box, Divider, Stack, Tooltip, Typography } from '@mui/material';
import useTasksConfig from 'utils/CustomHooks/useTasksTabs';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import HomeIcon from 'components/Icons/homeIcon';
import CalendarIconRounded from 'components/Icons/calendarIconRounded';
import ListIcon from 'components/Icons/listIcon';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Fragment, useMemo, useState } from 'react';
import EditIcon from 'components/Icons/editIcon';
import DeleteIcon from 'components/Icons/deleteIcon';
import ActionDropdown from 'components/Dropdown/ActionDropdown';
import { colors } from 'components/theme/constants';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import { showMessageNotification } from 'utils/globalFunction';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CustomDropdown from 'components/Dropdown/CustomDropdown';
import MoreItemsContent from './moreItemsContent';
import { VerifyFeatureAccess } from 'components/UpSellModal/VerifyFeatureAccess';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { TASKS_VIEW_TYPES } from 'pages/constants';
import TablesIcon from 'components/Icons/tablesIcon';
import KanbanIcon from 'components/Icons/kanbanIcon';

const actionDropdownOptions = [
  {
    label: 'Rename',
    id: 'rename',
    icon: <EditIcon />,
    iconAlign: 'start',
  },
  {
    label: 'Delete this View',
    id: 'delete',
    icon: <DeleteIcon style={{ color: colors.red, fill: 'none' }} />,
    sx: {
      color: `${colors.red}`,
    },
    iconAlign: 'start',
  },
];

function TasksViewTabsList(props) {
  const { setCurrentTabInTasksView, currentTabInTasksView, getTasksListing } =
    props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { addNewUntitled, updateCurrentTabLocally, updateTasksConfig } =
    useTasksConfig();
  const allTabsfilterData: any = useSelector(selectors.getTaskListFilters);
  const { tabs } = allTabsfilterData || {};

  const [editing, setEditing] = useState('');
  const [newLabel, setNewLabel] = useState('');

  const tabClickHandler = (tab) => {
    if (tab === currentTabInTasksView) return;

    // Fetch new tasks on tab change
    getTasksListing(tab);
    setCurrentTabInTasksView(tab);
    updateCurrentTabLocally(tab);
  };

  const deleteViewHandler = (tab) => {
    const clonedData = cloneDeep(allTabsfilterData);
    delete clonedData?.tabs?.[tab];

    updateTasksConfig(clonedData);
  };

  const editViewHandler = (tab) => {
    setEditing(tab);
    setNewLabel(tab);
  };

  const onBlurHandler = () => {
    if (!editing) return;

    if (!newLabel) {
      showMessageNotification('View title cannot be empty', 'error');
      return;
    }

    let existingTabs = Object.keys(tabs || {}) ?? [];

    // push existing
    existingTabs.push('List');

    // remove own
    existingTabs = existingTabs?.filter((item) => item !== editing);

    const labelExists = existingTabs?.some(
      (tab) => tab?.toLowerCase() === newLabel?.toLowerCase(),
    );

    if (labelExists) {
      showMessageNotification('This view title already exists', 'error');
      return;
    }

    if (newLabel !== editing) {
      const clonedData = cloneDeep(allTabsfilterData);
      if (editing) {
        const newTabs = {};
        Object.keys(clonedData?.tabs || {}).forEach((key) => {
          if (key === editing) {
            newTabs[newLabel] = clonedData?.tabs?.[key];
          } else {
            newTabs[key] = clonedData?.tabs?.[key];
          }
        });

        clonedData.tabs = newTabs;
        updateTasksConfig(clonedData);
      }
    }

    setEditing('');
    setNewLabel('');
    return;
  };

  const editingChangeHandler = (e) => {
    if (e.key === 'Enter') {
      onBlurHandler();
      return;
    }
    setNewLabel(e?.target?.value);
  };

  const handleOptionSelect = (option, tab) => {
    switch (option.id) {
      case 'rename':
        {
          editViewHandler(tab);
        }
        break;

      case 'delete':
        {
          deleteViewHandler(tab);
        }
        break;
    }
  };

  const customTabs: string[] = useMemo(() => {
    if (isObject(tabs) && !isEmpty(tabs)) {
      return Object.keys(tabs)?.filter?.((tab) => !['List'].includes(tab));
    }

    return [];
  }, [tabs]);

  const onDropdownClose = () => {
    onBlurHandler();
    setAnchorEl(null);
  };

  const handleDropDownOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return (
    <MainCnt>
      <Stack direction="row" alignItems="center" gap="10px">
        <ItemCnt
          onClick={() => tabClickHandler('List')}
          style={{
            paddingRight: 3,
            ...(currentTabInTasksView === 'List' && {
              borderBottom: '2px solid #6868FE',
            }),
          }}
        >
          <HomeIcon
            style={{ fontSize: 18 }}
            sx={{
              '& path': {
                fill: 'none',
                ...(currentTabInTasksView === 'List' && {
                  stroke: 'black',
                  strokeOpacity: 1,
                }),
              },
            }}
          />

          <Typography
            className="label"
            style={{
              ...(currentTabInTasksView === 'List' && {
                fontWeight: 700,
                textDecoration: 'none',
              }),
            }}
          >
            Home
          </Typography>
        </ItemCnt>

        {customTabs?.length > 0 && (
          <Divider
            orientation="vertical"
            flexItem
            style={{ margin: '10px 2px' }}
          />
        )}

        {customTabs?.slice(0, 5)?.map((tab, index) => (
          <Fragment key={tab}>
            {editing === tab ? (
              <CustomInput
                label=""
                style={{
                  width: 130,
                  marginBottom: 5,
                }}
                variant="outlined"
                muiLabel={false}
                fieldProps={{
                  placeholder: 'Enter view name',
                  value: newLabel,
                  autoFocus: true,
                  onChange: editingChangeHandler,
                  onKeyPress: editingChangeHandler,
                  style: { height: 33 },
                  onBlur: onBlurHandler,
                }}
              />
            ) : (
              <ItemCnt
                onClick={() => tabClickHandler(tab)}
                style={{
                  ...(currentTabInTasksView === tab && {
                    borderBottom: '2px solid #6868FE',
                    marginBottom: -5,
                  }),
                }}
              >
                {tabs?.[tab]?.viewType === TASKS_VIEW_TYPES.LIST && (
                  <ListIcon
                    style={{ fontSize: 18 }}
                    sx={{
                      '& path': {
                        fill: '#616161ab',
                        ...(currentTabInTasksView === tab && {
                          fill: '#000000ab',
                        }),
                      },
                    }}
                  />
                )}

                {tabs?.[tab]?.viewType === TASKS_VIEW_TYPES.TABLE && (
                  <Box
                    sx={{
                      '& svg': {
                        height: '18px',
                        width: '100%',
                        marginTop: '-2px',
                      },
                    }}
                  >
                    <TablesIcon />
                  </Box>
                )}

                {tabs?.[tab]?.viewType === TASKS_VIEW_TYPES.CALENDAR && (
                  <CalendarIconRounded
                    style={{ fill: 'none', fontSize: 18 }}
                    sx={{
                      '& path': {
                        fill: 'none',
                        stroke: 'rgba(117, 117, 117, 1)',
                        ...(currentTabInTasksView === 'Calendar' && {
                          stroke: 'black',
                          strokeOpacity: 1,
                        }),
                      },
                    }}
                  />
                )}
                {tabs?.[tab]?.viewType === TASKS_VIEW_TYPES.KANBAN && (
                  <KanbanIcon
                    style={{ fill: 'none', fontSize: 18 }}
                    sx={{
                      '& path': {
                        fill: 'none',
                        stroke: 'rgba(117, 117, 117, 1)',
                        ...(currentTabInTasksView === tab && {
                          stroke: 'black',
                          strokeOpacity: 1,
                        }),
                      },
                    }}
                  />
                )}

                {!tabs?.[tab]?.viewType && (
                  <Box
                    sx={{
                      '& svg': {
                        height: '18px',
                        width: '100%',
                        marginTop: '-2px',
                      },
                    }}
                  >
                    <TablesIcon />
                  </Box>
                )}

                {tab?.length > 14 ? (
                  <Tooltip title={tab} arrow>
                    <Typography
                      className="label"
                      style={{
                        ...(currentTabInTasksView === tab && {
                          fontWeight: 600,
                          textDecoration: 'none',
                        }),
                      }}
                    >
                      {tab?.slice(0, 14) + '...'}
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography
                    className="label"
                    style={{
                      ...(currentTabInTasksView === tab && {
                        fontWeight: 600,
                        textDecoration: 'none',
                      }),
                    }}
                  >
                    {tab}
                  </Typography>
                )}

                <div className="actionsCnt">
                  <ActionDropdown
                    options={actionDropdownOptions}
                    handleOptionSelect={(option) =>
                      handleOptionSelect(option, tab)
                    }
                    iconButton={false}
                    buttonProps={{
                      style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        padding: 0,
                        minWidth: 'unset',
                      },
                      sx: {
                        '& svg': {
                          color: '#424242b5',
                        },
                      },
                    }}
                    popperProps={{
                      placement: 'bottom-start',
                      sx: {
                        zIndex: 1301,
                      },
                    }}
                    paperProps={{
                      style: {
                        minWidth: 120,
                      },
                    }}
                  >
                    <MoreHorizIcon />
                  </ActionDropdown>
                </div>
              </ItemCnt>
            )}
            {index < customTabs?.length - 1 && (
              <Divider
                orientation="vertical"
                flexItem
                style={{ margin: '10px 2px' }}
              />
            )}
          </Fragment>
        ))}

        {customTabs?.length > 5 && (
          <Fragment>
            <CustomDropdown
              closeAction={onDropdownClose}
              buttonRenderer={
                <ItemCnt onClick={handleDropDownOpen}>
                  <Typography
                    className="label"
                    style={{ textDecoration: 'none' }}
                  >
                    More
                  </Typography>
                  <ArrowDownIcon style={{ color: 'rgb(0 0 0 / 43%)' }} />
                </ItemCnt>
              }
              popperProps={{
                content: (
                  <MoreItemsContent
                    tabs={customTabs?.slice(5) ?? []}
                    currentTab={currentTabInTasksView}
                    editingTab={editing}
                    newLabel={newLabel}
                    onTabClick={tabClickHandler}
                    onEditChange={editingChangeHandler}
                    onKeyPress={editingChangeHandler}
                    onBlur={onBlurHandler}
                    onOptionSelect={handleOptionSelect}
                    actionDropdownOptions={actionDropdownOptions}
                    allTabs={tabs}
                  />
                ),
                placement: 'bottom-start',
                open: Boolean(anchorEl),
                modifiers: {
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: true,
                    boundariesElement: 'viewport',
                  },
                },
                anchorEl: anchorEl,
                style: {
                  zIndex: 1301,
                  width: 200,
                },
              }}
            />
          </Fragment>
        )}

        <Divider
          orientation="vertical"
          flexItem
          style={{ margin: '10px 2px' }}
        />

        <div
          onClick={addNewUntitled}
          style={{
            cursor: 'pointer',
            fontSize: 25,
            fontWeight: 400,
            color: '#21212199',
          }}
        >
          +
        </div>
      </Stack>
    </MainCnt>
  );
}

export default TasksViewTabsList;
