import { Box } from '@mui/material';
import { KanbanBoardCnt } from './board.styles';
import ColumnCard from './column/columnCard';
import { boardColumns, KanbanData, transformTasksToKanban } from './constants';
import { DragDropContext } from 'react-beautiful-dnd';
import selectors from 'store/selectors';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { DroppableCmp } from './DNDComponent/Dropable';
import { DraggableCmp } from './DNDComponent/Dragable';
import useTasksConfig from 'utils/CustomHooks/useTasksTabs';
import { useTaskEdit } from 'controller/useTaskEdit';

const initialData = {
  columns: {},
  tasksById: {},
  columnOrder: [],
};

export interface Task {
  id: string;
  title: string;
  status: string;
  description: string;
}
export interface Column {
  id: string;
  title: string;
  taskIds: string[];
}
function KanbanBoard({ currentTabInTasksView }) {
  const taskList: any = useSelector(selectors.getTaskList);
  const { updateTabConfigState, updateTasksConfig } = useTasksConfig();
  const allTabsConfig: any = useSelector(selectors.getTaskListFilters);
  const { handleTaskStatusChange } = useTaskEdit();
  const defaultCollapsedColumns = ['Open', 'In Progress'];
  const [collapsedColumns, setCollapsedColumns] = useState([]);
  const [data, setData] = useState<KanbanData>(initialData);
  const handleColumnUpdate = (updatedColumnOrder) => {
    const updatedData = updateTabConfigState(
      allTabsConfig,
      currentTabInTasksView,
      {
        kanbanCofig: { columnOrder: updatedColumnOrder },
      },
    );
    updateTasksConfig(updatedData);
  };

  useEffect(() => {
    if (taskList?.rows) {
      const transformedData = transformTasksToKanban(
        taskList?.rows,
        boardColumns,
        allTabsConfig?.tabs?.[currentTabInTasksView]?.kanbanCofig || null,
      );
      setData(transformedData);
    }
  }, [taskList?.rows]);

  const onDragEnd = (result) => {
    const { destination, source, draggableId, type } = result;
    // If there is no destination, do nothing
    if (!destination) {
      return;
    }
    // If the item was dropped in the same position, do nothing
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (type === 'COLUMN') {
      const newColumnOrder = Array.from(data.columnOrder);
      newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, draggableId);

      setData({
        ...data,
        columnOrder: newColumnOrder,
      });
      // update column config here
      handleColumnUpdate(newColumnOrder);

      return;
    }

    // Reorder within the same column
    const startColumn = data.columns[source.droppableId];
    const finishColumn = data.columns[destination.droppableId];

    if (startColumn === finishColumn) {
      return;
      const newTaskIds = Array.from(startColumn.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...startColumn,
        taskIds: newTaskIds,
      };

      setData({
        ...data,
        columns: {
          ...data.columns,
          [newColumn.id]: newColumn,
        },
      });
    }

    // Moving task from one column to another
    const startTaskIds = Array.from(startColumn.taskIds);
    startTaskIds.splice(source.index, 1);
    const newStartColumn = {
      ...startColumn,
      taskIds: startTaskIds,
    };

    const finishTaskIds = Array.from(finishColumn.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);
    const newFinishColumn = {
      ...finishColumn,
      taskIds: finishTaskIds,
    };
    setData({
      ...data,
      columns: {
        ...data.columns,
        [newStartColumn.id]: newStartColumn,
        [newFinishColumn.id]: newFinishColumn,
      },
    });
    handleTaskStatusUpdate(draggableId, destination);
  };

  const handleTaskStatusUpdate = (taskId: string, selected) => {
    const task = data.tasksById[taskId];
    const taskStatus = selected.droppableId;
    const updatedTask = {
      ...task,
      taskStatus: taskStatus,
    };
    handleTaskStatusChange(task, updatedTask);
  };

  return (
    <>
      {data && data.columnOrder && (
        <Box
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            padding: '0 18px',
            overflow: 'auto',
          }}
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <DroppableCmp
              dndProps={{
                droppableId: 'all-columns',
                direction: 'horizontal',
                type: 'COLUMN',
              }}
            >
              <KanbanBoardCnt
                sx={{
                  display: 'flex',
                  background: '#fefefe',
                  height: '100%',
                }}
              >
                {data.columnOrder.map((columnId, index) => {
                  const column = data.columns[columnId];
                  return (
                    <DraggableCmp
                      dndPropss={{
                        draggableId: columnId,
                        index: index,
                      }}
                    >
                      <ColumnCard
                        key={column.id}
                        column={column}
                        index={index}
                        data={data}
                        collapsedColumns={collapsedColumns}
                        setCollapsedColumns={setCollapsedColumns}
                      />
                    </DraggableCmp>
                  );
                })}
              </KanbanBoardCnt>
            </DroppableCmp>
          </DragDropContext>
        </Box>
      )}
    </>
  );
}

export default KanbanBoard;
