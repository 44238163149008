import { createSvgIcon } from '@mui/material';

const ThermometerIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        opacity="0.12"
        d="M12.25 5.25004C12.25 3.63921 10.9442 2.33337 9.33337 2.33337C7.72254 2.33337 6.41671 3.63921 6.41671 5.25004V16.0508C5.00972 16.9926 4.08337 18.5965 4.08337 20.4167C4.08337 23.3162 6.43388 25.6667 9.33337 25.6667C12.2329 25.6667 14.5834 23.3162 14.5834 20.4167C14.5834 18.5965 13.657 16.9926 12.25 16.0508V5.25004Z"
        fill="#EF5350"
      />
      <path
        d="M24.5 3.50004L17.5 3.50004M24.5 8.16671L17.5 8.16671M24.5 12.8334L17.5 12.8334M6.41671 16.0508V5.25004C6.41671 3.63921 7.72254 2.33337 9.33337 2.33337C10.9442 2.33337 12.25 3.63921 12.25 5.25004V16.0508C13.657 16.9926 14.5834 18.5965 14.5834 20.4167C14.5834 23.3162 12.2329 25.6667 9.33337 25.6667C6.43388 25.6667 4.08337 23.3162 4.08337 20.4167C4.08337 18.5965 5.00972 16.9926 6.41671 16.0508ZM10.5 20.4167C10.5 21.061 9.97771 21.5834 9.33337 21.5834C8.68904 21.5834 8.16671 21.061 8.16671 20.4167C8.16671 19.7724 8.68904 19.25 9.33337 19.25C9.97771 19.25 10.5 19.7724 10.5 20.4167Z"
        stroke="#EF5350"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'ThermometerIcon',
  );
  return (
    <IconCmp
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      {...props}
    />
  );
};
export default ThermometerIcon;
