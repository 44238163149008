// Components
import { Droppable } from 'react-beautiful-dnd';

export const DroppableCmp = ({ dndProps, children }) => {
  return (
    <Droppable {...dndProps}>
      {(provided) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          style={{ height: '100%' }}
        >
          {children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
