import { TaskOverviewHeaderCnt } from 'pages/ReportingV2/Reports/TaskOverview/TaskSummaryHeader/taskSummaryHeader.style';
import { TaskSummaryContext } from 'pages/ReportingV2/Reports/TaskOverview/context/taskSummaryContext';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { IconButton } from '@mui/material';
import Tooltip from 'components/Tooltip';
import useTaskReportFilters from 'pages/ReportingV2/Reports/useTaskReportFilters';
import LocationFilterDropdown from 'pages/Widgets/TaskFilters/LocationFilter/locationFilters';
import DateRangePickerCommon from 'components/DateRangePicker/Common';
import useResponsive from 'utils/CustomHooks/useResponsive';
import {
  dateRangePickerSideMenuCommon,
  reportingInitialRange,
} from 'utils/datePicker';
import ProjectsFilterDropdown from 'components/TaskAdvanceFilters/AdvancedFilterBuilder/FilterRow/FilterDropdowns/ProjectsFilter/ProjectFilter';
import SchedulesFilterDropdown from 'components/TaskAdvanceFilters/AdvancedFilterBuilder/FilterRow/FilterDropdowns/SchedulesFilter/SchedulesFilter';
import useDropdownData from 'utils/CustomHooks/useDropdownData';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
function Header() {
  const {
    isFilterApplied,
    handleClearDateFilter,
    handleClearFilter,
    handleFilterChange,
    handleDateChange,
    selectedLocations,
    selectedProjectsList,
    selectedSchedulesList,
    clearAllFilters,
    state,
  } = useTaskReportFilters(TaskSummaryContext);
  const { selectedSchedules, selectedProjects } = useDropdownData();
  const { hasFeature, FEATURES } = useCustomPermission();
  const { isMobileDeviceSm } = useResponsive();

  return (
    <TaskOverviewHeaderCnt style={{ paddingBottom: 0 }}>
      <div className="taskOverviewHeaderLeft">
        <h3 className={'headingText'}>Scheduled Work</h3>
      </div>
      <div className="taskOverviewHeaderRight">
        <LocationFilterDropdown
          selected={selectedLocations}
          onChangeCallback={(option) => handleFilterChange(option, 'locations')}
          onClearCallback={() => handleClearFilter('locations')}
        />
        {hasFeature(FEATURES.PROJECTS) && (
          <ProjectsFilterDropdown
            selected={selectedProjects(selectedProjectsList)}
            onChangeCallback={(option) =>
              handleFilterChange(option, 'projects')
            }
          />
        )}
        <SchedulesFilterDropdown
          selected={selectedSchedules(selectedSchedulesList)}
          onChangeCallback={(option) => handleFilterChange(option, 'schedules')}
        />{' '}
        <DateRangePickerCommon
          clearProps={{
            dateLabel: 'Last 30 Days',
            onClick: handleClearDateFilter,
            initialRange: reportingInitialRange,
            showClearBtn: state?.selectedDateLabel != 'Last 30 Days',
          }}
          buttonProps={{
            hideSelected: true,
          }}
          handleDateChange={handleDateChange}
          selectedDateLabel={state?.selectedDateLabel}
          showLabelPrefix="Date Range: "
          selectedDateRange={{
            from: state?.filters?.fromDate,
            to: state?.filters?.toDate,
          }}
          contentProps={{
            displaySideOptions: !isMobileDeviceSm,
            allowInputs: !isMobileDeviceSm,
            numberOfMonths: isMobileDeviceSm ? 1 : 2,
            sideMenu: dateRangePickerSideMenuCommon,
          }}
          popperProps={{
            placement: 'bottom-start',
          }}
        />
        {isFilterApplied ? (
          <Tooltip title="Clear All Filters">
            <IconButton onClick={clearAllFilters}>
              <FilterAltOffIcon sx={{ color: '#EF5350' }} />
            </IconButton>
          </Tooltip>
        ) : null}
      </div>
    </TaskOverviewHeaderCnt>
  );
}

export default Header;
