import { useEffect, useState } from 'react';
import { BuilderComponent } from '@builder.io/react';

const KioskModeDetails = (props) => {
  const { setBuilderActive } = props;
  const [builderContentJson, setBuilderContentJson] = useState({});

  useEffect(() => {
    window?.builder
      .get('addon-kiosk-mode', {
        url: location.pathname,
      })
      .promise()
      .then((data) => {
        setBuilderContentJson(data);
        setBuilderActive(true);
      });
  }, []);

  return (
    <BuilderComponent model="addon-kiosk-mode" content={builderContentJson} />
  );
};

export default KioskModeDetails;
