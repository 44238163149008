export const XENIA_PRO_TIPS = [
  {
    title: 'Automate Corrective Actions',
    text: 'Did you know? Xenia lets you automate corrective tasks based on specific responses in inspections, saving time and ensuring immediate follow-up.',
  },
  {
    title: 'Track Work Orders by Location',
    text: 'Easily assign and track work orders by location with Xenia. This ensures issues are resolved quickly, no matter where they occur.',
  },
  {
    title: 'Standardize Procedures Across Sites',
    text: 'Use Xenia to create standardized checklists that ensure consistent operations across all your locations, from opening procedures to safety inspections.',
  },
  {
    title: 'Prevent Equipment Failures',
    text: 'Schedule regular preventive maintenance in Xenia to avoid unexpected equipment breakdowns and extend asset life.',
  },
  {
    title: 'Enhance Safety Compliance',
    text: 'Conduct and log safety inspections using Xenia’s customizable templates to meet compliance requirements effortlessly.',
  },
  {
    title: 'Streamline Approval Processes',
    text: 'Use Xenia to automate work order approvals, ensuring tasks are reviewed and assigned without delays.',
  },
  {
    title: 'Boost Accountability with Timestamping',
    text: 'Xenia automatically timestamps each step in your checklists, providing a clear record of when tasks were completed.',
  },
  {
    title: 'Customizable Dashboards',
    text: 'Create custom dashboards in Xenia to track key metrics like task completion rates and safety inspection outcomes in real-time.',
  },
  {
    title: 'Simplify Asset Management',
    text: 'Attach assets to tasks and work orders in Xenia to keep a detailed maintenance history, making future planning easier.',
  },
  {
    title: 'Ensure Quality with Weighted Scoring',
    text: 'Use Xenia’s weighted scoring in checklists to focus on the most critical steps, ensuring high standards are consistently met.',
  },
];
