// Styles
import { ColumnHeaderCnt } from '../board.styles';
// Icons
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

function ColumnHeader(props) {
  const { column, count, collapsed, updateCollapsed } = props;
  const handleColapseColumn = () => {
    updateCollapsed(!collapsed);
  };

  return (
    <ColumnHeaderCnt collapsed={collapsed}>
      {column.title}
      <span className={'childCount'}>{count}</span>

      <span className={'collapsIcon'} onClick={handleColapseColumn}>
        <ArrowLeftIcon />
      </span>
    </ColumnHeaderCnt>
  );
}

export default ColumnHeader;
