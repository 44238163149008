// MUI components
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Typography, Stack } from '@mui/material';
import { colors } from 'components/theme/constants';

export const Container = styled(Stack)(() => ({
  height: '100%',
  justifyContent: 'center',
  padding: '16px 0px',
}));

export const LocationNameCnt = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isParent',
})(({ isParent }) => ({
  display: 'flex',
  lineHeight: isParent ? 'normal' : 'unset',
  alignItems: 'center',
  height: '100%',
  '& .content': {
    display: 'flex',
    flexDirection: 'column',
  },

  '& .parentLocationName': {
    color: 'rgba(111, 111, 111, 0.87)',
    fontSize: 12,
    fontWeight: 500,
  },

  '& .locationName': {
    color: '#212121',
    fontSize: 14,
    fontWeight: 500,
  },
  '& .colorIcon': {
    fontSize: 14,
  },
}));

export const TitleText = styled(Typography)(() => ({
  color: '#212121',
  fontSize: 14,
  fontWeight: 400,
  letterSpacing: 0.17,
}));

export const GreyText = styled(Typography)(() => ({
  color: '#616161',
  fontSize: 14,
  fontWeight: 400,
}));

export const ScheduleCnt = styled(Box)({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  '& .innerCnt': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '4px 8px',
    gap: 6,
    background: '#6868FE',
    borderRadius: 20,
    '& svg': {
      color: '#6868FE',
      height: 16,
      width: 16,
    },
    '& p': {
      fontWeight: 400,
      fontSize: 13,
      lineHeight: '18.59px',
      letterSpacing: '0.17px',
      color: '#fff',
    },
  },
});

export const AssigneeCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
});

export const CheckListCnt = styled('div')({
  height: '100%',
  '& .templateAttached': {
    display: 'flex',
    alignItems: 'center',
    height: 'inherit',
    '& p': {
      marginLeft: 8,
      fontWeight: 300,
      fontSize: 14,
      color: colors.black,
      opacity: 0.87,
      cursor: 'pointer',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      '&:hover': {
        textDecoration: 'underline',
        transition: 'ease all 0.2s',
        fontWeight: 700,
      },
    },
  },
});
