import { Stack, styled } from '@mui/material';

export const AIFooterNotesBox = styled(Stack)(() => ({
  border: '1px solid transparent',
  backgroundColor: '#FFF8E1',
  borderRadius: 20,
  padding: 0,
  position: 'absolute',
  bottom: 110,
  margin: '0px 48px 0px 35px',
  height: 75,
  width: 'calc(100% - 113px)',

  '& .title': {
    fontWeight: 500,
    fontSize: 15,
    lineHeight: '21.86px',
    color: '#000000',
  },
  '& .tipTitle': {
    fontWeight: 800,
    fontSize: 16,
    lineHeight: '21.86px',
    color: 'rgba(0, 0, 0, 1)',
  },
  '& .statement': {
    fontWeight: 500,
    fontSize: 15,
    lineHeight: '19.12px',
    color: 'rgba(66, 66, 66, 1)',
  },

  '& .iconBox': {
    height: 50,
    width: 50,
    borderRadius: 14,
    backgroundColor: '#FDD835',
    marginRight: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const AIFooterStopGeneratingBox = styled(Stack)(() => ({
  border: '1px solid transparent',
  backgroundImage:
    'linear-gradient(white, white), linear-gradient(93.08deg, #42E5E5 1.54%, #66ACFE 24.92%, #7468FE 49.81%, #CE68FE 74.2%, #FE689E 99.09%)',
  borderRadius: 20,
  padding: 0,
  position: 'absolute',
  bottom: 20,
  backgroundColor: 'white',
  margin: '0px 48px 0px 35px',
  width: 'calc(100% - 113px)',
  backgroundOrigin: 'border-box',
  backgroundClip: 'content-box, border-box',

  '& .title': {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '21.86px',
    color: 'rgba(0, 0, 0, 1)',
  },
  '& .statement': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '19.12px',
    color: 'rgba(66, 66, 66, 1)',
  },
}));

export const AIFooterCompletedGenerateBox = styled(Stack)(() => ({
  border: '1px solid transparent',
  backgroundImage:
    'linear-gradient(white, white), linear-gradient(93.08deg, #42E5E5 1.54%, #66ACFE 24.92%, #7468FE 49.81%, #CE68FE 74.2%, #FE689E 99.09%)',
  borderRadius: 20,
  padding: 0,
  position: 'absolute',
  bottom: 20,
  backgroundColor: 'white',
  margin: '0px 48px 0px 35px',
  width: 'calc(100% - 113px)',
  backgroundOrigin: 'border-box',
  backgroundClip: 'content-box, border-box',

  '& .title': {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '21.86px',
    color: 'rgba(0, 0, 0, 1)',
  },
  '& .statement': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '19.12px',
    color: 'rgba(66, 66, 66, 1)',
  },
}));
