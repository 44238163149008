// MUI Components
import { Box, Skeleton } from '@mui/material';

function KanbanSkeleton() {
  const columnCount = 5; // Adjust based on the number of columns you have (from the image it looks like 5)
  const cardCountPerColumn = [4, 2, 1, 3, 2]; // Adjust based on the number of cards per column

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      style={{
        padding: '0 22px',
      }}
      height={'100%'}
      gap={2}
    >
      {[...Array(columnCount)].map((_, columnIndex) => (
        <Box
          key={columnIndex}
          width="100%"
          p={1}
          sx={{ backgroundColor: '#f4f5f7', borderRadius: 2 }}
        >
          <Skeleton
            variant="rectangular"
            height={40}
            width="80%"
            sx={{ marginBottom: 2, borderRadius: 1 }}
          />
          <Box mt={1}>
            {[...Array(cardCountPerColumn[columnIndex] || 4)].map(
              (_, taskIndex) => (
                <Box key={taskIndex} mb={2}>
                  <Skeleton
                    variant="rectangular"
                    height={80}
                    sx={{ borderRadius: 2 }}
                  />
                </Box>
              ),
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
}

export default KanbanSkeleton;
