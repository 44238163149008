import { Box } from '@mui/material';
import styled from '@mui/system/styled';

export const AIChecklistCnt = styled(Box)({
  marginBottom: 30,
});

export const PromptCnt = styled(Box)({
  height: 136,
  padding: '18px 20px 18px 20px',
  borderRadius: 14,

  '& .title': {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '21.86px',
    color: 'rgba(0, 0, 0, 1)',
  },

  '& .statement': {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '17.76px',
    color: 'rgba(33, 33, 33, 1)',
  },

  '& .actionBtn': {
    marginTop: 15,
    borderRadius: 6,
    boxShadow: '0px 4px 8px 0px rgba(248, 138, 105, 0.24)',
    padding: 10,

    fontSize: 14,
    fontWeight: 700,
    lineHeight: '18px',

    transition: 'transform 0.3s ease, box-shadow 0.3s ease',

    '&:hover': {
      transform: 'scale(1.03)',
      boxShadow: '0px 6px 12px 0px rgba(248, 138, 105, 0.4)',
      cursor: 'pointer',
    },
  },
});
