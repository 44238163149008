import { Stack, styled } from '@mui/material';

export const MainContainer = styled(Stack)({
  width: 1080,
  height: 660,

  '& .leftSide': {
    height: 'inherit',
    background:
      'linear-gradient(133.32deg, rgba(65, 169, 245, 0.2) 0%, rgba(216, 71, 252, 0.2) 100%)',
    position: 'relative',

    '& .content': {
      padding: '40px 30px',

      '& svg': {
        height: '60px',
        width: '60px',
        marginBottom: 25,
      },
    },

    '& .title': {
      fontWeight: 800,
      fontSize: 24,
      lineHeight: '32.78px',
      color: 'rgba(7, 54, 127, 1)',
      marginBottom: '15px',
    },

    '& .statement': {
      fontWeight: 400,
      fontSize: 15,
      lineHeight: '24px',
      color: 'rgba(7, 54, 127, 1)',
    },

    '& .sideNote': {
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      padding: '12px 20px 12px 20px',
      borderRadius: 8,

      position: 'absolute',
      bottom: 30,
      margin: 'auto 30px',

      '& .title': {
        fontWeight: 600,
        fontSize: 13,
        lineHeight: '17.76px',
        letterSpacing: '0.15px',
        color: 'rgba(7, 54, 127, 1)',
        marginBottom: 5,
      },

      '& .statement': {
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '18px',
        letterSpacing: '0.15px',
        color: 'rgba(7, 54, 127, 1)',
      },
    },
  },

  '& .rightSide': {
    height: '100%',
    padding: '40px 50px',

    '& .selectedFileBox': {
      border: '1px solid',
      borderImageSource:
        'linear-gradient(93.08deg, #42E5E5 1.54%, #66ACFE 24.92%, #7468FE 49.81%, #CE68FE 74.2%, #FE689E 99.09%)',
      borderImageSlice: 1,
      borderRadius: '4px',
      padding: 12,
      margin: '40px 0px 30px 0px',

      '& svg': {
        height: 40,
        width: 40,
      },

      '& .label': {
        fontWeight: 700,
        fontSize: 15,
        lineHeight: '20.49px',
        color: 'rgba(0, 0, 0, 1)',
      },

      '& .details': {
        fontWeight: 500,
        fontSize: 13,
        lineHeight: '17.76px',
        color: 'rgba(97, 97, 97, 1)',
      },

      '& .closeCnt': {
        cursor: 'pointer',
        border: '1px solid rgba(224, 224, 224, 1)',
        width: 36,
        height: 36,
        borderRadius: 100,

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        '& path': {
          stroke: 'rgba(66, 66, 66, 1)',
        },
      },
    },

    '& .uploadBox': {
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      gap: '10px',
      margin: '40px 0px 30px 0px',
      border: '1px dashed transparent',
      borderRadius: '4px',
      backgroundImage:
        'linear-gradient(#fff, #fff), linear-gradient(93.08deg, #42E5E5 1.54%, #66ACFE 24.92%, #7468FE 49.81%, #CE68FE 74.2%, #FE689E 99.09%)',
      backgroundOrigin: 'border-box',
      backgroundClip: 'content-box, border-box',

      '& svg': {
        height: 70,
        width: 70,
      },

      '& .label': {
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '21.86px',
      },
      '& .statement': {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '22px',
        color: 'rgba(33, 33, 33, 1)',
      },
    },

    '& .title': {
      fontWeight: 700,
      fontSize: 24,
      lineHeight: '32.78px',
      color: 'rgba(0, 0, 0, 1)',
      textAlign: 'center',
      marginBottom: 5,
    },

    '& .statement': {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '19.12px',
      color: 'rgba(33, 33, 33, 1)',
      textAlign: 'center',
    },

    '& .label': {
      fontWeight: 600,
      fontSize: 13,
      lineHeight: '20px',
      color: 'rgba(0, 0, 0, 1)',
    },
  },
});

export const CrossIconBtn = styled('div')({
  position: 'absolute',
  right: 10,
  top: 8,
  zIndex: 1,
  cursor: 'pointer',

  '& path': {
    stroke: 'rgba(0, 0, 0, 0.54)',
  },
});
