import { styled } from '@mui/material';
import { colors } from 'components/theme/constants';

export const CheckListCnt = styled('div')({
  height: '100%',
  '& .templateAttached': {
    display: 'flex',
    alignItems: 'center',
    height: 'inherit',
    '& p': {
      marginLeft: 8,
      fontWeight: 300,
      fontSize: 14,
      color: colors.black,
      opacity: 0.87,
      cursor: 'pointer',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      '&:hover': {
        textDecoration: 'underline',
        transition: 'ease all 0.2s',
        fontWeight: 700,
      },
    },
  },
});
