// React
import { Fragment, useCallback } from 'react';

// React Router
import { useLocation } from 'react-router-dom';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import selectors from 'store/selectors';
import actions from 'store/actions';

// MUI
import { Badge, Divider, Box } from '@mui/material';
import List from '@mui/material/List';

// Custom components
import SidebarItem from 'components/layout/sidebar/sidebarItem/SidebarItem';
import { HasPermission } from 'components/HasPermission';
import SidebarOperationTemplateDropdown from 'components/layout/sidebar/sidebarDropdownItems/operationTemplate/SidebarOperationTemplate';
import SidebarTaskAndWorkOrdersDropdown from 'components/layout/sidebar/sidebarDropdownItems/taskAndWorkOrders/SidebarTaskAndWorkOrders';

// Icons
import AssetsIcon from 'components/Icons/sidebarIcons/assetsIcon';
import ReportingIcon from 'components/Icons/sidebarIcons/ReportingIcon';
import HomeIcon from 'components/Icons/sidebarIcons/homeIcon';
import MessagingIcon from 'components/Icons/sidebarIcons/messagingIcon';
import LocationIcon from 'components/Icons/sidebarIcons/locationIcon';
import SettingsIcon from 'components/Icons/sidebarIcons/settingsIcon';
import ToolsIcon from 'components/Icons/sidebarIcons/toolsIcon';
import TelescopeIcon from 'components/Icons/sidebarIcons/telescopeIcon';
import InviteUsersIcon from 'components/Icons/sidebarIcons/inviteUsersIcon';
import NotificationsIcon from 'components/Icons/sidebarIcons/notificationsIcon';
import UsersAndTeamsIcon from 'components/Icons/sidebarIcons/usersAndTeamsIcon';

// Utils
import { appRoutes } from 'utils/globalVariable';

// Hooks
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { useSidebarConfig } from 'utils/CustomHooks/useSidebarConfig';

// Constants
import DIALOGS from 'utils/dialogIds';
import DocumentsIcon from 'components/Icons/documents';
import SidebarScheduledWorkDropdown from '../sidebarDropdownItems/scheduledWork/scheduledWork';
import SidebarWorkRequests from '../sidebarDropdownItems/workReqests/SidebarWorkrequests';
import AddonIcon from 'components/Icons/sidebarIcons/addonIcon';

const CustomMenu = ({ handleNavClick, sidebarOpen, messagesUnreadCount }) => {
  const dispatch = useDispatch();
  const currentPath = useLocation().pathname;
  const userRoletype: any = useSelector(selectors.getAuthRole);
  const unreadCount: number = useSelector(selectors.getNotificationUnreadCount);

  const { PERMISSIONS, hasPermission } = useCustomPermission();

  const canManageUsers = hasPermission(PERMISSIONS.CAN_MANAGE_USERS);
  const canManageTeams = hasPermission(PERMISSIONS.CAN_MANAGE_TEAMS);
  const canManageRoles = hasPermission(PERMISSIONS.CAN_MANAGE_ROLES);
  const { MENU_ITEM_IDS, visibleMenuItems, sidebarConfig } = useSidebarConfig();

  const sidebarItemsMap = {
    [MENU_ITEM_IDS.operationsTemplates]: (
      <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_LOG_SUBMISSION]}>
        <SidebarOperationTemplateDropdown sidebarOpen={sidebarOpen} />
      </HasPermission>
    ),
    [MENU_ITEM_IDS.taskAndWorkOrders]: (
      <HasPermission permissions={[PERMISSIONS.CAN_VIEW_TASKS]}>
        <SidebarTaskAndWorkOrdersDropdown sidebarOpen={sidebarOpen} />
      </HasPermission>
    ),
    [MENU_ITEM_IDS.sopWriterAndFiles]: (
      <HasPermission permissions={[PERMISSIONS.CAN_VIEW_SOP_AND_FILES]}>
        <SidebarItem
          showChip
          title={'Documents'}
          icon={
            <DocumentsIcon
              filled={
                currentPath.includes('/documents/my-documents') ||
                currentPath.includes('/documents/archive')
              }
            />
          }
          onClick={() => handleNavClick('/documents/my-documents')}
          isSelected={
            currentPath.includes('/documents/my-documents') ||
            currentPath.includes('/documents/archive')
          }
          sidebarOpen={sidebarOpen}
        />
      </HasPermission>
    ),
    [MENU_ITEM_IDS.scheduledWork]: (
      <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_TASKS]}>
        <SidebarScheduledWorkDropdown sidebarOpen={sidebarOpen} />
      </HasPermission>
    ),
    [MENU_ITEM_IDS.requests]: (
      <HasPermission permissions={[PERMISSIONS.CAN_VIEW_TASK_REQUESTS]}>
        <SidebarWorkRequests sidebarOpen={sidebarOpen} />
      </HasPermission>
    ),
    [MENU_ITEM_IDS.dashboards]: (
      <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_DASHBOARDS]}>
        <SidebarItem
          title={'Dashboards'}
          chipText="Beta"
          showChip={false}
          icon={<ReportingIcon filled={currentPath.includes('/dashboards')} />}
          onClick={() => handleNavClick('/dashboards')}
          isSelected={currentPath.includes('/dashboards')}
          sidebarOpen={sidebarOpen}
        />
        {/*</VerifyFeatureAccess>*/}
      </HasPermission>
    ),
    [MENU_ITEM_IDS.chats]: (
      <HasPermission permissions={[PERMISSIONS.CAN_USE_CHAT]}>
        <SidebarItem
          title={'Chats'}
          icon={
            sidebarOpen ? (
              <MessagingIcon filled={currentPath.includes('/messages')} />
            ) : (
              <Badge badgeContent={messagesUnreadCount}>
                <MessagingIcon filled={currentPath.includes('/messages')} />
              </Badge>
            )
          }
          onClick={() => handleNavClick('/messages')}
          isSelected={currentPath.includes('/messages')}
          sidebarOpen={sidebarOpen}
        />
      </HasPermission>
    ),
    [MENU_ITEM_IDS.locations]: (
      <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_LOCATIONS]}>
        <SidebarItem
          title={'Locations'}
          icon={<LocationIcon filled={currentPath.includes('/locations')} />}
          onClick={() => handleNavClick('/locations')}
          isSelected={currentPath.includes('/locations')}
          sidebarOpen={sidebarOpen}
        />
      </HasPermission>
    ),
    [MENU_ITEM_IDS.assets]: (
      <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_ASSETS]}>
        <SidebarItem
          title={'Assets'}
          icon={<AssetsIcon filled={currentPath.includes(appRoutes.assets)} />}
          onClick={() => handleNavClick(appRoutes.assets)}
          isSelected={currentPath.includes('/assets')}
          sidebarOpen={sidebarOpen}
        />
      </HasPermission>
    ),
    [MENU_ITEM_IDS.settings]: (
      <SidebarItem
        title={'Settings'}
        icon={<SettingsIcon filled={currentPath.includes('/settings')} />}
        onClick={() => {
          const pathToNavigate = hasPermission(
            PERMISSIONS.CAN_MANAGE_WORKSPACES,
          )
            ? '/settings/organization-management'
            : '/settings/my-profile';
          handleNavClick(pathToNavigate);
        }}
        isSelected={currentPath.includes('/settings')}
        sidebarOpen={sidebarOpen}
      />
    ),
  };

  const getSidebarComponent = useCallback(
    (menuItemId: string) => sidebarItemsMap[menuItemId],
    [
      sidebarConfig,
      userRoletype,
      sidebarOpen,
      messagesUnreadCount,
      visibleMenuItems,
      currentPath,
    ],
  );

  return (
    <List sx={{ pt: 0, pb: 0 }} className="sidebarMenuList">
      {userRoletype !== 'Hotel' && (
        <Fragment>
          <SidebarItem
            title={'Home'}
            icon={
              <HomeIcon
                filled={
                  currentPath.includes('/dashboard') &&
                  !currentPath.includes('/dashboards')
                }
              />
            }
            onClick={() => handleNavClick('/dashboard')}
            isSelected={
              currentPath.includes('/dashboard') &&
              !currentPath.includes('/dashboards')
            }
            sidebarOpen={sidebarOpen}
          />
        </Fragment>
      )}
      {(canManageUsers || canManageTeams || canManageRoles) && (
        <SidebarItem
          title={'Users & Teams'}
          icon={
            <UsersAndTeamsIcon
              filled={currentPath.includes('/users-and-teams')}
            />
          }
          onClick={() => handleNavClick('/users-and-teams')}
          isSelected={currentPath.includes('/users-and-teams')}
          sidebarOpen={sidebarOpen}
        />
      )}
      <SidebarItem
        title={'Notifications'}
        icon={
          <NotificationsIcon
            filled={currentPath.includes('/my-notifications')}
          />
        }
        onClick={() => handleNavClick('/my-notifications')}
        isSelected={currentPath.includes('/my-notifications')}
        sidebarOpen={sidebarOpen}
        notificationCount={unreadCount}
      />
      <Divider sx={{ m: '4px 12px' }} />
      {sidebarConfig?.visible?.map((menuItem) =>
        getSidebarComponent(menuItem.id),
      )}
      <SidebarItem
        title={'Customize Menu'}
        icon={<ToolsIcon />}
        onClick={() =>
          dispatch(
            actions.setDialog({
              dialogId: DIALOGS.CUSTOMIZE_SIDEBAR_DIALOG,
              open: true,
            }),
          )
        }
        isSelected={false}
        sidebarOpen={sidebarOpen}
      />

      {sidebarOpen ? (
        <Divider sx={{ m: '8px 12px' }} />
      ) : (
        <Box sx={{ width: '100%', m: '8px 12px' }}>
          <Divider />
        </Box>
      )}

      <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_CHECKLIST]}>
        <SidebarItem
          title={'Explore Templates'}
          icon={<TelescopeIcon />}
          onClick={() => {
            dispatch(
              actions.setDialog({
                dialogId: DIALOGS.TEMPLATE_LIBRARY,
                open: true,
              }),
            );
          }}
          isSelected={false}
          sidebarOpen={sidebarOpen}
        />
      </HasPermission>

      <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_BILLING]}>
        <SidebarItem
          title={'Xenia Add-ons'}
          icon={<AddonIcon filled={currentPath.includes('/addons')} />}
          onClick={() => {
            handleNavClick('/addons');
          }}
          isSelected={currentPath.includes('/addons')}
          sidebarOpen={sidebarOpen}
        />
      </HasPermission>

      <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_USERS]}>
        <SidebarItem
          title={'Invite Users'}
          icon={<InviteUsersIcon />}
          onClick={() => {
            dispatch(
              actions.setDialog({
                dialogId: 'inviteUserDialogV2',
                open: true,
              }),
            );
          }}
          isSelected={false}
          sidebarOpen={sidebarOpen}
        />
      </HasPermission>
    </List>
  );
};

export default CustomMenu;
