import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { Box, Grid, Stack, Typography } from '@mui/material';
import CustomDialog from 'components/CustomDialog/customDialog';
import CustomInput from 'components/Form/TextField/TextField.cmp';

import DIALOGS from 'utils/dialogIds';
import selectors from 'store/selectors';
import { setDialog } from 'store/actions/dialogActions';
import CustomButton from 'components/Button/CustomButton';
import CrossIcon from 'components/Icons/crossIcon';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

import { CrossIconBtn, MainContainer } from './style';
import AIStarIcon2 from 'components/Icons/aiStarIcon2';
import FileUploadIcon from 'components/Icons/fileUploadIcon';
import { useSnackbar } from 'notistack';
import FileUploadedIcon from 'components/Icons/fileUploaded';
import {
  DOCUMENT_BASED_PROMPT,
  INDEXED_DB_NAME,
  INDEXED_STORE_NAME,
} from 'utils/constants';
import useIndexedDB from 'utils/CustomHooks/useIndexedDB';

const MAX_UPLOAD_SIZE = 11;

const ConvertDocumentWithAI = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { workspaceId } = useWorkspaceHook();
  const dispatch = useDispatch();
  const dialogState = useSelector(selectors.getDialogState);
  const { open, data } = dialogState?.[DIALOGS?.CONVERT_DOCUMENT_WITH_AI] || [];

  const { saveIndexedDBData } = useIndexedDB(
    INDEXED_DB_NAME,
    INDEXED_STORE_NAME,
  );

  const [metadata, setMetadata] = useState<any>({
    attachment: null,
    additionalInfo: '',
  });

  useEffect(() => {
    if (data?.metadata) {
      setMetadata({
        attachment: data?.metadata?.attachment,
        additionalInfo: data?.metadata?.additionalInfo,
      });
    }
  }, [data?.metadata]);

  const handleClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS?.CONVERT_DOCUMENT_WITH_AI,
      }),
    );
  };

  const handleChangeMetadata = (key, value) => {
    setMetadata({
      ...metadata,
      [key]: value,
    });
  };

  const generateTemplateHandler = async () => {
    const payload = {
      id: 1,
      attachment: metadata?.attachment,
      additionalInfo: metadata?.additionalInfo ?? '',
      type: DOCUMENT_BASED_PROMPT,
    };

    await saveIndexedDBData(payload).then(console.log).catch(console.error);

    window.location.href = `/ws/${workspaceId}/checklist/new?ai=true`;
    handleClose();
  };

  const handleUpload = (files) => {
    setMetadata({
      ...metadata,
      attachment: files?.[0],
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: handleUpload,
    maxFiles: 1,
    multiple: false,
    maxSize: MAX_UPLOAD_SIZE * 1048576,
    accept: {
      'application/pdf': ['.pdf'],

      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        ['.docx'],
      'text/plain': ['.txt'],
      'text/csv': ['.csv'],
    },
    onDropRejected: (rejectedFiles) => {
      const errorMessage = rejectedFiles?.[0]?.errors?.[0]?.code;
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });
    },
  });

  const clearAttachment = () => {
    setMetadata({
      ...metadata,
      attachment: null,
    });
  };

  const selectedFileType = useMemo(() => {
    if (metadata?.attachment?.name) {
      const splitted = metadata?.attachment?.name?.split('.');
      return splitted?.[splitted?.length - 1];
    }
  }, [metadata?.attachment]);

  const isButtonDisable = useMemo(() => {
    if (!metadata?.attachment) return true;

    return false;
  }, [metadata]);

  return (
    <CustomDialog
      hideActions={true}
      dialogProps={{
        open: open,
        maxWidth: false,
        onClose: handleClose,
      }}
    >
      <MainContainer>
        <Grid container spacing={2} style={{ height: 'inherit' }}>
          <Grid item xs={4} style={{ height: 'inherit' }}>
            <Box className="leftSide">
              <div className="content">
                <AIStarIcon2 />
                <Typography className="title">
                  Instant Templates, Effortless Operations
                </Typography>
                <Typography className="statement">
                  Transform your ideas into actionable templates with our
                  AI-powered wizardry. Say goodbye to manual formatting and
                  hello to the future of streamlined operations.
                </Typography>
              </div>

              <Box className="sideNote">
                <Typography className="title">How does this work?</Typography>
                <Typography className="statement">
                  We’ll use third-party AI provider to generate content based on
                  your provided description. Avoid using personal and sensitive
                  data.
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={8}>
            <Box className="rightSide">
              <CrossIconBtn className="closeIcon" onClick={handleClose}>
                <CrossIcon style={{ height: 20 }} />
              </CrossIconBtn>

              <Typography className="title">
                Convert Document with AI
              </Typography>
              <Typography className="statement">
                Convert an existing pdf, csv or word file into a Xenia template.
              </Typography>

              {metadata?.attachment ? (
                <Box className="selectedFileBox">
                  <Stack
                    direction="row"
                    gap="10px"
                    width="100%"
                    alignItems="center"
                  >
                    <Stack
                      direction="row"
                      gap="10px"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Stack direction="row" gap="10px" alignItems="center">
                        <FileUploadedIcon />
                        <Stack direction="column" gap="2px">
                          <Typography className="label">
                            {metadata?.attachment?.name}
                          </Typography>
                          <Typography className="details">
                            {selectedFileType} •{' '}
                            {(
                              metadata?.attachment?.size /
                              (1024 * 1024)
                            )?.toFixed(2)}{' '}
                            MB
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                    <div className="closeCnt" onClick={clearAttachment}>
                      <CrossIcon style={{ height: 20 }} />
                    </div>
                  </Stack>
                </Box>
              ) : (
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  <Box className="uploadBox">
                    <Stack
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      style={{ padding: '30px 20px 30px 20px' }}
                    >
                      <FileUploadIcon />
                      <Typography className="label">
                        Convert Your Files into Operations Templates Instantly!
                      </Typography>

                      <Typography className="statement">
                        Upload a PDF, DOCX, CSV or TXT file, up to 10 MB.
                      </Typography>
                    </Stack>
                  </Box>
                </div>
              )}

              <Stack direction="column" gap="5px">
                <Typography className="label">
                  Additional Business Details (Optional)
                </Typography>
                <CustomInput
                  suppressErrorSpace={true}
                  muiLabel={false}
                  fieldProps={{
                    placeholder: 'Additional Business Information',
                    onChange: (e) =>
                      handleChangeMetadata('additionalInfo', e.target.value),
                    value: metadata.additionalInfo ?? '',
                  }}
                />
              </Stack>

              <Stack
                justifyContent="center"
                alignItems="center"
                marginTop="30px"
                sx={{
                  '& path': {
                    stroke: 'white',
                  },
                }}
              >
                <CustomButton
                  variant="contained"
                  onClick={generateTemplateHandler}
                  disabled={isButtonDisable}
                  style={{
                    background: isButtonDisable
                      ? 'lightGray'
                      : 'linear-gradient(133.32deg, #4194F5 0%, #D847FC 100%)',
                  }}
                  sx={{
                    '& svg': {
                      height: 23,
                      width: 23,
                    },
                  }}
                  startIcon={isButtonDisable ? null : <AIStarIcon2 />}
                >
                  Generate Template
                </CustomButton>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </MainContainer>
    </CustomDialog>
  );
};

export default ConvertDocumentWithAI;
