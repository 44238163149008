import { FC, useState } from 'react';
import { AIChecklistCnt, PromptCnt } from './style';
import { Grid, Stack, Typography } from '@mui/material';
import AIStarIcon from 'components/Icons/aiStarIcon';
import CustomButton from 'components/Button/CustomButton';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import { useDispatch } from 'react-redux';
import AIStarIcon2 from 'components/Icons/aiStarIcon2';

const AIChecklist: FC = () => {
  const dispatch = useDispatch();
  const [isTextBasedLoader, setIsTextBasedLoader] = useState(false);

  const openTextBasedPrompt = () => {
    setIsTextBasedLoader(true);
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS?.CREATE_TEMPLATE_WITH_AI,
      }),
    );

    setTimeout(() => {
      setIsTextBasedLoader(false);
    }, 1500);
  };

  const openDocumentBasedPrompt = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS?.CONVERT_DOCUMENT_WITH_AI,
      }),
    );
  };

  return (
    <AIChecklistCnt>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <PromptCnt
            style={{
              background:
                'linear-gradient(133.32deg, rgba(245, 162, 65, 0.2) 0%, rgba(252, 71, 212, 0.2) 100%), linear-gradient(0deg, #FFFFFF, #FFFFFF)',
            }}
          >
            <Stack
              direction="row"
              gap="10px"
              sx={{
                '& svg': { height: '40px', width: '40px', marginTop: '5px' },
              }}
            >
              <AIStarIcon />
              <Stack direction="column" gap="5px">
                <Typography className="title">
                  Create a Checklist with AI
                </Typography>
                <Typography className="statement">
                  Let our AI tool craft personalized templates for you
                </Typography>

                <CustomButton
                  variant="contained"
                  onClick={openTextBasedPrompt}
                  buttonLoader={isTextBasedLoader}
                  disabled={isTextBasedLoader}
                  className="actionBtn"
                  style={{
                    width: '150px',
                    height: 40,
                    background:
                      'linear-gradient(133.32deg, #F5A241 0%, #FC47D4 100%)',
                  }}
                  sx={{
                    '& svg': {
                      marginTop: 0,
                      height: 25,
                      width: 25,
                    },
                  }}
                >
                  Start Now
                </CustomButton>
              </Stack>
            </Stack>
          </PromptCnt>
        </Grid>
        <Grid item xs={6}>
          <PromptCnt
            style={{
              background:
                'linear-gradient(133.32deg, rgba(65, 169, 245, 0.2) 0%, rgba(216, 71, 252, 0.2) 100%),linear-gradient(0deg, #FFFFFF, #FFFFFF)',
            }}
          >
            <Stack
              direction="row"
              gap="10px"
              sx={{
                '& svg': { height: '40px', width: '40px', marginTop: '5px' },
              }}
            >
              <AIStarIcon2 />
              <Stack direction="column" gap="5px">
                <Typography className="title">
                  Convert an Existing Checklist with AI
                </Typography>
                <Typography className="statement">
                  Convert an existing pdf, csv or word file into a Xenia
                  template
                </Typography>

                <CustomButton
                  variant="contained"
                  // onClick={openDocumentBasedPrompt}
                  className="actionBtn"
                  style={{
                    width: '162px',
                    background:
                      'linear-gradient(133.32deg, #4194F5 0%, #D847FC 100%)',
                  }}
                >
                  Coming Soon
                </CustomButton>
              </Stack>
            </Stack>
          </PromptCnt>
        </Grid>
      </Grid>
    </AIChecklistCnt>
  );
};

export default AIChecklist;
