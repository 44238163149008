// React
import { useState } from 'react';

// Custom components
import CustomListItem from 'components/List/ListItem/ListItem';
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';

// Icons
import DoneIcon from '@mui/icons-material/Done';

import { GroupByBtn, StyledList } from './groupByDropdown.style';
import { isEmpty } from 'lodash';
import { StyledEndIconCnt } from 'components/Dropdown/AssigneeDropdown/assigneeDropdownSelect.style';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function GroupByDropdown(props) {
  const {
    listProps = {},
    groupBy,
    updateGroupBy,
    groupByOptions = [],
    title = 'Group By',
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const onViewSelect = (colId) => {
    updateGroupBy(colId);
  };
  const handleDropdownOpen = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleDropdownClose = (event) => {
    setAnchorEl(null);
  };

  const ListItems = () => {
    return (
      <>
        <StyledList
          sx={{ width: '100%' }}
          dense={true}
          disablePadding
          {...listProps}
        >
          {groupByOptions.map((c, i) => {
            const isSelected = c.id == groupBy;
            return (
              <CustomListItem
                key={c.title}
                id={c.title}
                selected={isSelected}
                checkbox={false}
                onClick={() => onViewSelect(c.id)}
                endIcon={isSelected && <DoneIcon />}
              >
                {c.title}
              </CustomListItem>
            );
          })}
        </StyledList>
      </>
    );
  };
  const selectedViewOption = groupByOptions.find((t) => t.id == groupBy);
  return (
    <>
      <GroupByBtn
        variant="outlined"
        buttonType="grayWhite"
        onClick={handleDropdownOpen}
        selected={!isEmpty(selectedViewOption)}
        endIcon={
          <StyledEndIconCnt>
            <KeyboardArrowDownIcon style={{ fontSize: 20 }} />
          </StyledEndIconCnt>
        }
      >
        {title}: <b>{selectedViewOption ? selectedViewOption?.title : ''}</b>
      </GroupByBtn>
      <CustomDropdown
        closeAction={handleDropdownClose}
        popperProps={{
          content: <ListItems />,
          id: 'columnSelectionDropdown',
          style: { width: 140 },
          open: !!anchorEl,
          placement: 'bottom-start',
          anchorEl,
        }}
      ></CustomDropdown>
    </>
  );
}

export default GroupByDropdown;
