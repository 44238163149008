import { createSvgIcon } from '@mui/material';

const OperationsIcon = (props) => {
  const { showBackground = true } = props;
  const IconCmp = createSvgIcon(
    <>
      {showBackground && (
        <rect y="0.548828" width="48" height="48" rx="8" fill="#FECDD2" />
      )}
      <path
        opacity="0.12"
        d="M12.3335 17.7822C12.3335 16.4754 12.3335 15.822 12.5878 15.3229C12.8115 14.8838 13.1685 14.5269 13.6075 14.3031C14.1066 14.0488 14.76 14.0488 16.0668 14.0488H20.5262C21.3637 14.0488 21.7825 14.0488 22.1523 14.1764C22.4792 14.2891 22.777 14.4732 23.0241 14.7152C23.3035 14.9888 23.4908 15.3634 23.8654 16.1126L25.1668 18.7155H12.3335V17.7822Z"
        fill="#B23842"
      />
      <path
        d="M12.3335 18.7155H30.0668C32.027 18.7155 33.0071 18.7155 33.7558 19.097C34.4144 19.4325 34.9498 19.968 35.2854 20.6265C35.6668 21.3752 35.6668 22.3553 35.6668 24.3155V29.4488C35.6668 31.409 35.6668 32.3891 35.2854 33.1378C34.9498 33.7964 34.4144 34.3318 33.7558 34.6674C33.0071 35.0488 32.027 35.0488 30.0668 35.0488H17.9335C15.9733 35.0488 14.9932 35.0488 14.2445 34.6674C13.586 34.3318 13.0505 33.7964 12.715 33.1378C12.3335 32.3891 12.3335 31.409 12.3335 29.4488V18.7155Z"
        fill="#FEEBEE"
      />
      <path
        d="M25.1668 18.7155L23.8654 16.1126C23.4908 15.3634 23.3035 14.9888 23.0241 14.7152C22.777 14.4732 22.4792 14.2891 22.1522 14.1764C21.7825 14.0488 21.3637 14.0488 20.5262 14.0488H16.0668C14.76 14.0488 14.1066 14.0488 13.6075 14.3031C13.1685 14.5269 12.8115 14.8838 12.5878 15.3229C12.3335 15.822 12.3335 16.4754 12.3335 17.7822V18.7155M12.3335 18.7155H30.0668C32.027 18.7155 33.0071 18.7155 33.7558 19.097C34.4144 19.4325 34.9498 19.968 35.2854 20.6265C35.6668 21.3752 35.6668 22.3553 35.6668 24.3155V29.4488C35.6668 31.409 35.6668 32.3891 35.2854 33.1378C34.9498 33.7964 34.4144 34.3318 33.7558 34.6674C33.0071 35.0488 32.027 35.0488 30.0668 35.0488H17.9335C15.9733 35.0488 14.9932 35.0488 14.2445 34.6674C13.586 34.3318 13.0505 33.7964 12.715 33.1378C12.3335 32.3891 12.3335 31.409 12.3335 29.4488V18.7155ZM20.5002 26.8822L22.8335 29.2155L28.0835 23.9655"
        stroke="#B23842"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'OperationsIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 48 49'} {...props} />;
};
export default OperationsIcon;
