import React from 'react';
import {
  LeftBorder,
  TaskListTileCnt,
  LocationInfo,
  TaskStatusChip,
} from 'pages/task/ListViewV2/TaskTile/styles';
import LocationIcon from 'components/Icons/locationIcon';
import { statusDropdownData } from 'helper/task/dropdownData';
import DueDate from 'pages/task/ListViewV2/TaskTile/dueDate';
import PriorityCmp from 'pages/task/ListViewV2/TaskTile/priority';
import CategoryChip from 'pages/task/ListViewV2/TaskTile/category';
import TaskAssignee from 'pages/task/ListViewV2/TaskTile/Assignee';
import Comments from 'pages/task/ListViewV2/TaskTile/comments';
import ActionDropdown from 'pages/task/ListViewV2/TaskTile/actionDropdown';
import { useTaskEdit } from 'controller/useTaskEdit';
import useResponsive from 'utils/CustomHooks/useResponsive';
import LinesEllipsis from 'react-lines-ellipsis';

const areEqual = (prevProps: any, nextProps: any) => {
  return prevProps.task === nextProps.task;
};
const TaskListTile = React.memo(({ task }: any) => {
  const { handleEditDetailTask } = useTaskEdit();
  const { isMobileDeviceSm } = useResponsive();

  const taskStatusObj = statusDropdownData.find(
    (s) => s.id === task.taskStatus,
  );
  return (
    <TaskListTileCnt onClick={() => handleEditDetailTask(task?.id, 'detail')}>
      <LeftBorder status={task.taskStatus} />
      <div className={'header'}>
        <div className={'headerLeftCnt'}>
          {task?.Location?.name ? (
            <LocationInfo>
              <span className={'icon'}>
                <LocationIcon style={{ fontSize: 16, fill: 'none' }} />
              </span>
              <span className={'locationName'}>{task?.Location?.name}</span>
            </LocationInfo>
          ) : null}

          <LinesEllipsis
            className="title"
            text={task?.title || ''}
            maxLine="1"
            ellipsis="..."
            trimRight
            basedOn="letters"
          />
        </div>
        <TaskStatusChip
          color={taskStatusObj?.color}
          background={taskStatusObj?.backgroundColor}
        >
          {taskStatusObj?.label}
        </TaskStatusChip>
      </div>
      <div className={'footer'}>
        {isMobileDeviceSm ? (
          <div>
            <div className={'footerLeft footerLeftSmall'}>
              <DueDate task={task} />
              <PriorityCmp task={task} />
            </div>
            <div className={'footerLeft footerLeftSmall'}>
              <CategoryChip task={task} hideSeprator={true} />
              <TaskAssignee task={task} />
            </div>
          </div>
        ) : (
          <div className={'footerLeft'}>
            <DueDate task={task} />
            <PriorityCmp task={task} />
            <CategoryChip task={task} />
            <TaskAssignee task={task} />
          </div>
        )}
        <div className={'footerRight'}>
          <Comments task={task} />
          <ActionDropdown task={task} />
        </div>
      </div>
    </TaskListTileCnt>
  );
}, areEqual);

export default TaskListTile;
