import DetailedBreakdown from '../widgets/detailedBreakdown';

function Details(props) {
  return (
    <div style={{ margin: '0 30px' }}>
      <DetailedBreakdown {...props} />
    </div>
  );
}

export default Details;
