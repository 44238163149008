export const defaultColDef = {
  lockVisible: true,
};

import {
  ActionColumnRenderer,
  LocationNameRenderer,
  ScheduleNameRenderer,
  ProgressRenderer,
} from './columnRenderers';

export const Columns = (
  props: {
    groupBy?: 'location' | 'schedule';
  } = {},
) => {
  const { groupBy } = props;
  const allCols = [
    {
      headerName: 'Completion on Time',
      field: 'completionOnTime',
      colId: 'completionOnTime',
      width: 150,
      minWidth: 100,
      suppressMenu: true,
      resizable: false,
      valueGetter: (params) => valueGetters?.completionOnTime(params),
      cellRenderer: (params) => cellRenderer?.completionOnTime(params),
    },
    {
      headerName: 'Completed Late',
      field: 'completedLate',
      colId: 'completedLate',
      width: 150,
      minWidth: 100,
      flex: 1,
      resizable: false,
      suppressMenu: true,
      valueGetter: (params) => valueGetters?.completedLate(params),
      cellRenderer: (params) => cellRenderer?.completedLate(params),
    },
    {
      headerName: 'Incomplete',
      field: 'incomplete',
      colId: 'incomplete',
      width: 150,
      minWidth: 100,
      flex: 1,
      resizable: false,
      suppressMenu: true,
      valueGetter: (params) => valueGetters?.incomplete(params),
      cellRenderer: (params) => cellRenderer?.incomplete(params),
    },
    {
      headerName: groupBy == 'location' ? 'Schedules' : 'Location',
      field: groupBy == 'location' ? 'schedules' : 'location', // location or schedule
      colId: groupBy == 'location' ? 'schedules' : 'location', // location or schedule
      sortable: false,
      width: 100,
      minWidth: 70,
      flex: 1,
      resizable: false,
      suppressMenu: true,
      valueGetter: (params) => valueGetters?.schedules(params, groupBy),
    },
    {
      headerName: '',
      field: '',
      colId: 'actionCol',
      suppressMovable: true,
      suppressMenu: true,
      width: 60,
      cellRenderer: (params) => cellRenderer.actionColumn(params, groupBy),
    },
  ];
  return allCols;
};
const valueGetters = {
  completionOnTime: (params) => {
    if (!params.data) {
      return '0';
    }
    const { LocationCompletedOnTimePercentage } = params.data;
    return LocationCompletedOnTimePercentage || '0';
  },
  completedLate: (params) => {
    if (!params.data) {
      return '0';
    }
    const { LocationCompletedLatePercentage } = params.data;
    return LocationCompletedLatePercentage || '0';
  },
  incomplete: (params) => {
    if (!params.data) {
      return '0';
    }
    const { LocationIncompletePercentage } = params.data;
    return LocationIncompletePercentage || '0';
  },
  schedules: (params, groupBy) => {
    if (!params.data) {
      return '-';
    }
    const { SchedulesCount, taskUniqueId, LocationsCount, LocationId } =
      params.data;
    if (groupBy === 'location') {
      return !taskUniqueId ? SchedulesCount || '-' : '';
    } else {
      return !LocationId ? LocationsCount || '-' : '';
    }
  },
  name: (params) => {
    if (!params.data) {
      return '-';
    }
    const { locationName, scheduleTitle } = params.data;
    return locationName || scheduleTitle;
  },
};

export const cellRenderer = {
  completionOnTime: (params) => {
    const { LocationCompletedOnTimePercentage, CompletedOnTimePercentage } =
      params.data;
    return (
      <ProgressRenderer
        value={
          LocationCompletedOnTimePercentage || CompletedOnTimePercentage || 0
        }
        params={params}
        color="#04B88D"
      />
    );
  },
  completedLate: (params) => {
    const { LocationCompletedLatePercentage, CompletedLatePercentage } =
      params.data;
    return (
      <ProgressRenderer
        value={LocationCompletedLatePercentage || CompletedLatePercentage || 0}
        params={params}
        color="#EF5350"
      />
    );
  },
  incomplete: (params) => {
    const { LocationIncompletePercentage, IncompletePercentage } = params.data;
    return (
      <ProgressRenderer
        value={LocationIncompletePercentage || IncompletePercentage || 0}
        params={params}
        color="#FB8C00"
      />
    );
  },
  // name is for location
  name: (params, groupBy) => {
    if (groupBy === 'location') {
      return <LocationNameRenderer params={params} />;
    } else {
      return <ScheduleNameRenderer params={params} />;
    }
  },
  // schedule Name is for schedule group by data
  actionColumn: (params, groupBy) => {
    return <ActionColumnRenderer params={params} groupBy={groupBy} />;
  },
};

export const TitleColumn = ({ groupBy }) => {
  const column = {
    headerName: groupBy == 'location' ? 'Location' : 'Schedules',
    field: groupBy, // location or schedule
    colId: groupBy, // location or schedule
    sortable: true,
    sortChildren: true,
    minWidth: 250,
    comparator: (a, b) => {
      return a?.toLowerCase().localeCompare(b?.toLowerCase(), undefined, {
        numeric: true,
        sensitivity: 'base',
      });
    },
    suppressMovable: true,
    suppressMenu: true,
    resizable: true,
    flex: 1,
    pinned: 'left',
    cellStyle: (params) => {
      return groupBy === 'location'
        ? params?.data.taskUniqueId && { marginLeft: 30 }
        : params.data.LocationId && { marginLeft: 30 };
    },
    valueGetter: (params) => valueGetters?.name(params),
    cellRenderer: (params) => cellRenderer.name(params, groupBy),
    cellRendererParams: {
      suppressCount: true,
      // isSearching: searchTerm,
    },
  };
  return column;
};
