import { useEffect, useMemo, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import CancelIcon from '@mui/icons-material/Cancel';
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import SelectItemsList from 'components/Dropdown/SelectItemsList/SelectItemsList';
import { fileTypesData } from 'pages/documents/constants';
import {
  StatusDropdownButton,
  StyledEndIconCnt,
  RemoveIconBtn,
  MainCnt,
  StyledSelectedText,
} from 'pages/documents/subHeader/fileTypesDropdown/style';

function FileTypes(props) {
  const { selectedOption, setSelectedOption, onChangeCallback } = props;
  return (
    <SelectItemsList
      searchFieldProps={{ autoFocus: false }}
      selectedOptions={selectedOption}
      controlled={true}
      checkbox={true}
      options={fileTypesData}
      restListItemProps={{
        style: {
          borderBottom: '1px solid gray',
        },
      }}
      checkboxProps={{ size: 'small' }}
      handleChangeCallback={(values) => {
        const previousAll = selectedOption?.find(
          (item) => item.value === 'all',
        );

        const currentAll = values?.find((item) => item.value === 'all');

        if (previousAll && !currentAll) {
          setSelectedOption([]);
          onChangeCallback?.([]);
          return;
        }

        const unselectAll = values?.[values?.length - 1]?.value != 'all';
        if (unselectAll) {
          values = values?.filter((item) => item.value !== 'all');
        }

        const isAll =
          values.find((item) => item.value === 'all') ||
          values?.length === fileTypesData?.length - 1;

        const newData = isAll ? fileTypesData : values;

        setSelectedOption(newData);
        onChangeCallback?.(newData);
      }}
    />
  );
}
function FileTypesDropdown(props) {
  const {
    popperProps = { style: {} },
    buttonProps = {},
    selected = null,
    children,
    dropdownProps,
    onChangeCallback,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState<any[]>(selected ?? []);

  useEffect(() => {
    setSelectedOption(selected ?? []);
  }, [selected]);
  const handleDropdownOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleClearOptions = (event) => {
    event.stopPropagation();
    setSelectedOption([]);
    onChangeCallback?.([]);
  };
  const selectedText = useMemo(() => {
    if (selectedOption && selectedOption.length === 1) {
      return selectedOption[0].label;
    } else if (selectedOption && selectedOption.length > 1) {
      const isAll = selectedOption.find((item) => item.value === 'all');
      return `${
        isAll ? selectedOption.length - 1 : selectedOption.length
      } Types`;
    } else {
      return 'Type: Show All';
    }
  }, [selectedOption]);
  return (
    <ClickAwayListener
      onClickAway={handleDropdownClose}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <MainCnt>
        <StatusDropdownButton
          variant="contained"
          buttonType="grayDropdown"
          onClick={handleDropdownOpen}
          isSelected={selectedOption.length > 0}
          endIcon={
            <StyledEndIconCnt>
              <KeyboardArrowDownIcon style={{ fontSize: 20 }} />
            </StyledEndIconCnt>
          }
        >
          <StyledSelectedText>{selectedText}</StyledSelectedText>
          {selectedOption?.length ? (
            <RemoveIconBtn
              className="remove-assignee"
              onClick={handleClearOptions}
            >
              <CancelIcon />
            </RemoveIconBtn>
          ) : null}
        </StatusDropdownButton>

        <CustomDropdown
          popperProps={{
            placement: 'bottom-start',
            content: (
              <FileTypes
                closeAction={handleDropdownClose}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                onChangeCallback={onChangeCallback}
                {...dropdownProps}
              />
            ),
            style: { ...popperProps.style, zIndex: 9999, width: 270 },
            open: !!anchorEl,
            anchorEl,
            ...popperProps,
          }}
          buttonProps={buttonProps}
          clickaway={false}
        >
          {children}
        </CustomDropdown>
      </MainCnt>
    </ClickAwayListener>
  );
}

export default FileTypesDropdown;
