import { useEffect, useMemo, useState } from 'react';

import ClickAwayListener from '@mui/base/ClickAwayListener';
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import SelectItemsList from 'components/Dropdown/SelectItemsList/SelectItemsList';
import { statusDropdownData } from 'helper/task/dropdownData';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  StatusDropdownButton,
  StyledEndIconCnt,
  StyledSelectedText,
  RemoveIconBtn,
} from './statusFilter.style';
import CancelIcon from '@mui/icons-material/Cancel';

function StatusList(props) {
  const { selectedOption, setSelectedOption, onChangeCallback } = props;
  return (
    <SelectItemsList
      searchFieldProps={{ autoFocus: false }}
      selectedOptions={selectedOption}
      controlled={true}
      checkbox={true}
      options={statusDropdownData}
      checkboxProps={{ size: 'small' }}
      handleChangeCallback={(values) => {
        setSelectedOption(values);
        onChangeCallback?.(values);
      }}
    />
  );
}
function StatusFilterDropdown(props) {
  const {
    popperProps = { style: {} },
    buttonProps = {},
    selected = null,
    children,
    dropdownProps,
    onChangeCallback,
    onClearCallback,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState<any[]>(selected ?? []);

  useEffect(() => {
    setSelectedOption(selected ?? []);
  }, [selected]);
  const handleDropdownOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleClearOptions = (event) => {
    event.stopPropagation();
    setSelectedOption([]);
    onClearCallback?.();
  };
  const selectedText = useMemo(() => {
    if (selectedOption && selectedOption.length === 1) {
      return selectedOption[0].label;
    } else if (selectedOption && selectedOption.length > 1) {
      return `${selectedOption.length} Statuses`;
    } else {
      return 'Status: All';
    }
  }, [selectedOption]);
  return (
    <ClickAwayListener
      onClickAway={handleDropdownClose}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <div>
        <StatusDropdownButton
          variant="contained"
          buttonType="grayDropdown"
          onClick={handleDropdownOpen}
          isSelected={selectedOption.length > 0}
          endIcon={
            <StyledEndIconCnt>
              {/*commented for later use*/}
              {/*{selected && (*/}
              {/*  <IconButton onClick={handleClearOptions}>*/}
              {/*    <CloseIcon />*/}
              {/*  </IconButton>*/}
              {/*)}*/}
              <KeyboardArrowDownIcon style={{ fontSize: 20 }} />
            </StyledEndIconCnt>
          }
        >
          <StyledSelectedText>{selectedText}</StyledSelectedText>
          {selectedOption?.length ? (
            <RemoveIconBtn
              className="remove-assignee"
              onClick={handleClearOptions}
            >
              <CancelIcon />
            </RemoveIconBtn>
          ) : null}
        </StatusDropdownButton>

        <CustomDropdown
          popperProps={{
            placement: 'bottom-start',
            content: (
              <StatusList
                closeAction={handleDropdownClose}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                onChangeCallback={onChangeCallback}
                {...dropdownProps}
              />
            ),
            style: { ...popperProps.style, zIndex: 9999, width: 270 },
            open: !!anchorEl,
            anchorEl,
            ...popperProps,
          }}
          buttonProps={buttonProps}
          clickaway={false}
        >
          {children}
        </CustomDropdown>
      </div>
    </ClickAwayListener>
  );
}

export default StatusFilterDropdown;
