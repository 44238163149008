import SearchInput from 'components/Form/TextField/SearchField.cmp';
import FiltersDropdown from 'components/TaskAdvanceFilters/FiltersDropdown';
import {
  HeaderCnt,
  ResponsiveHeaderCnt,
  TaskActionDropdownCnt,
} from './taskListHeader.style';
import { HasPermission } from 'components/HasPermission';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import useTaskFilters from 'utils/CustomHooks/useTaskFilters/useTaskFilters';
import { debounce } from 'lodash';
import { useMemo, useState } from 'react';
import TaskActionsDropdown from './ActionDropdown';
import useResponsive from 'utils/CustomHooks/useResponsive';
import { dateRangePickerSideMenuCommon } from 'utils/datePicker';
import { Stack, Typography } from '@mui/material';
import CalendarInputIcon from 'components/Icons/calendarInputIcon';
import DateRangePickerDropdown from 'components/DateRangePicker/Dropdown';
import SearchIcon from '@mui/icons-material/Search';
import SelectTaskViewDropdown from 'pages/task/TaskListView/SelectTaskViewDropdown';
import useTasksConfig from 'utils/CustomHooks/useTasksTabs';
import { getUserHotelTz } from 'store/selectors/auth';
import VisibilityToggle from '../TaskListHeader/VisibilityToggle';

interface TaskListHeaderPropTypes {
  disabledAdvanceFilters?: string[];
  forcedAdvanceFilters?: any[];
  isMainView: boolean;
  dateFilter: any;
  setDateFilter: any;
  formatDateFilter: any;
  currentTabInTasksView: string;
  setSearchedText: any;
  showViewTypes?: boolean;
  showVisibilityToggle?: boolean;
}

function TaskGridListHeader(props: TaskListHeaderPropTypes) {
  const {
    disabledAdvanceFilters,
    forcedAdvanceFilters,
    isMainView,
    dateFilter,
    setDateFilter,
    formatDateFilter,
    currentTabInTasksView,
    setSearchedText,
    showViewTypes = true,
    showVisibilityToggle = true,
  } = props;
  const { PERMISSIONS } = useCustomPermission();
  const { handleSetAdvanceFiltersCommon } = useTaskFilters();
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const { updateTabConfigState, updateTasksConfig } = useTasksConfig();
  const allTabsfilterData: any = useSelector(selectors.getTaskListFilters);

  const tz = useSelector(getUserHotelTz);

  const { isMobileDeviceSm } = useResponsive();

  const handleInputChange = debounce(async (e) => {
    const value = e.target.value.trim();
    setSearchTerm(value);
    setSearchedText(value);
  }, 300);

  const hiddenFilters = useMemo(() => {
    let hidden = 'startDate dueDate createdDate';
    if (disabledAdvanceFilters) {
      hidden = hidden + ' ' + disabledAdvanceFilters.join(' ');
    }
    return hidden;
  }, [disabledAdvanceFilters]);

  const defaultLast7DaysFilter = useMemo(() => {
    if (tz) {
      const range = dateRangePickerSideMenuCommon(tz)[4];
      return {
        from: range?.range?.from,
        to: range?.range?.to,
        selectedDateOption: range?.label,
      };
    }
    return {};
  }, [tz]);

  const filterData = useMemo(() => {
    return allTabsfilterData?.tabs?.[currentTabInTasksView]?.filters || {};
  }, [currentTabInTasksView, allTabsfilterData]);

  const dateRangeSelected = useMemo(() => {
    return (
      allTabsfilterData?.tabs?.[currentTabInTasksView]?.dateRange ??
      defaultLast7DaysFilter
    );
  }, [currentTabInTasksView, allTabsfilterData]);

  const updateDateRangeHandler = (date) => {
    const updatedData = updateTabConfigState(
      allTabsfilterData,
      currentTabInTasksView,
      {
        dateRange: date,
      },
    );

    updateTasksConfig(updatedData);
    setDateFilter(date);

    handleSetAdvanceFiltersCommon(
      filterData,
      [...(forcedAdvanceFilters ?? []), formatDateFilter(date)],
      {
        skipFilterSave: true,
      },
      currentTabInTasksView,
    );
  };

  return isMobileDeviceSm ? (
    <>
      <ResponsiveHeaderCnt>
        <div className={'lefCnt'}>
          <SearchInput
            fieldProps={{
              onChange: handleInputChange,
              autoFocus: true,
              defaultValue: searchTerm,
            }}
            sx={{
              width: 250,
              flex: 1,
              '& .MuiInputBase-root': {
                gap: '6px',
                height: '38px',
                borderRadius: '8px',
                '& input': {
                  padding: '0px',
                },
              },
            }}
          />

          <FiltersDropdown
            selectedFilters={filterData}
            onChange={(filters) => {
              handleSetAdvanceFiltersCommon(
                filters,
                [...(forcedAdvanceFilters ?? []), formatDateFilter(dateFilter)],
                {
                  skipFilterSave: false,
                },
                currentTabInTasksView,
              );
            }}
            handleClearFilters={() => {
              handleSetAdvanceFiltersCommon(
                null,
                [...(forcedAdvanceFilters ?? []), formatDateFilter(dateFilter)],
                { skipFilterSave: false },
                currentTabInTasksView,
              );
            }}
            hideFilters={hiddenFilters}
          />
        </div>
        <div className={'rightCnt'}>
          <DateRangePickerDropdown
            setRange={updateDateRangeHandler}
            dateRange={dateRangeSelected}
            selectedDateLabel={dateFilter?.selectedDateOption}
            popperProps={{
              placement: 'bottom-start',
            }}
            buttonRenderer={
              <Stack
                p="9px 12px"
                direction={'row'}
                alignItems={'center'}
                gap="8px"
                bgcolor={'#F2F5F7'}
                borderRadius={'8px'}
                sx={{
                  cursor: 'pointer',
                }}
              >
                <CalendarInputIcon sx={{ fill: 'none', fontSize: '16px' }} />
                <Stack direction={'row'} alignItems={'center'} gap="4px">
                  <Typography fontSize={'13px'} fontWeight={'400'}>
                    Date:
                  </Typography>
                  <Typography fontSize={'13px'} fontWeight={'600'}>
                    {dateFilter?.selectedDateOption}
                  </Typography>
                </Stack>
              </Stack>
            }
            contentProps={{
              displaySideOptions: !isMobileDeviceSm,
              allowInputs: !isMobileDeviceSm,
              numberOfMonths: isMobileDeviceSm ? 1 : 2,
              sideMenu: dateRangePickerSideMenuCommon,
            }}
          />
          <VisibilityToggle
            forcedAdvanceFilters={forcedAdvanceFilters}
            isMainView={isMainView}
            currentTabInTasksView={currentTabInTasksView}
            formatDateFilter={formatDateFilter}
            dateFilter={dateFilter}
          />
          <SelectTaskViewDropdown
            currentTabInTasksView={currentTabInTasksView}
          />
        </div>
      </ResponsiveHeaderCnt>
      <TaskActionDropdownCnt>
        {isMainView && (
          <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_TASKS]}>
            <TaskActionsDropdown />
          </HasPermission>
        )}
      </TaskActionDropdownCnt>
    </>
  ) : (
    <HeaderCnt>
      <div className={'lefCnt'}>
        <DateRangePickerDropdown
          setRange={updateDateRangeHandler}
          dateRange={dateFilter}
          selectedDateLabel={dateFilter?.selectedDateOption}
          popperProps={{
            placement: 'bottom-start',
          }}
          buttonRenderer={
            <Stack
              p="9px 12px"
              direction={'row'}
              alignItems={'center'}
              gap="8px"
              bgcolor={'#F2F5F7'}
              borderRadius={'8px'}
              sx={{
                cursor: 'pointer',
              }}
            >
              <CalendarInputIcon sx={{ fill: 'none', fontSize: '16px' }} />
              <Stack direction={'row'} alignItems={'center'} gap="4px">
                <Typography fontSize={'13px'} fontWeight={'400'}>
                  Date:
                </Typography>
                <Typography fontSize={'13px'} fontWeight={'600'}>
                  {dateFilter?.selectedDateOption}
                </Typography>
              </Stack>
            </Stack>
          }
          contentProps={{
            displaySideOptions: !isMobileDeviceSm,
            allowInputs: !isMobileDeviceSm,
            numberOfMonths: isMobileDeviceSm ? 1 : 2,
            sideMenu: dateRangePickerSideMenuCommon,
          }}
        />
        {!searchVisible && (
          <Stack
            p="6px 12px"
            borderRadius={'8px'}
            border="1px solid #E0E0E0"
            direction={'row'}
            gap="6px"
            alignItems={'center'}
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => setSearchVisible(true)}
          >
            <SearchIcon />
            <Typography
              maxWidth={'190px'}
              whiteSpace={'pre'}
              overflow={'hidden'}
              textOverflow={'ellipsis'}
              color="#212121"
              fontSize={'13px'}
              fontWeight={'400'}
            >
              {searchTerm || 'Search'}
            </Typography>
          </Stack>
        )}
        {searchVisible && (
          <SearchInput
            fieldProps={{
              onChange: handleInputChange,
              autoFocus: true,
              defaultValue: searchTerm,
            }}
            sx={{
              width: 250,

              '& .MuiInputBase-root': {
                gap: '6px',
                height: '38px',
                borderRadius: '8px',
                '& input': {
                  padding: '0px',
                },
              },
            }}
            onBlur={() => setSearchVisible(false)}
          />
        )}
        <FiltersDropdown
          selectedFilters={filterData}
          onChange={(filters) => {
            handleSetAdvanceFiltersCommon(
              filters,
              [...(forcedAdvanceFilters ?? []), formatDateFilter(dateFilter)],
              {
                skipFilterSave: false,
              },
              currentTabInTasksView,
            );
          }}
          handleClearFilters={() => {
            handleSetAdvanceFiltersCommon(
              null,
              [...(forcedAdvanceFilters ?? []), formatDateFilter(dateFilter)],
              { skipFilterSave: false },
              currentTabInTasksView,
            );
          }}
          hideFilters={hiddenFilters}
        />
      </div>
      <div className={'rightCnt'}>
        {showVisibilityToggle && (
          <VisibilityToggle
            forcedAdvanceFilters={forcedAdvanceFilters}
            isMainView={isMainView}
            currentTabInTasksView={currentTabInTasksView}
            formatDateFilter={formatDateFilter}
            dateFilter={dateFilter}
          />
        )}

        {showViewTypes && (
          <SelectTaskViewDropdown
            currentTabInTasksView={currentTabInTasksView}
          />
        )}

        {isMainView && (
          <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_TASKS]}>
            <TaskActionsDropdown />
          </HasPermission>
        )}
      </div>
    </HeaderCnt>
  );
}

export default TaskGridListHeader;
