export const THRESHOLD = {
  minW: 5,
  maxW: 12,
  minH: 8,
  maxH: 15,
};

export const SCHEDULED_REPORTS_THRESHOLD = {
  minW: 6,
  maxW: 12,
  minH: 8,
  maxH: 8,
};

export const NUMBER_THRESHOLD = {
  minW: 3,
  maxW: 6,
  minH: 5,
  maxH: 6,
};

export const INITIAL_LAYOUT_DASHBOARD = [
  {
    x: 0,
    y: 0,
    w: 6,
    h: 8,
    i: '.$usersReport',
    ...THRESHOLD,
  },
  {
    x: 6,
    y: 0,
    w: 6,
    h: 8,
    i: '.$checkListReport',
    ...THRESHOLD,
  },
  {
    x: 0,
    y: 6,
    w: 12,
    h: 8,
    i: '.$inProgressSubmissionsReport',
    ...THRESHOLD,
  },
  {
    x: 0,
    y: 8,
    w: 12,
    h: 10,
    i: '.$tasksDueToday',
    ...THRESHOLD,
  },
  {
    x: 0,
    y: 18,
    w: 12,
    h: 10,
    i: '.$overDueTask',
    ...THRESHOLD,
  },
  {
    x: 0,
    y: 28,
    w: 12,
    h: 8,
    i: '.$myRequestsReport',
    ...THRESHOLD,
  },
];

export const INITIAL_LAYOUT_DASHBOARD_REQUEST = [
  {
    x: 0,
    y: 0,
    w: 12,
    h: 8,
    i: '.$myRequestsReport',
    ...THRESHOLD,
  },
];

export const INTIAL_LAYOUT_SCHEDULED_WORK_REPORT = [
  {
    x: 0,
    y: 0,
    w: 6,
    h: 10,
    i: '.$scheduleCompletionOverview',
    ...SCHEDULED_REPORTS_THRESHOLD,
  },
  {
    x: 6,
    y: 0,
    w: 6,
    h: 10,
    i: '.$scheduledWorkByStatus',
    ...SCHEDULED_REPORTS_THRESHOLD,
  },
  {
    x: 0,
    y: 12,
    w: 12,
    h: 12,
    i: '.$scheduleCompletionByLocation',
    ...SCHEDULED_REPORTS_THRESHOLD,
  },
  {
    x: 0,
    y: 24,
    w: 12,
    h: 12,
    i: '.$onTimeVsLateCompletionByLocation',
    ...SCHEDULED_REPORTS_THRESHOLD,
  },
  {
    x: 0,
    y: 36,
    w: 12,
    h: 12,
    i: '.$schedulesCompletionStats',
    ...SCHEDULED_REPORTS_THRESHOLD,
  },
  {
    x: 0,
    y: 48,
    w: 12,
    h: 12,
    i: '.$correctiveScheduledTasks',
    ...SCHEDULED_REPORTS_THRESHOLD,
  },
];
export const INTIAL_LAYOUT_SCHEDULED_WORK_DETAIL_BREAKDOWN_REPORT = [
  {
    x: 0,
    y: 12,
    w: 12,
    h: 12,
    i: '.$scheduleCompletionByLocation',
    ...SCHEDULED_REPORTS_THRESHOLD,
  },
];

export const INITIAL_LAYOUT_TASK_SUMMARY_REPORT = [
  {
    x: 0,
    y: 0,
    w: 12,
    h: 12,
    i: '.$taskListSummary',
    ...THRESHOLD,
  },
  {
    x: 0,
    y: 0,
    w: 6,
    h: 12,
    i: '.$tasksByAssignee',
    ...THRESHOLD,
  },
  {
    x: 6,
    y: 0,
    w: 6,
    h: 12,
    i: '.$tasksByCategory',
    ...THRESHOLD,
  },
];
export const INITIAL_LAYOUT_TASK_COMPLETED_BY_REPORT = [
  {
    x: 0,
    y: 0,
    w: 6,
    h: 8,
    i: '.$tasksCompletedByTeamMember',
    ...THRESHOLD,
  },
  {
    x: 6,
    y: 0,
    w: 6,
    h: 8,
    i: '.$tasksCompletedByEmployee',
    ...THRESHOLD,
  },
];

export const INITIAL_LAYOUT_TEMPLATE_SUBMISSIONS_REPORT = [
  {
    x: 0,
    y: 0,
    w: 12,
    h: 12,
    i: '.$templateSubmissionsList',
    ...THRESHOLD,
  },
];

export const INITIAL_LAYOUT_EMPLOYEE_PRODUCTIVITY_REPORT = [
  {
    x: 0,
    y: 0,
    w: 6,
    h: 12,
    i: '.$tasksByEmployee',
    ...THRESHOLD,
  },
  {
    x: 6,
    y: 0,
    w: 6,
    h: 12,
    i: '.$tasksByTeam',
    ...THRESHOLD,
  },
  {
    x: 0,
    y: 12,
    w: 12,
    h: 12,
    i: '.$taskListSummary',
    ...THRESHOLD,
  },
];
export const INITIAL_DYNAMIC_LAYOUT = [
  {
    x: 0,
    y: 0,
    w: 12,
    h: 12,
    i: '.$barChart',
    ...THRESHOLD,
  },
  {
    x: 0,
    y: 12,
    w: 12,
    h: 12,
    i: '.$pieChart',
    ...THRESHOLD,
  },
];
export const mergeLayouts = (layout, initialLayout) => {
  const ids = new Set(initialLayout.map((d) => d.i));
  return [...initialLayout, ...layout.filter((d) => !ids.has(d.i))];
};
