export const boardColumns = [
  { title: 'Open', id: 'Open', color: '#3B8EF0', backgroundColor: '#F0F8FB' },
  {
    title: 'In Progress',
    id: 'In Progress',
    color: '#F58500',
    backgroundColor: '#FDF7F0',
  },
  {
    title: 'On Hold',
    id: 'On Hold',
    color: '#64636D',
    backgroundColor: '#F6F6F8',
  },
  {
    title: 'Completed',
    id: 'Completed',
    color: '#04B86C',
    backgroundColor: '#EEFDEF',
  },
  {
    title: 'Missed',
    id: 'Missed',
    color: '#D32F2F',
    backgroundColor: '#FCF1F1',
  },
];

interface Task {
  id: string;
  title: string;
  taskStatus: string;
}

interface Column {
  id: number;
  title: string;
  taskIds: string[];
}
export interface KanbanData {
  columns: any; // Columns are indexed by their title
  tasksById: any; // Tasks are indexed by their ID
  columnOrder: string[]; // Array of column titles for order
}

export const transformTasksToKanban = (
  tasks: Task[],
  boardColumns,
  config: any = null,
): KanbanData => {
  const kanbanData: KanbanData = {
    columns: {},
    tasksById: {},
    columnOrder: [],
  };

  const hasColoumnOrderConfig = config && config.columnOrder;
  // Initialize columns with empty task ID arrays
  if (hasColoumnOrderConfig) kanbanData.columnOrder = config.columnOrder;
  boardColumns.forEach((column) => {
    kanbanData.columns[column.id] = {
      ...column,
      taskIds: [],
    };
    if (!hasColoumnOrderConfig) kanbanData.columnOrder.push(column.id);
  });

  // Map tasks by ID and add task IDs to respective columns
  tasks.forEach((task) => {
    kanbanData.tasksById[task.id] = task;
    const taskStatusKey = task.taskStatus;
    kanbanData.columns[taskStatusKey].taskIds.push(task.id);
  });

  return kanbanData;
};
