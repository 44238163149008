import { useCallback, useEffect, useState } from 'react';
import { TaskSummaryContext } from 'pages/ReportingV2/Reports/TaskOverview/context/taskSummaryContext';
import {
  INTIAL_LAYOUT_SCHEDULED_WORK_REPORT,
  SCHEDULED_REPORTS_THRESHOLD,
} from 'components/CustomTilesGrid/utils';

import useReports from 'pages/ReportingV2/Reports/useReports';
import { REPORTING_CONFIG_CONSTANTS } from 'pages/ReportingV2/Reports/constants';

import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { transformLayouts } from 'utils/globalFunction';
import ScheduledWorkHeader from './header/header';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { PERMISSIONS } from 'utils/constants';
import { TabsCnt } from 'components/TaskDetailDialog/TaskDetail.style';
import CTabs from 'components/CTabs';
import Summary from './summary';
import Details from './detail';

function ScheduledWork() {
  const { workspaceId } = useWorkspaceHook();
  const {
    defaultProps,
    state,
    updateState,
    clearAllFilters,
    updateGridSettings,
  } = useReports(REPORTING_CONFIG_CONSTANTS.SCHEDULED_WORK_REPORT, false);

  const transformedLayouts = useCallback(() => {
    return state?.gridSettings
      ? transformLayouts(
          state?.gridSettings,
          INTIAL_LAYOUT_SCHEDULED_WORK_REPORT,
          SCHEDULED_REPORTS_THRESHOLD,
        )
      : null;
  }, [state?.gridSettings]);
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { hasPermission } = useCustomPermission();

  useEffect(() => {
    if (!hasPermission(PERMISSIONS.CAN_VIEW_REPORTING)) {
      navigateWithWorkspaceUrl('/dashboard');
    }
  }, []);

  const [tab, setTab] = useState('summary');

  const tabs = [
    {
      label: 'Summary',
      content: () =>
        tab === 'summary' ? <Summary {...defaultProps} /> : <></>,
      path: 'summary',
    },
    {
      label: 'Detailed Breakdown',
      content: () =>
        tab === 'detailed-breakdown' ? <Details {...defaultProps} /> : <></>,
      path: 'detailed-breakdown',
    },
  ];
  const getActiveTab = useCallback(
    (activeTab) => tabs.findIndex((t) => t.path === activeTab),
    [tab],
  );

  const onTabChangeCb = (activeTab: number) => {
    const path = tabs[activeTab].path;
    setTab(path);
  };

  return (
    <TaskSummaryContext.Provider
      value={{ state, updateState, clearAllFilters }}
    >
      <div>
        <ScheduledWorkHeader />
        <TabsCnt>
          <CTabs
            data={tabs}
            onTabChangeCb={onTabChangeCb}
            activeTab={getActiveTab(tab)}
            variant="scrollable"
          />
        </TabsCnt>
      </div>
    </TaskSummaryContext.Provider>
  );
}

export default ScheduledWork;
