import { Stack, Typography } from '@mui/material';
import { AIFooterCompletedGenerateBox } from './style';
import CustomButton from 'components/Button/CustomButton';
import { useDispatch } from 'react-redux';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import DoneIcon from '@mui/icons-material/Done';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from 'components/Icons/editIcon';
import useAITemplate from 'utils/CustomHooks/useAITemplate';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { DOCUMENT_BASED_PROMPT, TEXT_BASED_PROMPT } from 'utils/constants';

function CompletedAIGeneration(props) {
  const {
    handleGoNextMetadata,
    checklist,
    folderId,
    setChecklistItems,
    setChecklist,
    getOrCreateAndSetChecklist,
  } = props;
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const dispatch = useDispatch();
  const { getPayloadForAI } = useAITemplate();

  const editPromptHandler = async () => {
    // Show Prefilled Modal Again
    const metadata: any = await getPayloadForAI();

    if (metadata?.type === TEXT_BASED_PROMPT) {
      dispatch(
        setDialog({
          open: true,
          dialogId: DIALOGS?.CREATE_TEMPLATE_WITH_AI,
          data: {
            metadata,
            onSubmitHandler: () => {
              setChecklist(null);
              setChecklistItems(null);
              getOrCreateAndSetChecklist();
            },
          },
        }),
      );
    }

    if (metadata?.type === DOCUMENT_BASED_PROMPT) {
      dispatch(
        setDialog({
          open: true,
          dialogId: DIALOGS?.CONVERT_DOCUMENT_WITH_AI,
          data: {
            metadata,
          },
        }),
      );
    }
  };

  const markAsDoneHandler = () => {
    handleGoNextMetadata({}, true, () => {
      navigateWithWorkspaceUrl(
        `/checklist/${checklist.id}${folderId ? '/folder/' + folderId : ''}`,
        '',
        { replace: true },
      );
    });
  };

  return (
    <AIFooterCompletedGenerateBox direction="row" alignItems="center">
      <Stack
        direction="row"
        justifyContent="space-between"
        width="100%"
        style={{ padding: 15 }}
      >
        <Stack
          direction="row"
          gap="5px"
          alignItems="center"
          sx={{ '& svg': { height: '40px', width: '40px' } }}
        >
          <CheckCircleIcon
            style={{ color: 'rgba(76, 175, 80, 1)', height: 33, width: 33 }}
          />
          <Stack direction="column" gap="2px">
            <Typography className="title">Template generated!</Typography>
            <Typography className="statement">
              Carefully review and make any necessary changes before publishing.
            </Typography>
          </Stack>
        </Stack>

        <Stack direction="row" gap="10px">
          <CustomButton
            variant={'outlined'}
            color={'primary'}
            startIcon={<EditIcon />}
            onClick={editPromptHandler}
          >
            Edit Prompt
          </CustomButton>
          <CustomButton
            variant={'contained'}
            color={'primary'}
            startIcon={<DoneIcon />}
            onClick={markAsDoneHandler}
          >
            Done
          </CustomButton>
        </Stack>
      </Stack>
    </AIFooterCompletedGenerateBox>
  );
}

export default CompletedAIGeneration;
