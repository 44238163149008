/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAuthAssetsState } from 'store/selectors/assets';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { appRoutes } from 'utils/globalVariable';
import AssetsBulkAction from 'pages/Assets/BulkActions/AssetsBulkAction';

import { Box, Grid, Stack } from '@mui/material';
import CTypography from 'components/CTypography';
import ColorsDropdown from 'components/Dropdown/ColorsDropdown';
import useUpdateAsset from 'utils/CustomHooks/useUpdateAsset';
import ChildItemIcon from 'components/Icons/childItemIcon';

import LocationIcon from 'components/Icons/locationIcon';
import NoRecordsFound from 'components/ListView/EmptyRecords/noRecordsFound.cmp';
import ImageIcon from 'components/Icons/imageIcon';
import AssigneeDropdown from 'components/Dropdown/AssigneeDropdown/dropdown/dropdown';
import NoAddedRecords from '../EmptyRecords/NoAddedRecords.cmp';
import {
  BoxContainer,
  LocationContainer,
  SubContent,
} from './assetsGrid.styles';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import useResponsive from 'utils/CustomHooks/useResponsive';
import { getMenuState } from 'store/selectors/common';

export const AssetsGrid: FC<any> = ({ assetsList, isLoading, isEmptyData }) => {
  const { PERMISSIONS, hasPermission } = useCustomPermission();
  const hasAdminAccess = hasPermission(PERMISSIONS.CAN_MANAGE_ADMINS);
  const originalAssetsList = useSelector(getAuthAssetsState);
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { isMobileDeviceMd } = useResponsive();
  const [selectedAssets, setSelectedAssets] = useState([]);
  const { updateAsset } = useUpdateAsset();
  const sidebarOpen = useSelector(getMenuState);

  const resetSelection = () => {
    globalThis.AssetsGrid.api.deselectAll();
  };

  const handleUpdateAssetColor = (item, color) => {
    updateAsset({ ...item, avatar: { color } });
  };

  const getFirstImage = (attachments) => {
    return attachments?.filter((url) => /\.(jpe?g|png|gif)$/i?.test(url))?.[0];
  };

  const redirectToDetails = (data) => {
    const urlToNavigate = data?.ParentId
      ? `/${data?.ParentId}/sa/${data?.id}`
      : `/${data?.id}`;
    navigateWithWorkspaceUrl(appRoutes?.assets + urlToNavigate);
  };

  return (
    <>
      {selectedAssets?.length > 0 && (
        <AssetsBulkAction
          selected={selectedAssets}
          onClearSelection={resetSelection}
        />
      )}

      {!isLoading &&
        originalAssetsList?.length > 0 &&
        assetsList?.length === 0 && (
          <Stack
            justifyContent="center"
            alignItems="center"
            direction="column"
            marginTop="50px"
          >
            <NoRecordsFound />
          </Stack>
        )}

      {!isLoading && (originalAssetsList?.length === 0 || isEmptyData) && (
        <Stack
          justifyContent="center"
          alignItems="center"
          direction="column"
          height="calc(100vh - 300px)"
        >
          <NoAddedRecords />
        </Stack>
      )}

      <Grid
        container
        spacing={2}
        style={{
          ...(!isMobileDeviceMd && {
            width: `calc(100vw - ${sidebarOpen ? '310' : '130'}px)`,
          }),
        }}
      >
        {assetsList?.map((item) => {
          const imgToShow = getFirstImage(item?.attachments);
          return (
            <Grid item key={item?.id} xs={12} sm={6} md={4} lg={3}>
              <BoxContainer onClick={() => redirectToDetails(item)}>
                <div className="imgContainer">
                  {imgToShow ? (
                    <img src={imgToShow} style={{ width: '100%' }} />
                  ) : (
                    <Stack
                      bgcolor={'#ECEFF1'}
                      height={'100px'}
                      width="100%"
                      alignItems={'center'}
                      justifyContent={'center'}
                    >
                      <ImageIcon style={{ height: 30, width: 30 }} />
                    </Stack>
                  )}
                </div>
                <div className="contentContainer">
                  <div className="titleContainer">
                    {item?.ParentId ? (
                      <ChildItemIcon className="childIcon" />
                    ) : (
                      <Box
                        onClick={(e) => e.stopPropagation()}
                        className="colorIcon"
                      >
                        <ColorsDropdown
                          selected={item?.avatar?.color}
                          changeHandler={(color) =>
                            handleUpdateAssetColor(item, color)
                          }
                          iconStyles={{
                            width: 10,
                            height: 10,
                            borderRadius: '2px',
                            border: 'none',
                          }}
                        />
                      </Box>
                    )}

                    <CTypography className="title">{item?.name}</CTypography>
                  </div>

                  <SubContent>
                    {item?.SubAssets?.length > 0 && (
                      <div className="subAssetsCount">
                        <ChildItemIcon className="childIcon" />
                        <CTypography className="count">
                          {item?.SubAssets?.length}
                        </CTypography>
                      </div>
                    )}

                    <AssigneeDropdown
                      handleChangeCallback={(data: any) => {
                        updateAsset({
                          ...item,
                          LocationId:
                            data?.selectedList?.locations?.[
                              data?.selectedList?.locations?.length - 1
                            ] || null,
                        });
                      }}
                      buttonRendererProps={{
                        width: '100%',
                      }}
                      buttonRenderer={
                        <LocationContainer addLocation={!item?.Location?.name}>
                          <LocationIcon className="icon" />
                          <CTypography
                            className="name"
                            style={{
                              color: item?.Location?.name
                                ? '#212121'
                                : '#6868FE',
                            }}
                          >
                            {item?.Location?.name
                              ? item?.Location?.name
                              : 'Add to Location'}
                          </CTypography>
                        </LocationContainer>
                      }
                      showTabs={false}
                      listProps={{
                        locations: {
                          showInlineSelection: false,
                          isSearchable: true,
                          isMulti: false,
                          hideActions: false,
                          selected: item?.LocationId ? [item?.LocationId] : [],
                          addLocationByTag: hasAdminAccess,
                        },
                      }}
                      displayOptions={{
                        locations: true,
                      }}
                      popperProps={{
                        disablePortal: true,
                        onClick: (e) => e?.stopPropagation(),
                      }}
                      allSelected={false}
                    />
                  </SubContent>
                </div>
              </BoxContainer>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
