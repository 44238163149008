import { useDispatch } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import ActionDropdown from 'components/Dropdown/ActionDropdown/ActionDropdown';

import { setDialog } from 'store/actions/dialogActions';
import DialogIds from 'utils/dialogIds';
import { useAssigneesUtils } from 'utils/CustomHooks/useAssigneesUtil';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CreateTaskIcon from 'components/Icons/createTaskIcon';
import TaskTemplateIcon from 'components/Icons/taskTemplateIcon';
import { Box } from '@mui/material';
import { useMemo } from 'react';
import useResponsive from 'utils/CustomHooks/useResponsive';

const actionDropdownOptions = [
  {
    label: 'New Task',
    id: 'new_task',
    icon: <CreateTaskIcon />,
    iconAlign: 'start',
  },
  {
    label: 'From Template',
    id: 'from_template',
    icon: (
      <TaskTemplateIcon
        style={{ fill: 'none', fontSize: 19, marginRight: -4 }}
      />
    ),
    iconAlign: 'start',
  },
];

const TaskActionsDropdown = () => {
  const { isMobileDeviceSm } = useResponsive();
  const dispatch = useDispatch();
  const { addNewAssignees } = useAssigneesUtils();

  const transformTemplateToTask = (template) => {
    const {
      title,
      priority,
      LocationId,
      ChecklistId,
      description,
      AssetId,
      attachment,
      assignees,
      ServiceTypeId,
      notification,
    } = template;

    const taskInputs = {
      ...(title && { title }),
      ...(priority && { priority }),
      ...(LocationId && { locationIds: [LocationId] }),
      ...(description && { description }),
      ...(AssetId && { AssetId }),
      ...(ChecklistId && {
        checklistId: ChecklistId,
      }),
      ...(ServiceTypeId && {
        ServiceId: ServiceTypeId,
        serviceTypeId: ServiceTypeId,
      }),
      ...(assignees?.length > 0 && {
        assignees,
        TaskAssignees: addNewAssignees(assignees),
      }),
      ...(attachment && { attachment }),
      ...(notification && { notification }),
    };

    return taskInputs;
  };

  const templateSelectedHandler = (data) => {
    const taskData = transformTemplateToTask(data?.template);
    dispatch(
      setDialog({
        open: true,
        dialogId: 'createEditTask',
        data: {
          dialogState: {
            viewLess: false,
            taskTemplate: {
              enable: true,
              id: data?.taskTemplateId,
            },
          },
          taskData,
        },
      }),
    );
  };

  const handleOptionSelect = (option) => {
    switch (option.id) {
      case 'new_task':
        dispatch(
          setDialog({
            open: true,
            dialogId: 'createEditTask',
            data: {
              dialogState: {
                taskTemplate: {
                  enable: true,
                },
              },
            },
          }),
        );
        break;
      case 'from_template':
        dispatch(
          setDialog({
            dialogId: DialogIds.ATTACH_TASK_TEMPLATE_MODAL,
            open: true,
            data: {
              dialogState: {
                hideWhenTaskCreate: true,
              },
              onConfirm: templateSelectedHandler,
            },
          }),
        );
        break;
    }
  };

  return (
    <ActionDropdown
      options={actionDropdownOptions}
      handleOptionSelect={handleOptionSelect}
      iconButton={isMobileDeviceSm}
      header={
        <Box
          style={{
            fontWeight: 600,
            fontSize: 13,
            lineHeight: '22px',
            letterSpacing: '0.17px',
            color: '#616161',
            margin: '10px 15px 0px',
          }}
        >
          Create
        </Box>
      }
      buttonProps={{
        startIcon: <AddIcon />,
        className: 'actionDropdownBtn',
        endIcon: (
          <div
            style={{
              borderLeft: '1px solid #4E48FA',
              fontSize: 0,
              marginLeft: 2,
            }}
          >
            <KeyboardArrowDownIcon style={{ fontSize: 20 }} />
          </div>
        ),
        variant: 'contained',
        style: {
          padding: isMobileDeviceSm ? 0 : '5px 8px 5px 13px',
          marginLeft: 12,
        },
      }}
      popperProps={{
        placement: 'bottom-start',
        sx: {
          zIndex: 1301,
        },
      }}
      paperProps={{
        style: {
          minWidth: 120,
        },
      }}
    >
      {isMobileDeviceSm ? <AddIcon /> : 'Add'}
    </ActionDropdown>
  );
};

export default TaskActionsDropdown;
