import FiltersDropdown from 'components/TaskAdvanceFilters/FiltersDropdown';
import { WidgetFilterCnt } from 'pages/Dashboards/Widgets/WidgetAdvanceFilter/widgetAdvanceFilter.style';
import { formatFilters } from 'utils/Task/filters';
import {
  checkEmptyFilters,
  validateAdvancedFilter,
} from 'utils/CustomHooks/useTaskFilters/utils';
import { useEditWidget } from 'components/AllDialogs/Dashboards/editWidget/hooks';
import { useParams } from 'react-router-dom';
import { IUpdateWidget, IWidget } from 'pages/Dashboards/interfaces';
import { useWidget } from 'pages/Dashboards/hooks/useWidgets';
import SubmissionsFiltersDropdown from 'pages/Dashboards/Widgets/SubmissionFiltersDropdown/FiltersDropdown';
import { cloneDeep, isArray } from 'lodash';
import { DASHBOARDS_CONST } from 'pages/Dashboards/constants';
import { hideSubmissionsFilters } from './utils';

interface IWidgetAdvanceFilterProps {
  widgetId: string;
  globalFilters?: any;
  hideFilters?: any;
  isCompactAction?: boolean;
}

// Widget Local Filters.
function WidgetAdvanceFilter({
  widgetId,
  globalFilters,
  hideFilters = null,
  isCompactAction = false,
}: IWidgetAdvanceFilterProps) {
  const { editWidgetQuery } = useEditWidget();
  const params = useParams();
  const { dashboardId = '' } = params;
  const { getWidgetQuery } = useWidget({
    apiParams: {
      widgetId,
      dashboardId,
      data: { filters: globalFilters },
    },
  });
  const { queryWidgetById } = useWidget({
    apiParams: { dashboardId, widgetId },
  });
  const data: IWidget = queryWidgetById()?.widget;

  const updateWidget = (filters, additionalFilters = {}) => {
    const payload: IUpdateWidget = {
      filters,
      additionalFilters: additionalFilters || {},
      dashboardId,
      id: widgetId,
    };

    editWidgetQuery.mutate(payload, {
      onSuccess: (res) => {
        getWidgetQuery.refetch();
      },
    });
  };

  const isTemplateChange = (newFilters) => {
    const newTemplateFilter = newFilters?.filters?.find(
      (filter) => filter?.filterName === 'template',
    );
    const oldTemplateFilter = data?.filters?.filters?.find(
      (filter) => filter?.filterName === 'template',
    );

    if (newTemplateFilter?.value != oldTemplateFilter?.value) {
      return true;
    }

    return false;
  };

  const handleFilterChange = (filters) => {
    const formatedFilters = formatFilters(filters);
    const isFilterValid = validateAdvancedFilter(formatedFilters.filters);
    let finalFilters: any = undefined;

    if (isFilterValid) {
      finalFilters = filters;

      let additionalFilters = cloneDeep(data?.additionalFilters);

      if (data?.entity == 'submission') {
        const hasChange = isTemplateChange(finalFilters);

        if (hasChange) {
          additionalFilters = {
            ...additionalFilters,
            submissionFilters: [],
          };
        }

        if (additionalFilters?.submissionFilters?.length > 0) {
          additionalFilters = {
            ...additionalFilters,
            submissionFilters: {
              condition: 'AND',
              filters: additionalFilters?.submissionFilters,
            },
          };
        }
      }
      if (data?.entity == 'task') {
        const isTaskCorrective =
          finalFilters?.condition === 'AND'
            ? finalFilters?.filters.filter(
                (widgetFilter) =>
                  widgetFilter.filterName === 'taskTypes' &&
                  widgetFilter.comparator === 'is' &&
                  widgetFilter.value.length === 1 &&
                  widgetFilter.value[0] === 'corrective',
              ).length === 1
            : finalFilters?.condition === 'OR' &&
              finalFilters?.filters.length === 1 &&
              finalFilters?.filters[0].filterName === 'taskTypes' &&
              finalFilters?.filters[0].comparator === 'is' &&
              finalFilters?.filters[0].value.length === 1 &&
              finalFilters?.filters[0].value[0] === 'corrective';
        if (!isTaskCorrective) {
          additionalFilters = {};
        }
      }
      const clonedFilters = cloneDeep(finalFilters);

      if (clonedFilters?.filters?.length > 0) {
        clonedFilters.filters = clonedFilters?.filters?.filter(
          (item) =>
            !(item?.filterName === 'user' && item?.value?.[0] === 'all'),
        );
      }

      updateWidget(clonedFilters, additionalFilters);
    } else if (!isFilterValid && checkEmptyFilters(formatedFilters.filters)) {
      handleClearFilter();
    }
  };

  const validateAdvancedItemFilter = (
    advancedItemFilters: any[] | undefined,
  ) => {
    if (advancedItemFilters === undefined) return true;
    return (
      advancedItemFilters.every(
        (f) =>
          !!f.item &&
          !!f.comparator &&
          (isArray(f.value)
            ? f.value.length > 0 && f.value.every((v) => !!v)
            : !!f.value),
      ) && advancedItemFilters.length !== 0
    );
  };

  const handleItemFilterChange = (filters, empty = false) => {
    const formatedFilters = formatFilters(filters);
    const isFilterValid = validateAdvancedItemFilter(formatedFilters.filters);
    let finalFilters: any = undefined;
    if (isFilterValid) {
      finalFilters = filters;
      if (finalFilters?.filters?.length > 0) {
        finalFilters = {
          condition: 'AND',
          filters: finalFilters?.filters,
        };
      } else {
        finalFilters = [];
      }

      updateWidget(data?.filters, {
        ...data?.additionalFilters,
        submissionFilters: finalFilters,
      });
    } else if (!isFilterValid && empty) {
      updateWidget(data?.filters, {});
    }
  };
  const handleClearFilter = () => {
    updateWidget({}, {});
  };

  const handleTaskItemFilterChange = (
    filters,
    empty = false,
    key = 'associatedLogFilters',
  ) => {
    const formatedFilters = formatFilters(filters);
    const isFilterValid = validateAdvancedFilter(formatedFilters.filters);
    let finalFilters: any = undefined;
    if (isFilterValid) {
      finalFilters = filters;
      if (finalFilters?.filters?.length > 0) {
        finalFilters = {
          condition: 'AND',
          filters: finalFilters?.filters,
        };
      } else {
        finalFilters = [];
      }
      const payload: IUpdateWidget = {
        filters: { ...data?.filters },
        additionalFilters: {
          ...data?.additionalFilters,
          [key]: finalFilters || {},
        },
        dashboardId,
        id: widgetId,
      };

      editWidgetQuery.mutate(payload, {
        onSuccess: (res) => {
          getWidgetQuery.refetch();
        },
      });
    } else if (!isFilterValid && empty) {
      updateWidget(data?.filters, {});
    }
  };

  return (
    <WidgetFilterCnt>
      {data?.entity == 'task' && (
        <FiltersDropdown
          iconButton={isCompactAction}
          selectedFilters={data?.filters}
          additonalFilters={data?.additionalFilters || {}}
          handleSetItemsAdvancedFilters={handleTaskItemFilterChange}
          onChange={handleFilterChange}
          handleClearFilters={handleClearFilter}
          hideFilters={
            hideFilters ||
            'startDate locations createdDate archived futureStartDate futureDueDate completedDate taskTemplates priority createdBy scheduleFrequency projects'
          }
        />
      )}
      {data?.entity == 'scheduledTask' && (
        <FiltersDropdown
          iconButton={isCompactAction}
          selectedFilters={data?.filters}
          onChange={handleFilterChange}
          handleClearFilters={handleClearFilter}
          hideFilters={
            hideFilters ||
            'startDate dueDate associatedChecklists locations createdDate archived futureStartDate futureDueDate completedDate taskTemplates priority createdBy scheduleFrequency projects'
          }
        />
      )}
      {data?.entity == 'submission' && (
        <SubmissionsFiltersDropdown
          handleSetAdvancedFilters={handleFilterChange}
          handleSetItemsAdvancedFilters={handleItemFilterChange}
          handleClearFilter={handleClearFilter}
          advancedFilters={data?.filters?.filters}
          additionalFilters={data?.additionalFilters}
          hideFilters={hideFilters || hideSubmissionsFilters(data)}
        />
      )}

      {data?.entity == DASHBOARDS_CONST.WIDGET_TYPE_FLAGGED_ITEMS && (
        <SubmissionsFiltersDropdown
          handleSetAdvancedFilters={handleFilterChange}
          handleSetItemsAdvancedFilters={handleItemFilterChange}
          handleClearFilter={handleClearFilter}
          advancedFilters={data?.filters?.filters}
          additionalFilters={data?.additionalFilters}
          hideFilters={hideFilters || 'status duration completedAt completedBy'}
        />
      )}
    </WidgetFilterCnt>
  );
}

export default WidgetAdvanceFilter;
