import CalendarIconRounded from 'components/Icons/calendarIconRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import useDateTime from 'utils/CustomHooks/useDateTime';
import { useMemo } from 'react';
import { TASK_STATUS } from 'utils/globalVariable';
import { DueDateCnt } from 'pages/task/ListViewV2/TaskTile/dueDate/dueDate.styles';

function DueDate({ task }) {
  const { getUtcToTz, getUtcToTzTime } = useDateTime();
  const isTaskOverdue = task.isOverDue;
  const isTaskMissed = task.taskStatus === TASK_STATUS.Missed;
  const formatedDueDate = task.dueDate
    ? `${getUtcToTz(task.dueDate, 'MMM D')}, ${getUtcToTzTime(
        task.dueDate,
        'hh:mm A',
      )}`
    : undefined;

  return (
    <DueDateCnt>
      {!task.Finisher && task.dueDate && !isTaskOverdue && (
        <div className={'dueOrcompleted'}>
          <CalendarIconRounded />
          {formatedDueDate}
        </div>
      )}

      {isTaskOverdue && !isTaskMissed && (
        <div className={'overdue'}>
          <AccessTimeRoundedIcon sx={{ fontSize: 16 }} />
          {formatedDueDate}
        </div>
      )}
    </DueDateCnt>
  );
}

export default DueDate;
