import { useCallback, useEffect, useMemo, useState } from 'react';
import { TaskSummaryContext } from 'pages/ReportingV2/Reports/TaskOverview/context/taskSummaryContext';
import {
  INTIAL_LAYOUT_SCHEDULED_WORK_REPORT,
  SCHEDULED_REPORTS_THRESHOLD,
} from 'components/CustomTilesGrid/utils';

import CustomDragTilesGrid from 'components/CustomDragTilesGrid';

import useReports from 'pages/ReportingV2/Reports/useReports';
import { REPORTING_CONFIG_CONSTANTS } from 'pages/ReportingV2/Reports/constants';

import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { transformLayouts } from 'utils/globalFunction';
import CompletionOverview from '../widgets/completionOverview';
import WorkByStatus from '../widgets/workByStatus';
import CorrectiveActions from '../widgets/correctiveActions';
import ScheduledTasks from '../widgets/schedules';
import CompletionByLocations from '../widgets/completionByLocation';
import OnTimeVsLateByLocation from '../widgets/onTimeVsLateCompletionbyLocation';

function Summary(props) {
  const { workspaceId } = useWorkspaceHook();

  const {
    defaultProps,
    state,
    updateState,
    clearAllFilters,
    updateGridSettings,
  } = useReports(REPORTING_CONFIG_CONSTANTS.SCHEDULED_WORK_REPORT, false);

  const transformedLayouts = useCallback(() => {
    return state?.gridSettings
      ? transformLayouts(
          state?.gridSettings,
          INTIAL_LAYOUT_SCHEDULED_WORK_REPORT,
          SCHEDULED_REPORTS_THRESHOLD,
        )
      : null;
  }, [state?.gridSettings]);
  return (
    <div>
      {state?.isLoading ? null : (
        <div style={{ margin: '20px 30px' }}>
          <CustomDragTilesGrid
            key={workspaceId}
            customLayout={INTIAL_LAYOUT_SCHEDULED_WORK_REPORT}
            onChangeCB={updateGridSettings}
            isDroppable={false}
            isResizable={false}
            isDraggable={false}
          >
            <CompletionOverview key="scheduleCompletionOverview" {...props} />
            <WorkByStatus key="scheduledWorkByStatus" {...props} />
            <CompletionByLocations
              key="scheduleCompletionByLocation"
              {...props}
            />

            <OnTimeVsLateByLocation
              key="onTimeVsLateCompletionByLocation"
              {...props}
            />

            <ScheduledTasks key="schedulesCompletionStats" {...props} />

            <CorrectiveActions key="correctiveScheduledTasks" {...props} />
          </CustomDragTilesGrid>
        </div>
      )}
    </div>
  );
}

export default Summary;
