import styled from '@mui/system/styled';
import CustomButton from 'components/Button/CustomButton';
import List from '@mui/material/List';

export const StyledList = styled(List)({
  padding: '0',
  maxHeight: 300,
  overflowY: 'auto',
  '.calendarIcon': {
    '& path': {
      stroke: '#616161',
    },
  },
  '& svg': {
    color: '#616161',
    fontSize: 18,
  },
});
export const GroupByBtn = styled(CustomButton, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ selected }) => ({
  border: '1px solid #EBEAFF',
  display: 'flex',
  gap: 6,
  padding: '6px 10px 6px 15px',
  height: 38,
  borderRadius: 6,
  fontSize: 13,
  fontWeight: 500,
  // lineHeight: 22,
  letterSpacing: '0.46px',
  '&:hover': {
    border: '1px solid #EBEAFF',
  },
  color: '#212121',
  '.optionsIcon': {
    color: '#616161',
  },
  svg: {
    display: 'inline-block',
    color: '#616161',
  },
  '.calendarIcon': {
    '& path': {
      stroke: '#616161',
    },
  },
  '& .MuiButton-startIcon': {
    marginRight: 0,
    height: 18,
    width: 18,
    alignItems: 'center',
    '& svg': {
      color: '#000000DE',
      fontSize: 18,
    },
  },
}));
