import styled from '@mui/system/styled';
import CTypography from 'components/CTypography';

export const PriorityContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
});
export const ValueText = styled(CTypography, {
  shouldForwardProp: (prop) => prop !== 'customcolor',
})(({ customcolor }) => ({
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '24px',
  color: customcolor ?? '#000',
}));
