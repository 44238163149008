import { FC, useState } from 'react';
import MultiLocationsDetails from 'components/BuilderUI/AddOns/MultiLocations';
import { BuilderIoSectionCnt } from '../style';
import { Box, Typography } from '@mui/material';
import Resources from 'pages/dashboard/resources';
import { useDispatch } from 'react-redux';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import CustomButton from 'components/Button/CustomButton';
import { setCalendlyInfo } from 'store/actions/calendlyActions';
import { TALK_TO_SALES_URL } from 'utils/constants';

const AddOnsMultiLocationsPage: FC = () => {
  const { FEATURES, hasFeature } = useCustomPermission();
  const [builderActive, setBuilderActive] = useState(false);
  const dispatch = useDispatch();

  const handleTalkToSales = () => {
    dispatch(
      setCalendlyInfo({
        url: TALK_TO_SALES_URL,
        open: true,
      }),
    );
  };

  return (
    <BuilderIoSectionCnt>
      <Box className="actionCnt">
        {hasFeature(FEATURES.ADVANCED_LOCATION_BASED_ASSIGNMENT) ? (
          <div className="enabledBox">
            <Typography className="text">Enabled</Typography>
          </div>
        ) : (
          <CustomButton
            variant="contained"
            className="talkToSales"
            onClick={handleTalkToSales}
          >
            Talk to Sales
          </CustomButton>
        )}
      </Box>
      <MultiLocationsDetails setBuilderActive={setBuilderActive} />
      {builderActive && (
        <Box className="resources">
          <Resources title="Resources about Location Hierarchy & Multi-Unit" />
        </Box>
      )}
    </BuilderIoSectionCnt>
  );
};

export default AddOnsMultiLocationsPage;
