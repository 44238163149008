import { Box, Grid, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import {
  AddOnsContainer,
  EnabledBox,
  GetDemoBtn,
  ImgBox,
  IntroBannerCnt,
} from './style';
import KioskModeIcon from 'components/Icons/kioskModeIcon';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { setCalendlyInfo } from 'store/actions/calendlyActions';
import { useDispatch } from 'react-redux';
import { TALK_TO_SALES_URL } from 'utils/constants';
import LocationIcon from 'components/Icons/locationIcon';
import UsersAndTeamsIcon from 'components/Icons/sidebarIcons/usersAndTeamsIcon';
import KeyIcon from 'components/Icons/keyIcon';
import ProjectIcon from 'components/Icons/projectIcon';
import RequestsIcon from 'components/Icons/requestsIcon';
import QRCodeIcon2 from 'components/Icons/qrcodeIcon2';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import CustomRolesIcon from 'components/Icons/customRolesIcon';
import ThermometerIcon from 'components/Icons/thermometerIcon';

const AddOns: FC = () => {
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { FEATURES, hasFeature } = useCustomPermission();
  const dispatch = useDispatch();

  const getDemoHandler = () => {
    dispatch(
      setCalendlyInfo({
        url: TALK_TO_SALES_URL,
        open: true,
      }),
    );
  };

  const redirectToDetailView = (feature) => {
    switch (feature) {
      case 'multi-locations':
        navigateWithWorkspaceUrl('/addons/multilocations');
        break;

      case 'bluetooth-sensor':
        navigateWithWorkspaceUrl('/addons/bluetoothsensor');
        break;

      case 'kiosk-mode':
        navigateWithWorkspaceUrl('/addons/kioskmode');
        break;

      case 'custom-roles':
        navigateWithWorkspaceUrl('/addons/customroles');
        break;

      case 'sso':
        navigateWithWorkspaceUrl('/addons/sso');
        break;

      case 'work-requests':
        navigateWithWorkspaceUrl('/addons/workrequests');
        break;

      case 'scan-qr-code':
        navigateWithWorkspaceUrl('/addons/scanqrcode');
        break;

      case 'projects':
        navigateWithWorkspaceUrl('/addons/projects');
        break;

      default:
        return null;
    }
  };

  return (
    <AddOnsContainer>
      <IntroBannerCnt
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="column" gap="8px">
          <Typography className="statement">
            <span className="boldText">Enhance Your Xenia Experience</span> with
            Powerful Add-Ons
          </Typography>
          <Typography className="description">
            Unlock the full potential of Xenia with our powerful add-ons.
            Designed to enhance functionality and streamline workflows, these
            tools will help you achieve greater efficiency and productivity.
          </Typography>
        </Stack>

        <GetDemoBtn variant="contained" onClick={getDemoHandler}>
          Get a Demo
        </GetDemoBtn>
      </IntroBannerCnt>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Box
            className="mainBox"
            onClick={() => redirectToDetailView('multi-locations')}
          >
            {hasFeature(FEATURES.ADVANCED_LOCATION_BASED_ASSIGNMENT) && (
              <EnabledBox>
                <Typography className="text">Enabled</Typography>
              </EnabledBox>
            )}

            <ImgBox
              style={{
                backgroundColor: 'rgba(224, 247, 250, 1)',
              }}
              sx={{
                '& path': {
                  strokeOpacity: 1,
                },
              }}
            >
              <LocationIcon
                primary="rgba(38, 198, 218, 1)"
                style={{
                  fill: 'none',
                  width: 30,
                  height: 30,
                }}
              />
            </ImgBox>
            <Typography className="title">
              Location Hierarchy & Multi-Unit
            </Typography>
            <Typography className="subTitle">
              Enable location membership, location hierarchy, as well as
              location based access.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            className="mainBox"
            onClick={() => redirectToDetailView('bluetooth-sensor')}
          >
            <ImgBox
              style={{
                backgroundColor: '#FEEBEE',
              }}
              sx={{
                '& path': {
                  strokeOpacity: 1,
                },
              }}
            >
              <ThermometerIcon
                style={{
                  fill: 'none',
                  width: 30,
                  height: 30,
                }}
              />
            </ImgBox>
            <Typography className="title">
              Bluetooth Thermometer & Sensors
            </Typography>
            <Typography className="subTitle">
              Monitor and log temperature to ensure compliance and optimal
              conditions for safety and quality.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            className="mainBox"
            onClick={() => redirectToDetailView('kiosk-mode')}
          >
            {hasFeature(FEATURES.KIOSK_MODE) && (
              <EnabledBox>
                <Typography className="text">Enabled</Typography>
              </EnabledBox>
            )}
            <ImgBox
              style={{
                backgroundColor: '#E8F5E9',
              }}
            >
              <KioskModeIcon
                style={{
                  fill: 'none',
                  color: '#66BB6A',
                  width: 40,
                  height: 35,
                }}
              />
            </ImgBox>
            <Typography className="title">Kiosk Mode</Typography>
            <Typography className="subTitle">
              Transform any mobile device into a shared device that your team
              members can log into with their PIN.
            </Typography>{' '}
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            className="mainBox"
            onClick={() => redirectToDetailView('custom-roles')}
          >
            {hasFeature(FEATURES.CUSTOM_ROLES) && (
              <EnabledBox>
                <Typography className="text">Enabled</Typography>
              </EnabledBox>
            )}
            <ImgBox
              style={{
                backgroundColor: 'rgba(227, 242, 253, 1)',
              }}
            >
              <CustomRolesIcon
                style={{ fill: 'none', width: 30, height: 30 }}
                sx={{
                  '& path': {
                    stroke: '#0277BD',
                  },
                }}
              />
            </ImgBox>
            <Typography className="title">Custom Roles</Typography>
            <Typography className="subTitle">
              Create custom roles with custom permissions to control what each
              user can see and do.
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Box className="mainBox" onClick={() => redirectToDetailView('sso')}>
            {hasFeature(FEATURES.SSO) && (
              <EnabledBox>
                <Typography className="text">Enabled</Typography>
              </EnabledBox>
            )}
            <ImgBox
              style={{
                backgroundColor: 'rgba(224, 242, 241, 1)',
              }}
              sx={{
                '& path': {
                  stroke: 'rgba(38, 166, 154, 1)',
                },
              }}
            >
              <KeyIcon
                style={{
                  fill: 'none',
                  width: 27,
                  height: 27,
                }}
              />
            </ImgBox>
            <Typography className="title">Single Sign On (SSO)</Typography>
            <Typography className="subTitle">
              Enable login with SSO to provide seamless and secure access for
              your team on Xenia.
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Box
            className="mainBox"
            onClick={() => redirectToDetailView('work-requests')}
          >
            {hasFeature(FEATURES.PUBLIC_WORK_ORDER_REQUEST) && (
              <EnabledBox>
                <Typography className="text">Enabled</Typography>
              </EnabledBox>
            )}

            <ImgBox
              style={{
                backgroundColor: 'rgba(255, 248, 225, 1)',
              }}
            >
              <RequestsIcon
                primary="rgba(255, 143, 0, 1)"
                secondary="rgba(255, 248, 225, 1)"
                style={{
                  fill: 'none',
                  width: 30,
                  height: 30,
                }}
              />
            </ImgBox>
            <Typography className="title">Work Requests</Typography>
            <Typography className="subTitle">
              Enable both Xenia and non-Xenia users to submit work requests.
              View those requests, approve or decline them, and assign to the
              right team.
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Box
            className="mainBox"
            onClick={() => redirectToDetailView('scan-qr-code')}
          >
            {hasFeature(FEATURES.SCAN_QR_CODE) && (
              <EnabledBox>
                <Typography className="text">Enabled</Typography>
              </EnabledBox>
            )}
            <ImgBox
              style={{
                backgroundColor: 'rgba(237, 231, 246, 1)',
              }}
            >
              <QRCodeIcon2
                style={{
                  fill: 'none',
                  color: 'rgba(126, 87, 194, 1)',
                  width: 27,
                  height: 27,
                }}
              />
            </ImgBox>
            <Typography className="title">QR Codes</Typography>
            <Typography className="subTitle">
              Quickly access information, checklists, or complete actions by
              scanning QR codes.
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Box
            className="mainBox"
            onClick={() => redirectToDetailView('projects')}
          >
            {hasFeature(FEATURES.PROJECTS) && (
              <EnabledBox>
                <Typography className="text">Enabled</Typography>
              </EnabledBox>
            )}
            <ImgBox
              style={{
                backgroundColor: 'rgba(243, 229, 245, 1)',
              }}
              sx={{
                '& path': {
                  stroke: 'rgba(171, 71, 188, 1)',
                },
              }}
            >
              <ProjectIcon />
            </ImgBox>
            <Typography className="title">Projects</Typography>
            <Typography className="subTitle">
              Create, assign, and edit multiple schedules at once, and manage
              all of them from one page.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </AddOnsContainer>
  );
};

export default AddOns;
