import { useMemo } from 'react';
import CompletedAIGeneration from './completedAIGeneration';
import StopAIGeneration from './stopAIGeneration';
import XeniaProTips from './xeniaProTips';

function BuilderFooter(props) {
  const {
    autoTemplateInterval,
    isGenerating,
    checklist,
    setChecklist,
    setChecklistItems,
    handleGoNextMetadata,
    folderId,
    isChecklistComplete,
    getOrCreateAndSetChecklist,
    aiController,
  } = props;

  const showNotes = useMemo(() => {
    const isLoadingNext = checklist?.ChecklistItems?.find(
      (item) => item?.loadingNext,
    );

    return checklist?.ChecklistItems?.length === 1 && isLoadingNext;
  }, [checklist]);

  return isGenerating ? (
    <>
      {showNotes && <XeniaProTips />}
      <StopAIGeneration
        autoTemplateInterval={autoTemplateInterval}
        checklist={checklist}
        setChecklist={setChecklist}
        setChecklistItems={setChecklistItems}
        getOrCreateAndSetChecklist={getOrCreateAndSetChecklist}
        aiController={aiController}
      />
    </>
  ) : !isChecklistComplete ? null : (
    <CompletedAIGeneration
      handleGoNextMetadata={handleGoNextMetadata}
      checklist={checklist}
      folderId={folderId}
      setChecklist={setChecklist}
      setChecklistItems={setChecklistItems}
      getOrCreateAndSetChecklist={getOrCreateAndSetChecklist}
    />
  );
}

export default BuilderFooter;
