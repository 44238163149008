import React, { useCallback, useEffect, useMemo, useState } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import selectors from 'store/selectors';

// Custom components
import CustomListView from 'components/ListView/Listview.cmp';
import TaskListSkeleton from './Skeleton/TaskListSkeleton.cmp';
import NoAddedRecords from './EmptyRecords/NoAddedRecords.cmp';
import CreateTaskInfo from '../TaskListView/CreateTaskInfo/CreateTaskInfo';
import CreateTaskInfoCorrective from '../TaskListView/CreateTaskInfoCorrective/CreateTaskInfoCorrective';
import BulkAction from 'pages/task/TaskListView/BulkAction';
import FullWidthCellRenderer from 'pages/task/TaskListView/AddTaskInline/createTaskRow';

// AG Gridx
import {
  defaultColDef,
  columns,
  isRowGroupingEnabled,
  customIcons,
  getRowStyles,
  addTaskWithStatusRow,
  addTaskWithAssigneeRow,
  comparator,
  groupByColumn,
} from 'pages/task/TaskListView/constants';
import {
  ColumnPinnedEvent,
  ColumnResizedEvent,
  ColumnVisibleEvent,
  DragStoppedEvent,
  SortChangedEvent,
} from 'ag-grid-community';

// Utils
import { useUpdateColumn } from 'utils/CustomHooks/useUpdateColumn';
import xeniaApi from 'api/index';
import { isEmpty, sortBy, findIndex, find } from 'lodash';
import { TData } from 'memfs/lib/volume';
import { statusDropdownData } from 'helper/task/dropdownData';
import ArrowIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';
import { IconButton } from '@mui/material';
import {
  AssigneeGroupRowRendererCnt,
  GroupRowRendererCnt,
} from 'pages/task/TaskListView/taskListView.style';
import { useTaskEdit } from 'controller/useTaskEdit';
import AvatarPopover from 'components/AvatarPopover';
import TeamAvatar from 'components/Avatar/TeamAvatar/TeamAvatar.cmp';
import { useCurrency } from 'customhooks/index';
import './table.css';
import RequestedEmptyState from './RequestedEmptyState';

const TaskListView = ({
  onRowClick,
  taskActionDropdownProps,
  style = {},
  taskListFilters,
  forcedAdvanceFilters,
  isMainView,
  currentTabInTasksView,
  hideMainBanner,
  taskListHeight,
}) => {
  const [searching, setSearching] = useState(false);
  const [selected, setSelected] = useState([]);
  const { updateColumnsForTasksListing } = useUpdateColumn(null);
  const taskList: any = useSelector(selectors.getTaskList);
  const checkList = useSelector(selectors.getChecklistCompleteDataOnly);
  const categoryList = useSelector(selectors.getServiceAdmin);
  const { currency } = useCurrency();
  const projects = useSelector(selectors.getProjects);
  const schedules = useSelector(selectors.getSchedules);

  const isCorrectiveTasksView = useMemo(() => {
    return forcedAdvanceFilters?.some(
      (f) => f.filterName === 'taskTypes' && f.value.includes('corrective'),
    );
  }, [forcedAdvanceFilters]);

  const isRequestedTasksView = useMemo(() => {
    return forcedAdvanceFilters?.some(
      (f) => f.filterName === 'taskTypes' && f.value.includes('requested'),
    );
  }, [forcedAdvanceFilters]);

  const isFiltersORSearch = useSelector(selectors.getIsFiltersORSearch);
  const loaders = useSelector(selectors.getLoaders);
  const commentChannelsHash = useSelector(selectors.getTaskCommentChannelsHash);
  const userMetaInfo: any = useSelector(selectors.getUserMeta);
  const topAlertState = useSelector(selectors.getTopAlertState);
  const { handleChangeSelectedOption } = useTaskEdit(null);

  const taskColumns = useMemo(() => {
    return taskListFilters?.tabs?.[currentTabInTasksView]?.columns ?? [];
  }, [taskListFilters?.tabs?.[currentTabInTasksView]?.columns]);

  //Fetching task columns
  const fetchColumns = async () => {
    const taskColumns = taskListFilters?.tabs?.[currentTabInTasksView]?.columns;
    //Apply columns state on grid
    globalThis?.taskListGrid?.columnApi?.applyColumnState({
      state: taskColumns,
      applyOrder: true,
    });
  };

  const isArchiveFilter = find(
    taskListFilters?.tabs?.[currentTabInTasksView]?.filters,
    (f) => f?.filterName === 'archived' && f?.comparator === 'is',
  );
  useEffect(() => {
    fetchColumns();
  }, []);
  //Event will be called when a sort will be changed
  const onSortChange = (event: SortChangedEvent<TData>) => {
    updateColumnsForTasksListing(
      event.columnApi.getColumnState(),
      currentTabInTasksView,
    );
  };
  //Event will be called when a column is resized
  const onColumnResized = (event: ColumnResizedEvent<TData>) => {
    if (event?.finished && event.source !== 'api') {
      updateColumnsForTasksListing(
        event.columnApi.getColumnState(),
        currentTabInTasksView,
      );
    }
  };
  //Event called when a row/columns drag is stopped
  const onDragStopped = (event: DragStoppedEvent<TData>) => {
    updateColumnsForTasksListing(
      event.columnApi.getColumnState(),
      currentTabInTasksView,
    );
  };
  //Called When column is hide/show
  const onColumnVisible = (event: ColumnVisibleEvent<TData>) => {
    updateColumnsForTasksListing(
      event.columnApi.getColumnState(),
      currentTabInTasksView,
    );
  };
  //Called When column is pinned/unpinned
  const onColumnPinned = (event: ColumnPinnedEvent<TData>) => {
    updateColumnsForTasksListing(
      event.columnApi.getColumnState(),
      currentTabInTasksView,
    );
  };

  const excelStyles: any = [
    {
      id: 'header',
      alignment: {
        vertical: 'Center',
      },
      interior: {
        color: '#5555ff',
        pattern: 'Solid',
        patternColor: undefined,
      },
      font: { color: '#ffffff' },
    },
    {
      id: 'orgName',
      alignment: {
        vertical: 'Center',
        wrapText: true,
      },
    },
  ];

  //**** Will be removed once counts are sent with in the tasks from backend
  const tasksWithCounts = useMemo(
    () =>
      taskList?.rows
        ?.map((t) => {
          const channelId = `task_comments_${t.id}`;
          const channel = commentChannelsHash[channelId];
          const commentsCount = channel?.state;
          const attachedCheckList = checkList?.find(
            (item) => item.id === t.ChecklistId,
          );

          const project = projects?.list?.find(
            (item) => item?.id === t?.ProjectId,
          );

          const schedule = schedules?.list?.find(
            (item) => item?.id === t?.taskUniqueId,
          );

          return {
            ...t,
            commentsCount: commentsCount,
            attachedCheckList,
            project,
            schedule,
          };
        })
        .filter((t) => t?.taskNumber) || [],
    [commentChannelsHash, taskList, taskColumns],
  );
  const colConfig = useCallback(
    () =>
      columns({
        onRowClick,
        taskActionDropdownProps,
        categoryList,
        currency,
      }),
    [taskColumns, tasksWithCounts, currency],
  );

  const actionColumn = colConfig()?.find((c) => c.colId === 'actionCol');
  const sortedColumns = useCallback(() => {
    const configuredCols = colConfig()?.map((c) => {
      const currentColConfig =
        taskColumns?.find((cc) => cc.colId == c.colId) || {};
      return { ...c, ...currentColConfig };
    });

    return sortBy(configuredCols, (x) =>
      findIndex(taskColumns, (y: any) => x.colId === y.colId),
    ).filter((item) => item.colId !== 'actionCol');
  }, [taskColumns, tasksWithCounts]);

  const allColumns = useCallback(() => {
    return isEmpty(sortedColumns())
      ? colConfig()
      : [...sortedColumns(), actionColumn];
  }, [colConfig, tasksWithCounts]);

  //Hiding last 2 options for columns menu
  const getMainMenuItems = (params) => {
    const newOptions = params.defaultItems.slice(
      0,
      params.defaultItems.length - 3,
    );
    return newOptions;
  };

  const manageOverlays = () => {
    const { api } = globalThis.taskListGrid;
    const rowsCount = api.getDisplayedRowCount();

    setSearching(api.isAnyFilterPresent());
    if (rowsCount === 0 && (isFiltersORSearch || api.isAnyFilterPresent())) {
      api.showNoRowsOverlay();
    } else {
      api.hideOverlay();
    }
  };

  const rowDataUpdated = () => {
    manageOverlays();
  };

  const filtersUpdated = () => {
    manageOverlays();
  };
  const pinnedTopRowData = useMemo(() => {
    return [
      {
        fullWidth: true,
      },
    ];
  }, []);
  const getRowHeight = useCallback((params) => {
    if (params?.data?.addTask) {
      return 35;
    }
    if (params?.data?.fullWidth && !params?.node?.group) {
      return 50;
    }
    if (params?.node?.group) {
      return 75;
    }
  }, []);
  //Add Task Full width row renderer
  const fullWidthCellRenderer = useMemo(() => {
    return FullWidthCellRenderer;
  }, []);

  const isFullWidthRow = useCallback((params) => {
    return params?.rowNode?.data?.fullWidth;
  }, []);

  const isTaskListEmpty =
    !userMetaInfo?.createFirstTaskDialogSeen &&
    !taskList?.rows?.length &&
    !isFiltersORSearch &&
    !searching &&
    loaders?.tasksList?.isLoading === false;
  const onSelectionChanged = (event) => {
    const selected = event.api.getSelectedNodes();
    const filtered = selected.filter(
      (node) => !node.group && !node.data.fullWidth,
    );
    setSelected(filtered);
  };

  const clearSelection = (data = { updateData: false }) => {
    if (data?.updateData) {
      globalThis.taskListGrid.api.redrawRows();
      return;
    }

    globalThis.taskListGrid.api.deselectAll();
    setSelected([]);
  };

  const AssigneeRenderer = useCallback(
    (params) => {
      const childLength = params?.node?.childrenAfterFilter?.length - 1;
      const data = params?.node?.childrenAfterFilter?.[0]?.data;
      return (
        <AssigneeGroupRowRendererCnt>
          {params.node.expanded ? (
            <IconButton
              sx={{
                padding: 0,
                mr: '10px',
                transform: 'rotateX(180deg)',
              }}
              onClick={() => {
                params.api.setRowNodeExpanded(params.node, false);
              }}
            >
              <ArrowIcon sx={{ color: 'rgba(0,0,0,0.54)' }} />
            </IconButton>
          ) : (
            <IconButton
              sx={{ padding: 0, mr: '10px' }}
              onClick={() => {
                params.api.setRowNodeExpanded(params.node, true);
              }}
            >
              <ArrowIcon sx={{ color: 'rgba(0,0,0,0.54)' }} />
            </IconButton>
          )}
          <div className={'assigneeValueCnt'}>
            {data?.Assigned && (
              <AvatarPopover
                avatarProps={{ width: 24, height: 24 }}
                showAvatarOnly
                userId={data?.AssignId}
              />
            )}

            {data?.Team && (
              <TeamAvatar width={24} height={24} teamId={data?.AssignId} />
            )}
            <div style={{ marginLeft: 10 }}>{params.value}</div>
          </div>
          {childLength ? (
            <span className={'childCount'}>{childLength} tasks</span>
          ) : null}
        </AssigneeGroupRowRendererCnt>
      );
    },
    [taskColumns, taskList],
  );

  const GroupInnerRenderer = useCallback((params) => {
    const selectedStatus = statusDropdownData.find(
      (s) => s.value === params?.value,
    );
    const addTaskRowExist = params.node.childrenAfterFilter.find(
      (x) => x?.data?.addTask,
    );
    const childLength = addTaskRowExist
      ? params?.node?.childrenAfterFilter?.length - 1
      : params?.node?.childrenAfterFilter?.length;
    return (
      <GroupRowRendererCnt>
        {params.node.expanded ? (
          <IconButton
            sx={{
              padding: 0,
              mr: '10px',
              transform: 'rotateX(180deg)',
            }}
            onClick={() => {
              params.api.setRowNodeExpanded(params.node, false);
            }}
          >
            <ArrowIcon
              sx={{ color: selectedStatus?.color || 'rgba(0,0,0,0.54)' }}
            />
          </IconButton>
        ) : (
          <IconButton
            sx={{ padding: 0, mr: '10px' }}
            onClick={() => {
              params.api.setRowNodeExpanded(params.node, true);
            }}
          >
            <ArrowIcon
              sx={{ color: selectedStatus?.color || 'rgba(0,0,0,0.54)' }}
            />
          </IconButton>
        )}
        {params.value}
        {childLength ? (
          <span className={'childCount'}>
            {childLength}&nbsp;{childLength > 1 ? 'tasks' : 'task'}
          </span>
        ) : null}
      </GroupRowRendererCnt>
    );
  }, []);
  const onRowDragMove = useCallback((event) => {
    if (groupByColumn().colId !== 'status') return;
    const movingNode = event.node;
    const overNode = event.overNode;
    // find out what country group we are hovering over
    let groupStatus;
    if (overNode.group) {
      // if over a group, we take the group key (which will be the
      // country as we are grouping by country)
      groupStatus = overNode.key;
    } else {
      // if over a non-group, we take the country directly
      groupStatus = overNode.data.taskStatus;
    }
    const needToChangeParent = movingNode.data.taskStatus !== groupStatus;
    if (needToChangeParent) {
      const movingData = movingNode.data;
      movingData.taskStatus = groupStatus;
      globalThis?.taskListGrid?.api?.applyTransaction({
        update: [movingData],
      });
      handleChangeSelectedOption(event.node, movingData, {
        successMessage: null,
      });

      globalThis?.taskListGrid?.api?.clearFocusedCell();
    }
  }, []);
  const rowGroupId =
    globalThis?.taskListGrid?.columnApi?.getRowGroupColumns()?.[0]?.colId;
  const addRows = () => {
    if (
      rowGroupId === 'status' &&
      taskList?.rows?.length &&
      !isCorrectiveTasksView &&
      !isRequestedTasksView
    ) {
      return addTaskWithStatusRow;
    } else if (rowGroupId === 'assigned_to' && taskList?.rows?.length) {
      return addTaskWithAssigneeRow();
    } else {
      return [];
    }
  };

  const sortDataWithStatusGrouping = (tasks: any) => {
    const currentGroupById = groupByColumn().colId;
    if (currentGroupById == 'status') {
      const statusOrder = ['Open', 'In Progress', 'On Hold', 'Completed'];
      return tasks.sort(function (a, b) {
        return (
          statusOrder.indexOf(a.taskStatus) - statusOrder.indexOf(b.taskStatus)
        );
      });
    }
  };
  const initialGroupOrderComparator = useCallback((params) => {
    const statusOrder = [
      'Open',
      'In Progress',
      'On Hold',
      'Completed',
      'Missed',
    ];

    const a = params.nodeA.key || '';
    const b = params.nodeB.key || '';
    return statusOrder.indexOf(a) - statusOrder.indexOf(b);
  }, []);
  const tasksDataSorted =
    isRowGroupingEnabled() && groupByColumn().colId == 'status'
      ? [...addRows(), ...sortDataWithStatusGrouping(tasksWithCounts)]
      : tasksWithCounts;
  const GroupRowInnerRenderer =
    rowGroupId == 'assigned_to' ? AssigneeRenderer : GroupInnerRenderer;

  const listHeight = useMemo(() => {
    if (taskListHeight) {
      return taskListHeight;
    }
    if (topAlertState?.show && hideMainBanner) {
      return 'calc(100vh - 235px)';
    }

    if (
      topAlertState?.show &&
      !hideMainBanner &&
      taskList?.rows?.length === 0
    ) {
      return 'calc(100vh - 265px)';
    }

    if (topAlertState?.show && !hideMainBanner && taskList?.rows?.length > 0) {
      return 'calc(100vh - 290px)';
    }

    if (!topAlertState?.show && hideMainBanner) {
      return 'calc(100vh - 187px)';
    }

    if (!topAlertState?.show && !hideMainBanner) {
      return 'calc(100vh - 245px)';
    }

    return 'calc(100vh - 265px)';
  }, [topAlertState, hideMainBanner, taskList]);

  return loaders?.tasksList?.isLoading ||
    loaders?.tasksList?.isLoading === null ? (
    <TaskListSkeleton />
  ) : (
    <>
      <CustomListView
        rootProps={{
          className: 'task-list-table',
          style: {
            height: listHeight,
            boxShadow: 'none',
            padding: '0 24px',
            flex: 1,
            ...style,
          },
        }}
        onRowClick={onRowClick}
        gridProps={{
          ref: (ref) => {
            globalThis.taskListGrid = ref;
          },
          headerHeight: 48,
          onSortChanged: onSortChange,
          onDragStopped: onDragStopped,
          onColumnResized: onColumnResized,
          onColumnVisible: onColumnVisible,
          onColumnPinned: onColumnPinned,
          rowData: tasksDataSorted,
          excelStyles: excelStyles,
          defaultColDef: defaultColDef,
          columnDefs: allColumns(),
          suppressCellFocus: true,
          suppressMoveWhenRowDragging: true,
          icons: customIcons,
          getMainMenuItems: getMainMenuItems,
          onRowDataUpdated: rowDataUpdated,
          onFilterChanged: filtersUpdated,
          deltaRowDataMode: false,
          //Add Task pinned row
          //Only show if grid has tasks and do not show when task list has no data
          isFullWidthRow: isFullWidthRow,
          pinnedTopRowData:
            !isRowGroupingEnabled() &&
            !isCorrectiveTasksView &&
            !isRequestedTasksView
              ? pinnedTopRowData
              : null,
          fullWidthCellRenderer: fullWidthCellRenderer,
          getRowHeight: getRowHeight,
          onSelectionChanged,
          initialGroupOrderComparator: initialGroupOrderComparator,
          getRowStyle: getRowStyles,
          rowSelection: 'multiple',
          suppressRowClickSelection: true,
          groupDisplayType: 'groupRows',
          groupRowsSticky: true,
          getRowId: (params) => params.data.id,
          groupDefaultExpanded: 1,
          onRowDragEnd: onRowDragMove,
          groupRowRendererParams: {
            checkbox: false,
            suppressCount: true,
            innerRenderer: GroupRowInnerRenderer,
          },
        }}
      />
      {isTaskListEmpty && isMainView && <CreateTaskInfo />}

      {isTaskListEmpty && !isMainView && isCorrectiveTasksView && (
        <CreateTaskInfoCorrective />
      )}

      {isTaskListEmpty && !isMainView && isRequestedTasksView && (
        <RequestedEmptyState />
      )}

      {userMetaInfo?.createFirstTaskDialogSeen &&
        taskList?.row?.length &&
        !isFiltersORSearch &&
        !searching && <NoAddedRecords />}
      {selected.length ? (
        <BulkAction
          selected={selected}
          onClearSelection={clearSelection}
          isArchiveFilter={isArchiveFilter}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default TaskListView;
