import ArrowCollapsed from '@mui/icons-material/KeyboardArrowUp';
import { isEmpty } from 'lodash';
import {
  ActionColumnRendererCnt,
  ArrowBtn,
  LocationNameCnt,
  CheckListIconBox,
  ProgressCnt,
} from './scheduleSummaryList.styles';
import { ReactComponent as ChecklistDefaultIcon } from 'public/assets/img/checklist-default-icon.svg';
import LocationIcon from 'components/Icons/locationIcon';
import { Box, Typography } from '@mui/material';
import { StyledProgressBar } from 'components/UserCounterProgress/styled';

const MuiIcon = ({ name, color, styles = {} }) => {
  const icon = {
    location: <LocationIcon sx={{ fontSize: 18 }} />,
    schedule: <ChecklistDefaultIcon style={{ height: 15, width: 15 }} />,
  };
  const IconComponent = icon[name];
  return IconComponent ? (
    <CheckListIconBox
      sx={{
        backgroundColor: color || '#FFA681',
        ...styles,
      }}
    >
      {IconComponent}
    </CheckListIconBox>
  ) : null;
};

export function ActionColumnRenderer({ params, groupBy }) {
  const { TaskSummaries, LocationSummaries } = params.data;
  const haveSubScheules =
    groupBy === 'location'
      ? !isEmpty(TaskSummaries)
      : !isEmpty(LocationSummaries);

  return (
    <ActionColumnRendererCnt>
      <span
        style={{ display: 'flex', alignItems: 'center' }}
        data-attribute={'cell-click'}
      >
        {haveSubScheules && (
          <ArrowBtn
            onClick={(e) => {
              e.stopPropagation();
              params.node.setExpanded(!params.node.expanded);
              globalThis?.scheduleSummaryList.api.redrawRows();
            }}
            collapsed={!params.node.expanded && !params?.isSearching}
          >
            <ArrowCollapsed />
          </ArrowBtn>
        )}
      </span>
    </ActionColumnRendererCnt>
  );
}
export function ProgressRenderer({ value, color, params }) {
  return (
    <ProgressCnt>
      <Typography
        variant="body2"
        sx={{ color: '#212121', fontSize: 15, fontWeight: 500 }}
      >{`${value}%`}</Typography>
      <Box width="100px" ml={1}>
        <StyledProgressBar
          style={{ height: 8, borderRadius: 4 }}
          variant="determinate"
          value={value}
          color={color}
        />
      </Box>
    </ProgressCnt>
  );
}
export function LocationNameRenderer({ params }) {
  const { taskUniqueId, title, locationName } = params.data;
  const haveSubScheules = !isEmpty(params?.data?.TaskSummaries);
  return (
    <LocationNameCnt>
      <span data-attribute={'cell-click'}></span>
      <span className={'locationName'}>{locationName || title || '-'}</span>
    </LocationNameCnt>
  );
}
export function ScheduleNameRenderer({ params }) {
  const { scheduleTitle, name, LocationId } = params.data;
  const haveSubLocations = !isEmpty(params?.data?.LocationSummaries);
  return (
    <LocationNameCnt>
      <span data-attribute={'cell-click'}></span>
      <span className={'locationName'}>{scheduleTitle || name || '-'}</span>
    </LocationNameCnt>
  );
}
