import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'store/selectors';

// Custom components
import CustomListView from 'components/ListView/Listview.cmp';
import 'pages/Dashboards/Widgets/TaskListing/TaskListView/taskListSummary.style.css';
// AG Grid
import {
  defaultColDef,
  columns,
  getRowStyles,
} from 'pages/Dashboards/Widgets/TaskListing/TaskListView/constants';
import { SortChangedEvent } from 'ag-grid-community';

// Utils
import { useUpdateColumn } from 'utils/CustomHooks/useUpdateColumn';
import xeniaApi from 'api/index';
import { TData } from 'memfs/lib/volume';
import { setDialog } from 'store/actions/dialogActions';
import WithLoader from 'pages/Dashboards/Widgets/withLoader';
import WithErrorState from 'pages/Dashboards/Widgets/errorStateCmp/errorState';
import WidgetAdvanceFilter from 'pages/Dashboards/Widgets/WidgetAdvanceFilter/widgetAdvanceFilter';
import WidgetActions from 'pages/Dashboards/Widgets/widgetActions';
import {
  WidgetCnt,
  WidgetHeading,
} from 'pages/Dashboards/Widgets/widgets.style';
import { useLoadWidget } from 'pages/Dashboards/Widgets/useLoadWidget';
import { exportToPng } from 'utils/globalFunction';
import { useMutation } from 'react-query';
import { useTaskUtils } from 'utils/CustomHooks/useTaskUtils';
import { cloneDeep } from 'lodash';

interface ITaskTableWidgetProps {
  widgetId: string;
  editable?: boolean;
  globalFilters?: any;
  isScheduled?: boolean;
}
const TasksListSummary = ({
  widgetId,
  editable,
  globalFilters,
  isScheduled,
}: ITaskTableWidgetProps) => {
  const datesFilters = ['startDate', 'dueDate', 'completedDate'];

  const { widget } = useLoadWidget(widgetId, globalFilters);
  const { getCategoryDetails, getChecklistDetails } = useTaskUtils();
  const { updateColumns } = useUpdateColumn(null);
  const chartRef: any = useRef(null);
  const categoryList = useSelector(selectors.getServiceAdmin);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const getTasksQuery = useMutation({
    mutationKey: 'getTasks',
    mutationFn: xeniaApi.getTasksApi,
  });

  const { taskColumns } = useSelector((state: any) => {
    return {
      taskColumns: state?.taskHotelData?.taskColumns,
    };
  });

  const dataTransformation = (data) => {
    return data?.map((item) => {
      let serviceCategory: any = null;
      let template: any = null;

      if (item?.ServiceId) {
        serviceCategory = getCategoryDetails(item);
      }

      if (item?.ChecklistId) {
        template = getChecklistDetails(item);
      }

      return {
        ...item,
        ...(serviceCategory && { ServiceType: serviceCategory }),
        ...(template && {
          Checklist: template,
        }),
      };
    });
  };

  const fetchTasksList = async (isRecurring = false) => {
    let localFilters = cloneDeep(widget?.filters?.filters);

    const globalAdvanceFilters = {
      condition: 'AND',
      filters: [
        ...(globalFilters &&
        globalFilters.dateRange &&
        globalFilters.dateRange[0] &&
        globalFilters.dateRange[1]
          ? [
              {
                comparator: 'is',
                conditional: 'and',
                filterName: isRecurring
                  ? 'instanceActivationTime'
                  : 'createdDate',
                value: [globalFilters.dateRange[0], globalFilters.dateRange[1]],
              },
            ]
          : []),
        ...(globalFilters &&
        globalFilters.location &&
        globalFilters.location.length > 0
          ? [
              {
                comparator: 'is',
                conditional: 'and',
                filterName: 'locations',
                value: globalFilters.location,
              },
            ]
          : []),
        ...(isRecurring
          ? [
              {
                comparator: 'is',
                conditional: 'and',
                filterName: 'taskTypes',
                value: ['recurring'],
              },
            ]
          : []),
      ],
    };

    let advanceFilters: any = {
      condition: 'AND',
      filters: [],
    };

    if (localFilters?.length > 0) {
      localFilters = localFilters?.filter((item) =>
        item?.filterName !== isRecurring ? 'dueDate' : 'createdDate',
      );

      localFilters = localFilters?.map((filter) => {
        if (datesFilters?.includes(filter?.filterName)) {
          return {
            ...filter,
            value: [filter?.value?.from, filter?.value?.to],
          };
        }

        return filter;
      });

      if (localFilters?.length > 0) {
        advanceFilters = {
          condition: localFilters?.[0]?.conditional?.toUpperCase(),
          filters: localFilters,
        };
      }
    }

    const requestPayload = {
      hideCompleted: false,
      limit: 1000000,
      offset: 0,
      view: 'list',
      viewType: 'current',
      advanceFilters,
      globalFilters: globalAdvanceFilters,
      ...widget?.additionalFilters,
    };

    getTasksQuery.mutate(requestPayload, {
      onSuccess: (data) => {
        setData(dataTransformation(data?.data?.rows));
      },
      onError: (error) => {
        console.log('error', error);
      },
    });
  };

  useEffect(() => {
    if (widget?.id) {
      fetchTasksList(isScheduled);
    }
  }, [globalFilters, widget]);

  const onRowClick = (id) => {
    dispatch(
      setDialog({
        open: true,
        dialogId: 'taskDetail',
        data: {
          taskId: id,
        },
      }),
    );
  };

  //Event will be called when a sort will be changed
  const onSortChange = (event: SortChangedEvent<TData>) => {
    updateColumns(event.columnApi.getColumnState());
  };

  const colConfig = useMemo(
    () =>
      columns({
        categoryList,
      }),
    [taskColumns],
  );

  //Hiding last 2 options for columns menu
  const getMainMenuItems = (params) => {
    const newOptions = params.defaultItems.slice(
      0,
      params.defaultItems.length - 3,
    );
    return newOptions;
  };

  const getRowHeight = useCallback((params) => {
    return 50;
  }, []);
  const exportChartToImage = () => {
    exportToPng(chartRef.current, widget?.title);
  };

  const hideFilters = useMemo(() => {
    if (isScheduled) {
      return 'locations dueDate futureDueDate startDate taskTypes';
    }
    return 'locations createdDate dueDate startDate scheduleFrequency';
  }, []);

  return (
    <WidgetCnt ref={chartRef}>
      <WidgetHeading>
        {widget?.title || 'Tasks'}
        <div className={'widgetActionsCnt'}>
          <WidgetAdvanceFilter
            widgetId={widgetId}
            globalFilters={globalFilters}
            hideFilters={hideFilters}
          />
          <WidgetActions
            widgetId={widgetId}
            exportChartToImage={exportChartToImage}
            editable={editable}
          />
        </div>
      </WidgetHeading>

      <WithLoader
        isLoading={getTasksQuery.status === 'idle' || getTasksQuery.isLoading}
      >
        <WithErrorState
          type={'table'}
          error={getTasksQuery?.error}
          reload={fetchTasksList}
        >
          <CustomListView
            rootProps={{
              style: {
                height: 400,
                boxShadow: 'none',
                flex: 1,
              },
            }}
            onRowClick={onRowClick}
            gridProps={{
              ref: (ref) => {
                globalThis.taskSummaryListGrid = ref;
              },
              headerHeight: 30,
              onSortChanged: onSortChange,
              rowData: data,
              suppressChangeDetection: true,
              defaultColDef: defaultColDef,
              columnDefs: colConfig,
              suppressCellFocus: true,
              getMainMenuItems: getMainMenuItems,
              getRowHeight: getRowHeight,
              getRowStyle: getRowStyles,
              suppressRowClickSelection: true,
              getRowId: (params) => params.data.id,
            }}
          />
        </WithErrorState>
      </WithLoader>
    </WidgetCnt>
  );
};

export default TasksListSummary;
