import React, { useMemo, useEffect, useState } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'store/selectors';

// Utils
import xeniaApi from 'api/index';
import { TasksListSummaryCnt } from 'pages/Widgets/TaskListing/TaskListView/tasksListSummary.style';
import { WidgetHeading, WidgetLoaderCnt } from 'pages/Widgets/widgets.style';
import CircularProgress from '@mui/material/CircularProgress';
import TaskListWidgetEmptyState from 'pages/Widgets/TaskListing/TaskListView/EmptyRecords/TaskListWidgetEmptyState.cmp';
import { setDialog } from 'store/actions/dialogActions';
import OverDueEmptyState from 'pages/Widgets/TaskListing/TaskListView/EmptyRecords/OverdueEmptyState';
import AssigneeFilterDropdown from 'components/AssigneeFilter/AssigneeFilter';
import CTabs from 'components/CTabs';
import { Stack, Box, Typography } from '@mui/material';
import AssigneesView from 'components/AssigneesView/AssigneesView';
import { statusDropdownData } from 'helper/task/dropdownData';
import useDateTime from 'utils/CustomHooks/useDateTime';
import useMeasure from 'react-use-measure';
import { useAssigneesUtils } from 'utils/CustomHooks/useAssigneesUtil';

// Hooks
import { useUserConfig } from 'controller/useUserConfig';

interface ListingProps {
  tasks: any[];
  type: 'dueToday' | 'overdue' | 'upcoming' | 'completed';
  onTaskClick: (taskId: string) => void;
}
const Listing: React.FC<ListingProps> = (props) => {
  const { tasks, type, onTaskClick } = props;
  const { isSameDay, isInFuture, getUtcToTzTime, getUtcToTz } = useDateTime();
  const getTaskColor = (status: string) => {
    const color = statusDropdownData.find((t) => t.id === status);
    return color;
  };
  const filteredTasks = useMemo(() => {
    switch (type) {
      case 'dueToday':
        return tasks.filter(
          (t) => t.dueDate && isSameDay(t.dueDate) && t.taskStatus !== 'Missed',
        );
      case 'overdue':
        return tasks.filter((t) => t.isOverDue && t.taskStatus !== 'Completed');
      case 'upcoming':
        return tasks.filter((t) => t.dueDate && isInFuture(t.dueDate));
      case 'completed':
        return tasks.filter((t) => t.taskStatus === 'Completed');
      default:
        return tasks;
    }
  }, [tasks, type]);

  return (
    <Stack maxWidth="100%" height="100%" overflow={'scroll'}>
      {filteredTasks.map((t) => {
        const color = getTaskColor(t.taskStatus);
        return (
          <Stack
            borderBottom={'1px solid #EEE'}
            p="10px 0px"
            direction={'row'}
            onClick={() => onTaskClick(t.id)}
            sx={{
              cursor: 'pointer',
            }}
            justifyContent={'space-between'}
          >
            <Stack gap="12px" width={'88%'} direction={'row'}>
              <Box
                bgcolor={color?.backgroundColor}
                border={`2px solid ${color?.color}`}
                borderRadius={'100px'}
                width="18px"
                height="18px"
                mt="2px"
              />
              <Stack width={'95%'}>
                <Typography
                  maxWidth="100%"
                  whiteSpace={'pre'}
                  textOverflow={'ellipsis'}
                  overflow="hidden"
                  fontSize={'13px'}
                  fontWeight={'600'}
                  color="#000"
                >
                  {t.title}
                </Typography>
                {t.dueTime && (
                  <Stack direction="row" gap="4px" alignItems="center">
                    {type !== 'dueToday' && (
                      <Typography
                        fontSize={'12px'}
                        fontWeight={'500'}
                        color={
                          type === 'overdue'
                            ? 'rgba(211, 47, 47, 1)'
                            : 'rgba(66, 66, 66, 1)'
                        }
                      >
                        {getUtcToTz(t.dueTime, 'MMM D')}
                      </Typography>
                    )}
                    {type !== 'dueToday' && (
                      <Box
                        bgcolor={
                          type === 'overdue'
                            ? 'rgba(211, 47, 47, 1)'
                            : 'rgba(66, 66, 66, 1)'
                        }
                        borderRadius={'100px'}
                        width="3px"
                        height="3px"
                      />
                    )}
                    <Typography
                      fontSize={'12px'}
                      fontWeight={'500'}
                      color={
                        type === 'overdue'
                          ? 'rgba(211, 47, 47, 1)'
                          : 'rgba(66, 66, 66, 1)'
                      }
                      // overdue
                    >
                      {getUtcToTzTime(t.dueTime)}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Stack>
            <AssigneesView
              type={'avatars'}
              data={t.TaskAssignees}
              assigneesProps={{ width: 28, height: 28 }}
            />
          </Stack>
        );
      })}
      {!filteredTasks.length && <TaskListWidgetEmptyState />}
    </Stack>
  );
};

const OverdueTasksListSummary = (props) => {
  const { hideFilters = false, self = false, basicUser = false } = props;
  const [ref, { height }] = useMeasure();
  const { getUserConfig, editUserConfig, userId } = useUserConfig();
  const { isSameDay, isInFuture } = useDateTime();
  const userProfile: any = useSelector(selectors.getUserProfile);
  const { splitAssigneesByIds } = useAssigneesUtils();
  const { id } = userProfile;

  const [activeTab, setActiveTab] = useState(0);

  const [data, setData] = useState([]);
  const [selectedAssignee, setSelectedAssignee] = useState<any>([]);
  const [configKey, setConfigKey] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const handleClearFilter = async () => {
    setSelectedAssignee([]);
    await splitAndSaveConfig([]);

    fetchTaskList({
      assignees: [],
      users: [],
    });
  };

  const fetchTaskList = async (customFilters = {}) => {
    // Fetch Report data
    setLoading(true);

    const requestPayload = {
      ...customFilters,
    };

    let data: any = [];

    if (basicUser) {
      const payload = {
        advanceFilters: {
          condition: 'AND',
          filters: [
            {
              comparator: 'is',
              filterName: 'createdDate',
              conditional: 'and',
              value: [null, null],
            },
          ],
        },
        offset: 0,
        limit: 9999999999,
        view: 'calendar',
        viewType: '',
      };
      const response = await xeniaApi.getTasksApi(payload);
      data = response?.data?.rows ?? [];
    } else {
      data = await xeniaApi.getTasksList(requestPayload);
    }

    setData(data);
    setLoading(false);
  };

  const splitAndSaveConfig = async (assigneesList) => {
    const data = splitAssigneesByIds(assigneesList, {
      people: true,
      teams: true,
      locations: true,
    });

    const assignees =
      [...(data?.locations ?? []), ...(data?.teams ?? [])] ?? [];
    const users = [...(data?.people ?? [])] ?? [];

    await editUserConfig(configKey, {
      homeTaskAndWorkOrdersFilters: {
        data: {
          assignees,
          users,
        },
      },
    });

    return { assignees, users };
  };

  const handleEmployeeSelect = async (option) => {
    setSelectedAssignee(option);
    const assigneeIds = option.map((t) => t.id);

    const { assignees, users } = await splitAndSaveConfig(assigneeIds);

    const updatedFilters = {
      assignees,
      users,
    };

    fetchTaskList(updatedFilters);
  };
  const setHomeTaskAndWorkOrdersFilters = async () => {
    const userConfig = await getUserConfig(configKey);

    if (userConfig?.homeTaskAndWorkOrdersFilters?.data) {
      const { assignees, users } =
        userConfig.homeTaskAndWorkOrdersFilters.data || {};

      let assigneesList = [...(assignees ?? []), ...(users ?? [])];
      assigneesList = assigneesList?.map((id) => {
        return { id };
      });

      setSelectedAssignee(assigneesList);

      const updatedFilters = {
        assignees,
        users,
      };

      fetchTaskList(updatedFilters);
    } else {
      setSelectedAssignee([]);
      fetchTaskList({
        assignees: [],
        users: [],
      });
    }
  };

  const reloadCallBack = async () => {
    const assigneeIds = selectedAssignee?.map((t) => t.id);

    const { assignees, users } = await splitAndSaveConfig(assigneeIds);

    const updatedFilters = {
      assignees,
      users,
    };

    fetchTaskList(updatedFilters);
  };

  const onRowClick = (id) => {
    dispatch(
      setDialog({
        open: true,
        dialogId: 'taskDetail',
        data: {
          successCB: reloadCallBack,
          taskId: id,
        },
      }),
    );
  };

  useEffect(() => {
    if (userId) {
      setConfigKey(`homeTaskAndWorkOrdersFilters::${userId}`);
    }
  }, [userId]);

  useEffect(() => {
    if (configKey) {
      setHomeTaskAndWorkOrdersFilters();
    }
  }, [configKey]);

  useEffect(() => {
    if (id) {
      if (self) {
        splitAndSaveConfig([id]);
        editUserConfig(configKey, {
          homeTaskAndWorkOrdersFilters: { assignees: [id] },
        });
        fetchTaskList({ assignees: [id] });
        return;
      }
    }
  }, [id]);

  const overdueTaskCount = useMemo(
    () =>
      (data as any)?.filter(
        (t) => t?.isOverDue && t?.taskStatus !== 'Completed',
      )?.length ?? 0,
    [data],
  );

  const dueTodayCount = useMemo(
    () =>
      (data as any)?.filter(
        (t) =>
          t?.dueDate && isSameDay(t?.dueDate) && t?.taskStatus !== 'Missed',
      )?.length ?? 0,
    [data],
  );

  const upcomingCount = useMemo(
    () =>
      (data as any)?.filter((t) => t?.dueDate && isInFuture(t?.dueDate))
        ?.length ?? 0,
    [data],
  );

  const completedCount = useMemo(
    () =>
      (data as any)?.filter((t) => t?.taskStatus === 'Completed')?.length ?? 0,
    [data],
  );

  return (
    <TasksListSummaryCnt ref={ref} className={'taskListSummaryCnt'}>
      <WidgetHeading>
        Task & Work Orders
        {!hideFilters && (
          <AssigneeFilterDropdown
            selected={selectedAssignee}
            onChangeCallback={(option) => handleEmployeeSelect(option)}
            onClearCallback={handleClearFilter}
          />
        )}
      </WidgetHeading>

      {loading && (
        <WidgetLoaderCnt>
          <CircularProgress />
        </WidgetLoaderCnt>
      )}
      {!loading && data?.length === 0 && <OverDueEmptyState />}
      {!loading && data?.length > 0 && (
        <CTabs
          activeTab={activeTab}
          onTabChangeCb={setActiveTab}
          data={[
            {
              label: `Due Today (${dueTodayCount})`,
              content: () => (
                <Listing
                  onTaskClick={onRowClick}
                  tasks={data}
                  type={'dueToday'}
                />
              ),
              panelProps: {
                sx: {
                  height: height - 143,
                },
              },
            },
            {
              label: `Upcoming (${upcomingCount})`,
              content: () => (
                <Listing
                  onTaskClick={onRowClick}
                  tasks={data}
                  type={'upcoming'}
                />
              ),
              panelProps: {
                sx: {
                  height: height - 143,
                },
              },
            },
            {
              label: `Overdue (${overdueTaskCount})`,
              content: () => (
                <Listing
                  onTaskClick={onRowClick}
                  tasks={data}
                  type={'overdue'}
                />
              ),
              panelProps: {
                sx: {
                  height: height - 143,
                },
              },
            },
            {
              label: `Completed (${completedCount})`,
              content: () => (
                <Listing
                  onTaskClick={onRowClick}
                  tasks={data}
                  type={'completed'}
                />
              ),
              panelProps: {
                sx: {
                  height: height - 143,
                },
              },
            },
          ]}
        />
      )}
    </TasksListSummaryCnt>
  );
};

export default OverdueTasksListSummary;
