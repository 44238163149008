import { useCallback, useEffect, useMemo, useState } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { getUserHotelTz } from 'store/selectors/auth';
import actions from 'store/actions';

// MUI
import { CircularProgress, Grid, Stack } from '@mui/material';

// Custom components
import CTypography from 'components/CTypography';
import AssigneeFilterDropdown from 'components/AssigneeFilter/AssigneeFilter';
import DateRangePickerCommon from 'components/DateRangePicker/Common';
import CalendarIcon from 'components/Icons/calendarIconRounded';
import NoRecordsFound from 'components/ListView/EmptyRecords/noRecordsFound.cmp';
import ImageIcon from 'components/Icons/imageIcon';
import LocationFilterDropdown from 'pages/Widgets/TaskFilters/LocationFilter/locationFilters';
import TemplateFilterDropdown from 'pages/Widgets/TemplateSubmissionsFilters/TemplateFilter/TemplateFilter';

// Utils
import { STEP_TYPES } from 'utils/templates';
import DIALOGS from 'utils/dialogIds';
import useResponsive from 'utils/CustomHooks/useResponsive';
import useDropdownData from 'utils/CustomHooks/useDropdownData';
import {
  dateRangePickerSideMenuCommon,
  reportingInitialRange,
} from 'utils/datePicker';
import DateTime from 'utils/DateTime';

// APIs
import xeniaApi from 'api/index';

// Styles
import {
  BoxContainer,
  FiltersCmp,
  LocationContainer,
  SubContent,
} from './style';

// Other Libraries
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import mime from 'mime';

interface GalleryViewProps {
  filters: string;
  templateId?: string;
  locationId?: string;
}

const GalleryView: React.FC<GalleryViewProps> = ({
  filters,
  templateId,
  locationId,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const tz = useSelector(getUserHotelTz) || 'America/Chicago';
  const initState = {
    dateRange: {
      selectedDateLabel: 'Last 30 Days',
      filters: {
        fromDate: new DateTime()
          .toTz(tz)
          .transform({ subtract: [29, 'day'], startOf: 'day' })
          .toJSDate(),
        toDate: new DateTime()
          .toTz(tz)
          .transform({ subtract: [0, 'day'], endOf: 'day' })
          .toJSDate(),
      },
    },
    location: locationId ? [locationId] : [],
    users: [],
    checklists: templateId ? [templateId] : [],
  };
  const [state, updateState] = useState<any>(initState);
  const { isMobileDeviceSm } = useResponsive();
  const { selectedTaskTemplates } = useDropdownData();
  const dispatch = useDispatch();

  const getImageAttachments = (allAttachments: string[] = []) =>
    allAttachments?.filter((url: string) =>
      mime.getType(url)?.includes('image'),
    );

  const extractImageAttachments = useCallback((submissions) => {
    const attachmentsMap = {};
    submissions.forEach((submission) => {
      submission.TaskChecklistItems?.forEach((item) => {
        if (
          item.type === STEP_TYPES.TAKE_PHOTO ||
          item.type === STEP_TYPES.PHOTO_ANNOTATE
        ) {
          item.answers?.value?.forEach((attachment) => {
            attachmentsMap[attachment] = {
              image: attachment,
              updater: {
                name: item.Updater?.fullName,
                photo: item.Updater?.photo,
              },
              date: item.completedAt ?? item.createdAt,
              name: item.description,
              templateName: submission.Checklist?.name,
              icon: submission.icon,
              locations: submission.locationsInfo,
            };
          });
        }
        item.TaskChecklistItemNotes?.forEach((note) => {
          const images = getImageAttachments(note.attachments);
          images.forEach((attachment) => {
            attachmentsMap[attachment] = {
              image: attachment,
              updater: {
                name: note.Creator?.fullName,
                photo: note.Creator?.photo,
              },
              date: note.createdAt,
              name: item.description,
              templateName: submission.Checklist?.name,
              icon: submission.icon,
              locations: submission.locationsInfo,
            };
          });
        });
      });
      submission.TaskChecklistNotes?.forEach((item) => {
        const images = getImageAttachments(item.attachments);
        images.forEach((attachment) => {
          attachmentsMap[attachment] = {
            image: attachment,
            updater: {
              name: item.Creator?.fullName,
              photo: item.Creator?.photo,
            },
            date: item.createdAt,
            name: 'Other Notes',
            templateName: submission.Checklist?.name,
            icon: submission.icon,
            locations: submission.locationsInfo,
          };
        });
      });
    });
    return attachmentsMap;
  }, []);

  const attachmentsMap = useMemo(() => extractImageAttachments(data), [data]);

  const getTemplateSubmissions = useCallback(
    async (params: {
      location?: string[];
      fromDate?: Date | null;
      toDate?: Date | null;
      users?: [{ id: string }];
      checklists?: string[];
      detailed?: boolean;
    }) => {
      try {
        const filters: any = {
          advanceFilters: {
            condition: 'AND',
            filters: [
              {
                comparator: 'is',
                conditional: 'and',
                filterName: 'status',
                value: ['Submitted'],
              },
              ...(params.users?.length
                ? [
                    {
                      comparator: 'is',
                      conditional: 'and',
                      filterName: 'user',
                      value: params.users.map((v) => v.id),
                    },
                  ]
                : []),
            ],
          },
          globalFilters: {
            condition: 'AND',
            filters: [
              ...(params.fromDate && params.toDate
                ? [
                    {
                      comparator: 'is',
                      conditional: 'and',
                      filterName: 'date',
                      value: [params.fromDate, params.toDate],
                    },
                  ]
                : []),
            ],
          },
        };

        if (params.checklists?.length) {
          filters.advanceFilters.filters.push({
            comparator: 'is',
            conditional: 'and',
            filterName: 'template',
            value: params.checklists.filter((o) => o !== 'all'),
          });
        }

        if (params.location?.length) {
          filters.globalFilters.filters.push(
            ...(params.location?.length > 0
              ? [
                  {
                    comparator: 'is',
                    conditional: 'and',
                    filterName: 'locations',
                    value: params.location,
                  },
                ]
              : []),
          );
        }

        const requestPayload = {
          ...filters,
        };
        const response = await xeniaApi.getAllChecklistLogs(requestPayload);
        return response?.data;
      } catch (e) {
        console.log(e);
      }
    },
    [],
  );

  const getData = async () => {
    setIsLoading(true);
    const data = await getTemplateSubmissions({
      location: state.location,
      checklists: state.checklists,
      fromDate: state.dateRange?.filters?.fromDate,
      toDate: state.dateRange?.filters?.toDate,
      users: state.users,
      detailed: true,
    });
    setData(data ?? []);
    setIsLoading(false);
  };

  const handleDateChange = (date): any => {
    const copyFilterState = cloneDeep(state);
    copyFilterState.dateRange = {
      selectedDateLabel: date?.selectedDateOption,
      filters: {
        ...state?.dateRange?.filters,
        fromDate: date?.from,
        toDate: date?.to,
      },
    };
    updateState(copyFilterState);
  };
  const handleClearDateFilter = (date) => {
    handleDateChange(date);
  };
  const handleLocationFilterChange = (values) => {
    const postData = values.map((v) => v.id);
    updateState({ ...state, location: postData });
  };
  const handleClearLocationFilter = (values) => {
    updateState({ ...state, location: values });
  };
  const handleClearPersonFilter = () => {
    updateState({ ...state, users: [] });
  };
  const handlePersonChangeFilter = (values) => {
    updateState({ ...state, users: values });
  };
  const handleTemplateChangeFilter = (values) => {
    updateState({ ...state, checklists: values });
  };
  const handleClearTemplateFilter = (values) => {
    updateState({ ...state, checklists: values });
  };
  const handleClickGalleryDetails = (id: string) => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.GALLERY_DETAILS_MODAL,
        open: true,
        data: {
          allAttachments: Object.values(attachmentsMap),
          id,
        },
      }),
    );
  };

  useEffect(() => {
    getData();
  }, [state]);

  return (
    <>
      <FiltersCmp>
        {filters.includes('date') && (
          <DateRangePickerCommon
            handleDateChange={handleDateChange}
            selectedDateLabel={state?.dateRange?.selectedDateLabel}
            buttonProps={{
              hideSelected:
                state?.dateRange?.selectedDateLabel === 'Last 30 Days',
            }}
            selectedDateRange={{
              from: state?.dateRange?.filters?.fromDate,
              to: state?.dateRange?.filters?.toDate,
            }}
            clearProps={{
              dateLabel: 'Last 30 Days',
              onClick: handleClearDateFilter,
              initialRange: reportingInitialRange,
              showClearBtn:
                state?.dateRange?.selectedDateLabel != 'Last 30 Days',
            }}
            contentProps={{
              displaySideOptions: !isMobileDeviceSm,
              allowInputs: !isMobileDeviceSm,
              numberOfMonths: isMobileDeviceSm ? 1 : 2,
              sideMenu: dateRangePickerSideMenuCommon,
            }}
          />
        )}
        {filters.includes('template') && (
          <TemplateFilterDropdown
            selected={selectedTaskTemplates(state.checklists)}
            onChangeCallback={(templates) => {
              handleTemplateChangeFilter(templates?.map((item) => item?.id));
            }}
            onClearCallback={handleClearTemplateFilter}
          />
        )}
        {filters.includes('location') && (
          <LocationFilterDropdown
            selected={state.location}
            onChangeCallback={(option) => handleLocationFilterChange(option)}
            onClearCallback={handleClearLocationFilter}
          />
        )}
        {filters.includes('person') && (
          <AssigneeFilterDropdown
            selected={state.users}
            onChangeCallback={(option) => handlePersonChangeFilter(option)}
            onClearCallback={handleClearPersonFilter}
            isUnassigned={false}
            isLocation={false}
          />
        )}
      </FiltersCmp>
      {isLoading ? (
        <Stack
          width="100%"
          pt="150px"
          height="calc(100vh - 300px)"
          alignItems="center"
        >
          <CircularProgress />
        </Stack>
      ) : Object.keys(attachmentsMap)?.length === 0 ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          direction="column"
          marginTop="50px"
          height="calc(100vh - 330px)"
        >
          <NoRecordsFound />
        </Stack>
      ) : (
        <Grid container spacing={3}>
          {Object.values(attachmentsMap)?.map((item: any) => {
            return (
              <Grid item key={item?.image} xs={12} sm={6} md={4} lg={2}>
                <BoxContainer
                  onClick={() => handleClickGalleryDetails(item?.image)}
                >
                  <div className="imgContainer">
                    {item?.image ? (
                      <img src={item?.image} style={{ width: '100%' }} />
                    ) : (
                      <Stack
                        bgcolor="#ECEFF1"
                        height="100px"
                        width="100%"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <ImageIcon height={30} width={30} />
                      </Stack>
                    )}
                  </div>
                  <div className="contentContainer">
                    <div className="titleContainer">
                      <CTypography className="title">{item?.name}</CTypography>
                    </div>
                    <SubContent>
                      <LocationContainer>
                        <CalendarIcon className="icon" />
                        <CTypography className="name">
                          {moment
                            .tz(item?.date, tz)
                            .format('MMM DD, YYYY • hh:mm A')}
                        </CTypography>
                      </LocationContainer>
                    </SubContent>
                  </div>
                </BoxContainer>
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
};

export default GalleryView;
