// Components
import AssigneesView from 'components/AssigneesView/AssigneesView';
// Icons
import CalendarIconRounded from 'components/Icons/calendarIconRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
// Actions / Selectors / Utils
import { useTaskEdit } from 'controller/useTaskEdit';
// Hooks
import useDateTime from 'utils/CustomHooks/useDateTime';
// Styles
import { TaskCardCnt } from '../board.styles';
import { memo, useMemo } from 'react';
import { TASK_STATUS } from 'utils/globalVariable';
import { isEqual } from 'lodash';
import TaskAttachment from './taskAttachment';
import TaskFrequency from './taskFrequency';
import { Box } from '@mui/material';
import { MemoizedAssigneeDropdownCmp } from 'pages/task/TaskListView/AssigneeDropdownCmp';

function TaskCard(props) {
  const { task, index, style, provided, isDragging } = props;
  const { getUtcToTz } = useDateTime();
  const { handleEditDetailTask } = useTaskEdit();
  const taskData = useMemo(() => {
    return task;
  }, [task]);
  const { title } = taskData; //destructure taskData object
  const formatedDueDate = taskData.dueDate
    ? `${getUtcToTz(taskData.dueDate, 'MMM D, YYYY')}`
    : undefined;
  const isTaskOverdue = taskData.isOverDue;
  const isTaskMissed = taskData.taskStatus === TASK_STATUS.Missed;

  const handleOpenTaskDetaills = (event) => {
    handleEditDetailTask(taskData?.id, 'detail');
  };
  return (
    <TaskCardCnt
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={{
        ...style,
        ...provided.draggableProps.style,
      }}
      data-is-dragging={isDragging}
      data-testid={taskData.id}
      data-index={index}
      aria-label={`${taskData.title}`}
    >
      <div onClick={(e) => handleOpenTaskDetaills(e)}>
        <div className={'taskTitle'}>{title}</div>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          {formatedDueDate && (
            <div className="taskDate">
              {!task.Finisher && !isTaskOverdue && (
                <div className={'dueOrcompleted'}>
                  <CalendarIconRounded />
                  {formatedDueDate}
                </div>
              )}

              {isTaskOverdue && !isTaskMissed && (
                <div className={'overdue'}>
                  <AccessTimeRoundedIcon sx={{ fontSize: 16 }} />
                  {formatedDueDate}
                </div>
              )}
            </div>
          )}

          <TaskAttachment task={taskData} />
        </Box>
        <TaskFrequency task={taskData} />
        <div className={'taskFooter'}>
          <div className={'taskPriority'}>
            <div
              className={`icon-priority-${taskData?.priority?.toLowerCase()}`}
              style={{ marginRight: 10 }}
            />
            <span>{taskData?.priority}</span>
          </div>
          <span onClick={(e) => e.stopPropagation()}>
            <MemoizedAssigneeDropdownCmp
              task={taskData}
              buttonStyles={{ borderRadius: 50 }}
            />
          </span>
        </div>
      </div>
    </TaskCardCnt>
  );
}

export default TaskCard;
const arePropsEqual = (prevProps, nextProps) => isEqual(prevProps, nextProps);

export const TaskCardMomoized = memo(TaskCard, arePropsEqual);
