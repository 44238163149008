// React
import { useMemo } from 'react';
// Icon
import RecurringIcon from 'components/Icons/recurringIcon';
// Components
import CTypography from 'components/CTypography';
// Hooks
import { weeks } from 'components/RepeatTask/Weekly/constants';
import { months } from 'components/RepeatTask/Monthly/constants';

// Styles
import { FrequencyCnt } from '../board.styles';

const scheduleList = [...weeks, ...months];
function TaskFrequency(props) {
  const { task } = props;
  const { recurringByEvery, intervalWeek } = task;

  const schedule = useMemo(() => {
    let scheduleTitle = scheduleList?.find(
      (item) => item.id === recurringByEvery,
    )?.displayTitle;
    if (recurringByEvery === 'Week1' && intervalWeek?.length === 7) {
      scheduleTitle = 'Daily';
    }
    return scheduleTitle;
  }, [task]);

  return (
    <>
      {schedule && (
        <FrequencyCnt>
          <div className="innerCnt">
            <RecurringIcon />
            <CTypography>{schedule}</CTypography>
          </div>
        </FrequencyCnt>
      )}
    </>
  );
}

export default TaskFrequency;
