import { useMemo } from 'react';
import TaskListTile from 'pages/task/ListViewV2/TaskTile';
import { TaskListCnt } from 'pages/task/ListViewV2/style';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { FixedSizeList as List } from 'react-window';
import TaskListSkeleton from 'pages/task/ListViewV2/TaskTile/skeletonLoader';
import NoAddedRecords from 'pages/task/TaskListView/EmptyRecords/NoAddedRecords.cmp';
import CreateTaskInfo from 'pages/task/TaskListView/CreateTaskInfo/CreateTaskInfo';
import useResponsive from 'utils/CustomHooks/useResponsive';

function TaskListViewV2(props) {
  const { hideMainBanner, searchedText } = props;
  const { isMobileDeviceSm } = useResponsive();

  const taskList = useSelector(selectors.getTaskList);

  const isFiltersORSearch = useSelector(selectors.getIsFiltersORSearch);
  const allTasks: any = taskList?.rows || [];

  const tasks = useMemo(() => {
    if (searchedText) {
      return allTasks?.filter((task) =>
        task?.title?.toLowerCase()?.includes(searchedText?.toLowerCase()),
      );
    }
    return allTasks;
  }, [allTasks, searchedText]);

  const loaders = useSelector(selectors.getLoaders);
  const userMetaInfo: any = useSelector(selectors.getUserMeta);
  const isTasksLoading = loaders?.tasksList?.isLoading;
  const topAlertState = useSelector(selectors.getTopAlertState);
  const isTaskListEmpty =
    !userMetaInfo?.createFirstTaskDialogSeen &&
    !tasks?.length &&
    !isFiltersORSearch &&
    loaders?.tasksList?.isLoading === false;

  const listHeightToMinus = useMemo(() => {
    if (topAlertState?.show && hideMainBanner) {
      return 235;
    }

    if (topAlertState?.show && !hideMainBanner) {
      return 275;
    }

    if (!topAlertState?.show && hideMainBanner) {
      return 180;
    }

    if (!topAlertState?.show && !hideMainBanner) {
      return 230;
    }

    return 180;
  }, [topAlertState, hideMainBanner]);

  if (isTaskListEmpty) {
    return (
      <TaskListCnt>
        <CreateTaskInfo style={{ position: 'static', transform: 'none' }} />
      </TaskListCnt>
    );
  }
  if (
    userMetaInfo?.createFirstTaskDialogSeen &&
    tasks?.length &&
    !isFiltersORSearch
  ) {
    return (
      <TaskListCnt>
        <NoAddedRecords />
      </TaskListCnt>
    );
  }
  return (
    <TaskListCnt>
      {isTasksLoading ? (
        <TaskListSkeleton />
      ) : (
        <List
          height={window.innerHeight - listHeightToMinus} // Adjust according to your container's height
          itemCount={tasks?.length}
          itemSize={isMobileDeviceSm ? 150 : 105} // Adjust according to your TaskListTile height
          width={'100%'} // Adjust according to your container's width
        >
          {({ index, style }) => {
            const task = tasks[index];
            return (
              <div style={style} key={task?.id}>
                <TaskListTile key={task.id} task={task} />
              </div>
            );
          }}
        </List>
      )}
    </TaskListCnt>
  );
}

export default TaskListViewV2;
